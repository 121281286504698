
import React, { useState, useEffect } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Paper, Tabs, Tab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SettingHeader from '../SettingHeader';
import { useLocation } from 'react-router-dom';
import Data from './Data';
import Basic from './Basic';
import { Repository_Policy } from 'wowok';
import Policy from './Policy';
import Reference from './Reference';
import { Pannel, a11yProps,  } from '../../util/Common';

export default function RepositorySetting (props:any) {  
    const location = useLocation()
    var t = parseInt(location.hash.slice(1));
    if (t < 0 || t > 3 ||  isNaN(t))  { t = 0; }
    window.location.hash = '#' + t;
/*
    const [data, setData] = useState<PolicyData[]>([]);

    const QueryPolicy = (count:number=0, cursor?:any) => {
      if (!props?.contents?.fields?.policies?.fields?.id?.id)
        return;

      count += 1;
      //@ 取4页
      Protocol.Client().getDynamicFields({parentId:props?.contents?.fields?.policies?.fields?.id?.id, cursor:cursor}).then((res:any)=>{
          Protocol.Client().multiGetObjects({ids:res.data.map((i:any)=>i.objectId), options:{showContent:true}}).then((policy) => {
              policy.forEach((v) => {
                  var f = data.find((i)=>i.name == (v?.data?.content as any)?.fields?.name);
                  if (f) {
                      f = {name:(v?.data?.content as any)?.fields?.name, description:(v?.data?.content as any)?.fields?.value?.fields?.description,
                          dataType:(v?.data?.content as any)?.fields?.value?.fields?.value_type as RepositoryValueType,
                          permission:(v?.data?.content as any)?.fields?.value?.fields?.permission_index as number}
                  } else {
                      data.push({name:(v?.data?.content as any)?.fields?.name, description:(v?.data?.content as any)?.fields?.value?.fields?.description,
                          dataType:(v?.data?.content as any)?.fields?.value?.fields?.value_type as RepositoryValueType,
                          permission:(v?.data?.content as any)?.fields?.value?.fields?.permission_index as PermissionIndexType,
                      });
                  }
              });
              setData([...data])
          });
          if (res.hasNextPage && count <= 4) {
              QueryPolicy(count, res.nextCursor);
          }
      })
  }

  useEffect(()=> {
      setData([]);
      QueryPolicy();
  }, []);
*/
  const id = props?.contents?.fields?.id?.id ?? '';
  const policies: Repository_Policy[]= props?.contents?.fields?.policies?.fields?.contents?.map((v:any) => {
    return {key:v?.fields?.key, description:v?.fields?.value?.fields?.description, 
      permissionIndex:v?.fields?.value?.fields?.permission_index, dataType:v?.fields?.value?.fields?.value_type}
  })
  const [value, setValue] = React.useState(t);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    window.location.hash = '#' + newValue;
    setValue(newValue);
  };
    

    return (
      <Paper sx={{ width: '100%', pt:'.4em',}} >
        <SettingHeader name={id ? 'Repository Setting' : 'Launch Repository'}/>

        <Tabs value={value} onChange={handleChange} aria-label="reward" sx={{height:id ? '3em' : '3.8em', margin:'.4em 1em'}}>
          <Tab label="Basic" {...a11yProps(0)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" 
            label={'Policy'} {...a11yProps(1)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" label="Data" 
            {...a11yProps(2)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" label="Reference" 
            {...a11yProps(3)} sx={{textTransform:'none'}}/>
        </Tabs>
        <Pannel value={value} index={0}>
            <Basic {...props}/>
        </Pannel>
        <Pannel value={value} index={1}>
          <Policy {...props} policies={policies}/>
        </Pannel>
        <Pannel value={value} index={2}>
          <Data {...props} policies={policies}/>
        </Pannel>
        <Pannel value={value} index={3}>
          <Reference {...props} />
        </Pannel>
    </Paper>
    );
}
