import React, { useMemo, useState } from 'react';
import ReactFlow, { MarkerType, Position, Panel, Node, Edge, useNodesState, useEdgesState, Controls,} from 'reactflow';
import 'reactflow/dist/style.css';
import { generateRandomString, store_launch_graph} from '../../../util'
import GuardEdge from './GuardEdge';
import GuardNode from './GuardNode';
import { Guard, ContextType, OperatorType, ValueType, MAX_DESCRIPTION_LENGTH, GuardMaker, 
    IsValidAddress, SER_VALUE, IsValidOperatorType, IsValidValueType } from 'wowok';
import { Box, Button, Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import { NodeData, ROOT_ID } from './Guard';
import { ERROR, Errors } from 'wowok/src/exception';
import { Transition } from '../../util/Common';
import CloseIcon from '@mui/icons-material/Close';
import { SettingInputText, SettingLaunch, SettingTitle } from '../Settings';
import { useSnackbar } from 'notistack';
import { TransactionBlock, } from 'wowok';
import { useWallet } from '@suiet/wallet-kit';
import { useAccount, add_resource_launched } from '../../util/Account';


export const GUARD_LAUNCH_SOTRE_KEY_NODES = store_launch_graph('GUARD_LAUNCH_NODES');
export const GUARD_LAUNCH_SOTRE_KEY_EDGES = store_launch_graph('GUARD_LAUNCH_EDGES');
export const GUARD_LAUNCH_SOTRE_KEY_FLOW = store_launch_graph('GUARD_LAUNCH_FLOW');
export const GUARD_LAUNCH_SOTRE_KEY_CONSTANTS = store_launch_graph('GUARD_LAUNCH_CONSTANTS');

const get_x_offset = (index:number, index_max:number) : number=> {
    const mid  = index_max / 2;
    if (index !== mid) {
        return 160*(index-mid);
    } 
    return 0;
}

export interface constItem {
    identifier?: number; // 编号
    type: ValueType; // 数据类型
    bWitness: boolean; // 是否是witness
    value: any; // 数据值
    cmd: number[]; // 查询命令
    reference?: number; // 被引用次数
}

export const constReference = (nodes:Node[], items: constItem[], bcmd:boolean=true) : constItem[]  => {
    items.forEach((i) => {
        let ret = 0;
        i.cmd = [];
        nodes.forEach((v) => {
            if (v.data.identifier === i.identifier) {
                ret = ret + 1;
                if (v.data.cmd !== undefined) {
                    const c = Guard.GetCmd(v.data.cmd);
                    if (c) {
                        i.cmd.push(c.query_id)
                    }
                }
            }
        }) 
        i.reference = ret;
    }); 
    if (bcmd) return items.filter((v)=>v.cmd.length > 0);
    else return items
}

export const constTable = (filter:ValueType  | 'any' | 'number', bWitness?:boolean) : constItem[] => {
    const d = localStorage.getItem(GUARD_LAUNCH_SOTRE_KEY_CONSTANTS);
    if (d) {
        const tb:constItem[] = JSON.parse(d) ?? [] ;
        if (filter === 'any') {
            if (typeof(bWitness) === 'boolean') {
                return tb.filter((v)=>v.bWitness === bWitness);
            } else {
                return tb
            }
        } else if (filter === 'number') {
            if (typeof(bWitness) === 'boolean') {
                return tb.filter((v)=> (v.type === ValueType.TYPE_U8 || v.type === ValueType.TYPE_U128 ||
                v.type === ValueType.TYPE_U256 || v.type === ValueType.TYPE_U64) && v.bWitness === bWitness);    
            } else {
                return tb.filter((v)=> v.type === ValueType.TYPE_U8 || v.type === ValueType.TYPE_U128 ||
                v.type === ValueType.TYPE_U256 || v.type === ValueType.TYPE_U64);                
            }
        } 
        if (typeof(bWitness) === 'boolean') {
            return tb.filter((v)=>v.type === filter && v.bWitness === bWitness);
        } else {
            return tb.filter((v)=>v.type === filter);
        }
    }
    return []
}

export const ChangeConstTable = (nodes: Node[], value:any, type:ValueType, bWitness:boolean, cmd?:number) : number => { //@ 返回identifier
    //@ 能否在table里找到
    const tb = constTable('any');
    const exist = constTable('any').find((v) => {
        return v.value === value && v.type === type && v.bWitness === bWitness && v.cmd.find((i)=>i===cmd)
    });
    if (exist && exist.identifier !== undefined) { //@ 啊啊啊，undefined!!
        return exist.identifier;
    } 

    const ids:number [] = [];
    nodes.forEach((v) => {
        if (v.data.identifier !== undefined) {
            ids.push(v.data.identifier);
        }
    })

    const identifier = GenIdentifier(tb, ids);
    tb.push({identifier:identifier, value: value, type:type, bWitness:bWitness, cmd:cmd !== undefined ? [cmd] : []});   
    localStorage.setItem(GUARD_LAUNCH_SOTRE_KEY_CONSTANTS, JSON.stringify(tb));
    return identifier;                               
}

export const GenIdentifier = (items: constItem[], used:number[]) : number => {
    for (let i = 1; i < 256; ++i) {
        if (items.find((v) => v.identifier === i)) {
            continue;
        }
        return i;
    };
    for (let i = 1; i < 256; ++i) {
        if (used.find((v) => v === i) === undefined) {
            return i;
        }
    };
    return 1
}

export const ChangeNodeData = (node:Node)  => {
    const data :NodeData = node.data;

    const edges_store = localStorage.getItem(GUARD_LAUNCH_SOTRE_KEY_EDGES);
    const nodes_store = localStorage.getItem(GUARD_LAUNCH_SOTRE_KEY_NODES);
    let edges:Edge[] = edges_store ? JSON.parse(edges_store) : [];
    let nodes:Node[] = nodes_store ? JSON.parse(nodes_store) : [];
    const find = nodes.find((v)=>v.id === node.id);
    if (!find) { ERROR(Errors.Fail, 'node not found')};

    const DeleteChild = (parent:string) => {
        const n = edges.filter((v)=>v.target === parent).map((i)=>i.source); // 子节点名字
        n.forEach((v)=>DeleteChild(v)); // 递归删除子节点的子节点
        nodes = nodes.filter((v)=>n.includes(v.id) === false); // 删除子节点
        edges = edges.filter((v)=>v.target !== parent); // 删除子节点的边
    }

    const Delete = (node:string) => {
        const n = edges.filter((v)=>v.target === node).map((i)=>i.source); // 子节点名字
        n.forEach((v)=>Delete(v)); // 递归删除子节点的子节点
        nodes = nodes.filter((v)=>v.id !== node); // 删除自己节点
        edges = edges.filter((v)=>v.source !== node); // 删除自己节点和父节点的边
    }

    // 在原有结构上增减
    if (data.type === find?.data?.type && GuardMaker.is_multi_input_op(data.type)) {
        if (!data.value || data.value < 2)  ERROR(Errors.InvalidParam, 'ChangeNodeData: data.value'); //@ error
        if (typeof(find?.data.value) === 'number') {
            if (find.data.value > data.value) { // 减少
                const n = edges.filter((v)=>v.target === node.id).map((i)=>i.source); // 所有源的名字
                nodes.filter((v)=>n.includes(v.id)).filter((i)=>{return i.data.index >= data.value}).forEach((k)=>Delete(k.id));                
            } else if(find.data.value < data.value) { // 增加
                //console.log(data)
                var ret_t : any = 'any';
                if (data.type === OperatorType.TYPE_LOGIC_AND || data.type === OperatorType.TYPE_LOGIC_OR) {
                    ret_t =  ValueType.TYPE_BOOL;
                } else if (data.type === OperatorType.TYPE_LOGIC_HAS_SUBSTRING) {
                    ret_t = ValueType.TYPE_STRING;
                } else if (data.type === OperatorType.TYPE_LOGIC_EQUAL) {

                } else {
                    ret_t = 'number';
                }
                //console.log(ret_t)
                for (let i = find.data.value; i < data.value!; ++i) {
                    const newid = generateRandomString(16);
                    const d: NodeData = {ret_type:ret_t, index:i};
                    nodes.push({id:newid, position:{x:(node as any).xPos + get_x_offset(i, data.value!-1), y:(node as any).yPos + 140}, data:d, 
                        type:"CustomNode", sourcePosition:Position.Top, targetPosition:Position.Bottom});
                    edges.push({id:newid+node.id, source:newid , target:node.id , animated: true,
                        markerEnd: { type: MarkerType.ArrowClosed, width:16, height:16, color:'#E8A7D5'}, type:"CustomEdge"}) 
                }   
            }
        }
    } else {
        DeleteChild(node.id); // 清除原有的树结构

        var i;
        // 生成新的树结构
        const newid = generateRandomString(16);
        switch(data.type as number) {
            case OperatorType.TYPE_QUERY:
                if (data.cmd === undefined) { break; } // TYPE_WITNESS_ID 且 cmd， 则还是query命令
                Guard.GetInputParams(data.cmd!).forEach((i, index, arr)=> {
                    const newid = generateRandomString(16);
                    const d: NodeData = {ret_type:i, index:index};
                    nodes.push({id:newid, position:{x:(node as any).xPos + get_x_offset(index, arr.length-1), y:(node as any).yPos + 140}, data:d, 
                        type:"CustomNode", sourcePosition:Position.Top, targetPosition:Position.Bottom});
                    edges.push({id:newid+node.id, source:newid , target:node.id , animated: true,
                        markerEnd: { type: MarkerType.ArrowClosed, width:16, height:16, color:'#E8A7D5'}, type:"CustomEdge"})
                });
                break;
            case OperatorType.TYPE_LOGIC_AND:
            case OperatorType.TYPE_LOGIC_OR:
                if (!data.value || data.value < 2) ERROR(Errors.InvalidParam, 'ChangeNodeData: data.value');
                for (i = 0; i < data.value!; ++i) {
                    const newid = generateRandomString(16);
                    const d: NodeData = {ret_type:ValueType.TYPE_BOOL, index:i};
                    nodes.push({id:newid, position:{x:(node as any).xPos + get_x_offset(i, data.value!-1), y:(node as any).yPos + 140}, data:d, 
                        type:"CustomNode", sourcePosition:Position.Top, targetPosition:Position.Bottom});
                    edges.push({id:newid+node.id, source:newid , target:node.id , animated: true,
                        markerEnd: { type: MarkerType.ArrowClosed, width:16, height:16, color:'#E8A7D5'}, type:"CustomEdge"}) 
                }
                break;
            case OperatorType.TYPE_LOGIC_NOT:
                nodes.push({id:newid, position:{x:(node as any).xPos + get_x_offset(0, 0), y:(node as any).yPos + 140}, data:{ret_type:ValueType.TYPE_BOOL, index:0}, 
                    type:"CustomNode", sourcePosition:Position.Top, targetPosition:Position.Bottom});
                edges.push({id:newid+node.id, source:newid , target:node.id , animated: true, 
                    markerEnd: { type: MarkerType.ArrowClosed, width:16, height:16, color:'#E8A7D5'}, type:"CustomEdge"}) 
                break;
            case OperatorType.TYPE_NUMBER_ADDRESS:
                nodes.push({id:newid, position:{x:(node as any).xPos + get_x_offset(0, 0), y:(node as any).yPos + 140}, data:{ret_type:'number', index:0},  
                    type:"CustomNode", sourcePosition:Position.Top, targetPosition:Position.Bottom});
                edges.push({id:newid+node.id, source:newid , target:node.id , animated: true, 
                    markerEnd: { type: MarkerType.ArrowClosed, width:16, height:16, color:'#E8A7D5'}, type:"CustomEdge"}) 
                break;
            case OperatorType.TYPE_LOGIC_AS_U256_EQUAL:
            case OperatorType.TYPE_LOGIC_AS_U256_GREATER:
            case OperatorType.TYPE_LOGIC_AS_U256_GREATER_EQUAL:
            case OperatorType.TYPE_LOGIC_AS_U256_LESSER:
            case OperatorType.TYPE_LOGIC_AS_U256_LESSER_EQUAL:
            case OperatorType.TYPE_LOGIC_EQUAL:
                if (!data.value || data.value < 2) ERROR(Errors.InvalidParam, 'ChangeNodeData: data.value');
                for (i = 0; i < data.value!; ++i) {
                    const newid = generateRandomString(16);
                    const d: NodeData = {ret_type:(data.type as number) === OperatorType.TYPE_LOGIC_EQUAL ? 'any' : 'number', index:i};
                    nodes.push({id:newid, position:{x:(node as any).xPos + get_x_offset(i, 1), y:(node as any).yPos + 140}, data:d, 
                        type:"CustomNode", sourcePosition:Position.Top, targetPosition:Position.Bottom});
                    edges.push({id:newid+node.id, source:newid , target:node.id , animated: true,
                        markerEnd: { type: MarkerType.ArrowClosed, width:16, height:16, color:'#E8A7D5'}, type:"CustomEdge"}) 
                }
                break;
            case OperatorType.TYPE_NUMBER_ADD:
            case OperatorType.TYPE_NUMBER_DEVIDE:
            case OperatorType.TYPE_NUMBER_MOD:
            case OperatorType.TYPE_NUMBER_MULTIPLY:
            case OperatorType.TYPE_NUMBER_SUBTRACT:
                if (!data.value || data.value < 2) ERROR(Errors.InvalidParam, 'ChangeNodeData: data.value');
                for (i = 0; i < data.value!; ++i) {
                    const newid = generateRandomString(16);
                    const d: NodeData = {ret_type:'number', index:i};
                    nodes.push({id:newid, position:{x:(node as any).xPos + get_x_offset(i, 1), y:(node as any).yPos + 140}, data:d, 
                        type:"CustomNode", sourcePosition:Position.Top, targetPosition:Position.Bottom});
                    edges.push({id:newid+node.id, source:newid , target:node.id , animated: true,
                        markerEnd: { type: MarkerType.ArrowClosed, width:16, height:16, color:'#E8A7D5'}, type:"CustomEdge"}) 
                }            
                break;
            case OperatorType.TYPE_LOGIC_HAS_SUBSTRING:
                if (!data.value || data.value < 2) ERROR(Errors.InvalidParam, 'ChangeNodeData: data.value');
                for (i = 0; i < data.value!; ++i) {
                    const newid = generateRandomString(16);
                    const d: NodeData = {ret_type:ValueType.TYPE_STRING, index:i};
                    nodes.push({id:newid, position:{x:(node as any).xPos + get_x_offset(i, 1), y:(node as any).yPos + 140}, data:d, 
                        type:"CustomNode", sourcePosition:Position.Top, targetPosition:Position.Bottom});
                    edges.push({id:newid+node.id, source:newid , target:node.id , animated: true,
                        markerEnd: { type: MarkerType.ArrowClosed, width:16, height:16, color:'#E8A7D5'}, type:"CustomEdge"}) 
                }
                break;
            default:
                if (data.type === ContextType.TYPE_CLOCK || data.type === ContextType.TYPE_SIGNER || 
                    data.type === ContextType.TYPE_CONSTANT || data.type === ContextType.TYPE_GUARD || IsValidValueType(data.type as number)) {
    
                } else {
                    console.log(data)
                    ERROR(Errors.Fail, 'Invalid type')
                }
        }
    }

    // 保存
    find!.data = node.data; // 保存当前节点
    localStorage.setItem(GUARD_LAUNCH_SOTRE_KEY_EDGES, JSON.stringify(edges));
    localStorage.setItem(GUARD_LAUNCH_SOTRE_KEY_NODES, JSON.stringify(nodes));
}


const GuardGraph = (props:any) => {    
    //console.log(props)
    const { enqueueSnackbar } = useSnackbar();
    const wallet = useWallet();
    const account = useAccount();

    const [rfInstance, setRfInstance] = React.useState<any>(null);
    const snapGrid:[number, number] = [10, 10];
    const defaultViewport = { x: 110, y: 110, zoom: 0.5 };
    //@ 传递参数给node
    const nodeTypes = useMemo(() => ({ CustomNode: (node_props:any) => <GuardNode forceUpdate={props.forceUpdate} {...node_props}/>}), [props.forceUpdate]);
    const edgeTypes = useMemo(() => ({ CustomEdge: GuardEdge}), []);
    const [readStore, setReadStore] = useState(true);

    const fitViewOptions = { padding: 0.5 };
    let initEdges:Edge[] = [];
    let initNodes:Node[] = [];
    if (readStore) {
        const edges_store = localStorage.getItem(GUARD_LAUNCH_SOTRE_KEY_EDGES);
        const nodes_store = localStorage.getItem(GUARD_LAUNCH_SOTRE_KEY_NODES);
        const flow_store = localStorage.getItem(GUARD_LAUNCH_SOTRE_KEY_FLOW);
        if (flow_store) {
            const flow = JSON.parse(flow_store);
            const { x = 0, y = 0, zoom = 1 } = flow.viewport;
            if (rfInstance) {
                rfInstance.setViewport({ x, y, zoom });      
            }            
        }

        initEdges = edges_store ? JSON.parse(edges_store) : [];
        initNodes = nodes_store ? JSON.parse(nodes_store) : [];
        const root = initNodes.find((v) => v.data?.root === true);
        if (!root) {
            console.log('error');
            initEdges = [];
            initNodes = [];
        } 
    }

    if (initNodes.length === 0) {
        initNodes.push({id:ROOT_ID,  position:{x:0, y:-200}, data:{ret_type:ValueType.TYPE_BOOL, root:true, index:0}, type:"CustomNode", sourcePosition:Position.Top, targetPosition:Position.Bottom})
        localStorage.setItem(GUARD_LAUNCH_SOTRE_KEY_NODES, JSON.stringify(initNodes));
    }

    const [nodes, setNodes, onNodesChange] = useNodesState(initNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initEdges);
    const [clearDialog, setClearDialog] = useState(false);
    const [launchDialog, setLaunchDialog] = useState(false);
    const [description, setDescription] = useState('');

    const onNodeDragStop = () => {
        if (rfInstance) {
            const flow = rfInstance.toObject();
            localStorage.setItem(GUARD_LAUNCH_SOTRE_KEY_FLOW, JSON.stringify(flow));
        }

        localStorage.setItem(GUARD_LAUNCH_SOTRE_KEY_NODES, JSON.stringify(nodes));
        setReadStore(true)
    }

    const IsValidNode = (node:Node) : boolean => {
        const data : NodeData = node.data;
        if (!data.type) return false;
        // query cmd
        if (data.type === OperatorType.TYPE_QUERY) {
            //@ identifier 存在性检查前面已经完成了，需要检查witness
            if (data.cmd === undefined) return false;
            if (data.identifier === undefined && !IsValidAddress(data?.value)) return false;
        }
        
        // others
        const t = SER_VALUE.find((v)=>v.type === data.type);
        if (t && data.identifier === undefined && t.validator && !t.validator(data?.value)) {
            //console.log(t)
            //console.log(node)
            return false;
        }
        return true;
    }


    const ResolveNodes = (maker: GuardMaker, target:Node)  => {
        if (!IsValidNode(target)) {
            // enqueueSnackbar('Node Setting Invalid', { variant: "error" });
            throw target.id;
        }

        // 所有源节点，按index降序排序
        const source = edges.filter((v)=>v.target === target.id)
                .map((i) => nodes.find((j)=>j.id === i.source))
                .sort((a,b)=>{return (b?.data as NodeData)?.index - (a?.data as NodeData)?.index})
                .map((i)=>i?.id);

        // 递归
        for (let i = 0; i < source.length; ++ i) {
            const child = nodes.find((v) => v.id === source[i]);
            if (!child) {
                throw target.id;
            }
            ResolveNodes(maker, child!);
        }

        // 处理逻辑
        const data :NodeData = target.data; 
        //@ 检查常量表
        if (data?.identifier !== undefined && !maker.hasIdentifier(data.identifier)) {
            throw target.id;
        }

        if (data.type === OperatorType.TYPE_QUERY) {
            var f = Guard.GetCmd(data.cmd);
            if (!f) {
                throw target.id;
            }
            maker.add_query(f.module, f.query_name, data.identifier !== undefined ? data.identifier:data.value);
        } else if (IsValidOperatorType(data.type as number)) {
            maker.add_logic(data.type as number, data.value); //@ logics
        } else {
            if (data.identifier !== undefined) {
                maker.add_param(ContextType.TYPE_CONSTANT, data.identifier);
            } else {
                maker.add_param(data.type as number, data.value);
            }
        }
    }

    const ValidConstant = () : constItem[] => {
        const tb = constTable('any');
        const ret: constItem[] = [];
        //console.log(nodes)
        nodes.forEach((v) => {
            if (v.data?.identifier !== undefined) {
                const item = tb.find((i) => i.identifier === v.data.identifier);
                if (item) {
                    if (item.bWitness) item.value = undefined;
                    ret.push(item);
                } else {
                    ERROR(Errors.Fail, 'ValidConstant');
                }
            }
        });
        return ret
    }
  
    return ( 
        <Box sx={{height:'48em', mb:'1em'}}>
            <ReactFlow 
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                snapToGrid={true}
                snapGrid={snapGrid}
                fitViewOptions={fitViewOptions}
                fitView
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onNodeDragStop={ onNodeDragStop }
                onNodeDragStart={ () => {setReadStore(false) }}
                onMoveEnd={ onNodeDragStop }
                deleteKeyCode = { null }
                isValidConnection={()=>false}
                onInit={(rf) => setRfInstance(rf) }
                defaultViewport={defaultViewport}
                
            >
                <Controls />
                <Panel position="top-right">
                   <div style={{display:'flex', alignItems:'center', }}>
                    <Button variant='outlined' className='cmdButton' onClick={() => { 
                        if (!wallet.connected) {
                            enqueueSnackbar('Please login wallet', { variant: "error" });
                            document.getElementById('header-wallet-cmd')?.click();
                            return ;
                        }
                        setLaunchDialog(true)
                    }} style={{padding:'.2em .4em', fontSize:'.9em', textTransform:'none'}}>Launch</Button>
                    <Button variant='outlined' className='cmdButton' onClick={() => setClearDialog(true)}
                        style={{padding:'.2em .4em', fontSize:'.9em', textTransform:'none', marginLeft:'.6em'}}>Clear</Button>
                    </div> 
                </Panel>
            </ReactFlow>
            <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'sm'} disableRestoreFocus
                open={clearDialog}
                style={{}}
                TransitionComponent={Transition}
                keepMounted
                onClose={()=>setClearDialog(false)}
                >
                <DialogTitle sx={{textAlign:'center'}} > Clear Guard Cache
                    <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setClearDialog(false)}}> <CloseIcon /> </IconButton>   
                </DialogTitle>
                <DialogContent sx={{m:'1em'}}>
                    <Box sx={{fontSize:'1.2em', fontWeight:'500'}}>All content on the artboard will be cleared! </Box>
                    <SettingLaunch text='Clear' cancel='Cancel' event={(type, value, id) => {
                        if (type === 'click') {
                            localStorage.removeItem(GUARD_LAUNCH_SOTRE_KEY_NODES);
                            localStorage.removeItem(GUARD_LAUNCH_SOTRE_KEY_EDGES);
                            localStorage.removeItem(GUARD_LAUNCH_SOTRE_KEY_CONSTANTS);
                            window.location.reload();
                        }; setClearDialog(false);
                    }}/>
                </DialogContent> 
            </Dialog> 
            <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'sm'} disableRestoreFocus
                open={launchDialog}
                style={{}}
                TransitionComponent={Transition}
                keepMounted
                onClose={()=>setLaunchDialog(false)}
                >
                <DialogTitle sx={{textAlign:'center'}} > Launch Guard
                    <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setLaunchDialog(false)}}> <CloseIcon /> </IconButton>   
                </DialogTitle>
                <DialogContent >
                    <SettingTitle title='Guard Description'/>
                    <SettingInputText placeholder='Enter the name or description of the Guard' maxlength={MAX_DESCRIPTION_LENGTH} multiline maxRows={6} autoFocus
                        value={description}
                        event={(type, value, id)=>{ setDescription(value) }}/>
                    <SettingLaunch text='Launch' event={async (t) => {
                        const root = nodes.find((v)=>v.data?.root === true);
                        if (!root) {
                            enqueueSnackbar('Root node Invalid', { variant: "error" });
                            return 
                        }

                        try {
                            const txb = new TransactionBlock();
                            const maker = new GuardMaker();
                            //console.log(root); console.log(nodes)
                            try {
                                //@ 处理有效的常量
                                ValidConstant().forEach((v) => {
                                    maker.add_constant(v.type, v.value, v.identifier, true);
                                })
                                ResolveNodes(maker, root);
                            } catch(e:any) {
                                console.log(e)
                                const i = (e as string).indexOf(':');
                                if (i > 0) { //@ 错误处理，没有标准，argly
                                    enqueueSnackbar('Build error:'+(e as string)/*.slice(i+1)*/, { variant: "error" });
                                } else {
                                    const id : string = e;
                                    enqueueSnackbar('Node setting invalid', { variant: "error" });
                                    document.getElementById(id)?.click();
                                }
                                setLaunchDialog(false);
                                return 
                            }
                            
                            add_resource_launched(account, txb, [Guard.New(txb, description, maker.build()).launch()]);
                            props.exe(generateRandomString(8), txb, 'guard::Guard')
                        } catch (e) {
                            console.log('excute error: ', e);
                            enqueueSnackbar('Launch failed', { variant: "error" });
                        }
                    }}/>
                </DialogContent> 
            </Dialog> 
    </Box>);
}

export default GuardGraph ;