
import React, { useState, useEffect } from 'react';
import '../../css/Launch.css';
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation } from 'react-router-dom';
import Data from '../launch/repository/Data';
import { Protocol, RepositoryValueType, PermissionIndexType, Repository_Policy } from 'wowok';
import Policy from '../launch/repository/Policy';
import Details from './Details';
import { Pannel, a11yProps} from '../util/Common';


export default function RepositorySetting (props:any) {  
    const location = useLocation()
    var t = parseInt(location.hash.slice(1));
    if (t < 0 || t > 2 ||  isNaN(t))  { t = 0; }
    window.location.hash = '#' + t;

    const policies: Repository_Policy[]= props?.contents?.fields?.policies?.fields?.contents?.map((v:any) => {
      return {key:v?.fields?.key, description:v?.fields?.value?.fields?.description, 
        permissionIndex:v?.fields?.value?.fields?.permission_index, dataType:v?.fields?.value?.fields?.value_type}
    })

  const id = props?.contents?.fields?.id?.id ?? '';
  const [value, setValue] = React.useState(t);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    window.location.hash = '#' + newValue;
    setValue(newValue);
  };
    
    return (
      <Paper sx={{ width: '100%', pt:'.4em',}} >
              <Tabs value={value} onChange={handleChange} sx={{height:id ? '3em' : '3.8em', margin:'.4em 1em'}}>
                <Tab label='Data' {...a11yProps(0)} sx={{textTransform:'none', pb:'.2em'}} value={0}/>
                <Tab label="Policy"  {...a11yProps(1)} sx={{textTransform:'none', pb:'.2em'}} value={1}/>
                <Tab label="Promise"   {...a11yProps(2)} sx={{textTransform:'none', pb:'.2em'}} value={2}/>
              </Tabs>
            <Pannel value={value} index={0}>
              <Data {...props} policies={policies}/>
            </Pannel>
            <Pannel value={value} index={1}>
              <Policy {...props} policies={policies}/>
            </Pannel>
            <Pannel value={value} index={2}>
              <Details {...props} />
            </Pannel>
        </Paper>
    );
}