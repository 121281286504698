
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box, Typography, Tooltip, Button, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { PermissionAnswer, Service as WowokService, Permission, PermissionIndex, PermissionIndexType, Passport, ServiceAddress} from 'wowok';
import { SettingSwitch, SettingTips, SettingTitle, SettingCoinType, SettingLaunch, SettingInputText } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { TransactionBlock, } from 'wowok';
import { generateRandomString } from '../../../util';
import { make_permIconProp } from '../PermCheck';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { PermIcon } from '../../util/Icons';
import { grey } from '@mui/material/colors';
import { Transition } from '../../util/Common';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAccount, add_resource_launched } from '../../util/Account';

export default function Status (props:any) {
    const path = useLocation();
    const id = props?.contents?.fields?.id?.id ?? '';
    const permission = props?.contents?.fields?.permission;
    const answer: PermissionAnswer | undefined = props?.perms;
    const type = WowokService.parseObjectType(props?.type);
    const [open, setOpen] = useState(false);
    const [newType, setNewType] = useState(type);
    const [alert, setAlert] = useState('');

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const account = useAccount();

    const launch = async (perm:PermissionIndexType , param:any) => {      
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }
        
      if (perm === PermissionIndex.service_publish && props?.contents?.fields?.withdraw_guard?.fields?.contents?.length === 0) {
        enqueueSnackbar('Need to add at least one Withdraw Guard', { variant: "error" });
        window.location.href = '/'+id+'?setting#1'; //@ withdraw guard
        return 
      }
        const txb = new TransactionBlock();
        const obj = WowokService.From(txb, type, permission, id);
        const pid = generateRandomString(8);
        props.permissioncheck({id:pid, txb:txb, answer:answer, index:[perm], handler:
            (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
            try {
                if (id === pid) {
                    if (index.includes(perm)) {
                        if (perm === PermissionIndex.service_clone) { //@ use new token type
                            add_resource_launched(account, txb, [obj.clone(param, true, passport?.get_object()) as ServiceAddress]) ;                           
                        } else if (perm === PermissionIndex.service_publish) {
                            obj.publish(passport?.get_object());
                        } else if (perm === PermissionIndex.service_pause) {
                            obj.pause(param, passport?.get_object());
                        }
                    };    
                    passport?.destroy(); // destroy passport
                    if (perm === PermissionIndex.service_clone) {
                        props.exe(pid, txb, 'service::Service');
                    } else {
                        props.exe(pid, txb);
                    }
                }
            } catch (e) {
                console.log(e)
                enqueueSnackbar( 'Launch Failed', { variant: "error" });
            }}
        });
    }
    
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
            {props?.contents?.fields?.bPublished === true && path.search.toLowerCase().includes('setting') && 
                <SettingTips text={<>
                <span>The service has been released. Machine, Withdraw/Refund Guards, etc. will not be changed. You can modify and form a new Service through the Clone method blow.</span>
                </>} />
            }
            {props?.contents?.fields?.bPublished === false && <SettingSwitch  title='Allow Ordering' checked={props!.contents!.fields!.bPublished} 
                tips="The status is irreversible. If set, Service's machine & guards would not be modified." 
                disabled={props!.contents!.fields!.bPublished}
                noPerm={Permission.HasPermission(answer, PermissionIndex.service_publish)?.has === false}
                perm={make_permIconProp(answer, PermissionIndex.service_publish)}
                event={(type, value, id) => {
                if (type === 'check') {
                    if (props?.contents?.fields?.withdraw_guard?.fields?.contents?.length === 0) {
                        enqueueSnackbar('Need to add at least one Withdraw Guard', { variant: "error" });
                        window.location.href = '/'+id+'?setting#1'; //@ withdraw guard
                        return 
                    }

                    var tips = ''; 
                    if (props?.contents?.fields?.withdraw_guard?.fields?.contents?.length === 0) {
                        tips = 'Withdrawal Guard promise is not set (withdrawal from the order can be made at any time). \r\n\r\n';
                    } 
                    if (props?.contents?.fields?.refund_guard?.fields?.contents?.length === 0) {
                        tips = tips + 'Refund Guard promise is not set (orders can be refunded at any time). \r\n\r\n';
                    }
                    tips += 'Are you sure you want to allow orders?';
                    setAlert(tips);
                }
            }}/>}
            {props?.contents?.fields?.bPublished === true && <SettingSwitch  title='Allow Ordering'  checked={!props!.contents!.fields!.bPaused} 
                noPerm={Permission.HasPermission(answer, PermissionIndex.service_pause, true)?.has === false}
                perm={make_permIconProp(answer, PermissionIndex.service_pause)}
                event={(t, v, d) => {
                if (t === 'check') {
                    launch(PermissionIndex.service_pause, !(v as boolean)); 
                }}}
                tips='If ordering paused, new Order would not be generated.'
            />}
            <div style={{margin:'1.4em 0 .4em 0'}}>
                <Typography display='inline' sx={{fontSize:'1.1em', fontWeight:500}}>Clone Service</Typography>
                <Tooltip title={'After cloning a service, you can edit the cloned service, such as changing coin type, guards, etc...'} arrow placement='right'>
                    <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                </Tooltip>
                <Button variant='contained' sx={{ml:'1.6em'}} size='small' 
                    disabled={Permission.HasPermission(answer, PermissionIndex.service_clone, true)?.has === false}
                    onClick={()=>setOpen(true)}>
                    {<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                    <span>Clone</span> 
                    <PermIcon {...make_permIconProp(answer, PermissionIndex.service_clone)} />
                    </div>}
                </Button>
            </div>
            <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth='md' disableRestoreFocus id='service-clone-dlg'
            open={open} disableEnforceFocus 
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>setOpen(false)}
            >
            <DialogTitle sx={{textAlign:'center'}} > Service Clone
                <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setOpen(false)}}> <CloseIcon /> </IconButton>   
            </DialogTitle>
                <DialogContent>
                    <SettingTips text='New service that cloned from this service, would be changable for settings.'/>
                    <SettingTitle title='New Service Token' required tips='The payer uses this token to pay for the service order, and the payee receives this token. Once the service is launched, the token cannot be changed, but you can clone the service to modify the token.'/>
                    <SettingCoinType err_empty='Please enter token type that payed by payer'  placeholder='Enter token type that payed by payer' 
                        id='token' initValue={newType} disablePortal={true} event={(type, value, id) => {
                            setNewType(type === 'value' ? value : '');
                        }}/>
                    <SettingLaunch text='Launch' event={(t) => {
                        if (!newType) {
                            enqueueSnackbar('Token invalid', { variant: "error" });
                            document.getElementById('header-wallet-cmd')?.click();
                        }
                        launch(PermissionIndex.service_clone, newType);
                    }}/>
                </DialogContent>
            </Dialog>
            <Dialog onClick={(e)=>e.stopPropagation()} fullScreen={useMediaQuery(theme.breakpoints.down('sm'))} disableRestoreFocus  fullWidth maxWidth='sm' id='service-alert-dlg'
            open={alert !== ''} disableEnforceFocus 
            TransitionComponent={Transition}
            keepMounted
            onClose={()=> { setAlert(''); window.location.reload() }}
            >
            <DialogTitle sx={{textAlign:'center'}} > Allow Ordering
                <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{ setAlert(''); window.location.reload() }}> <CloseIcon /> </IconButton>   
            </DialogTitle>
                <DialogContent>
                        <SettingInputText placeholder='Description not set yet.' maxlength={1000} multiline maxRows={6} readOnly
                            value={alert} noneBorder={true} noneScroll={true} sx={{fontWeight:400}}
                            />
                    <SettingLaunch text='Comfirm & Launch' event={(t) => {
                        launch(PermissionIndex.service_publish, undefined); 
                    }}/>
                </DialogContent>
            </Dialog>
        </Box>
    );  
  }