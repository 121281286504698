import { Box, IconButton, TextField, Tooltip, Autocomplete, Chip, Popper, OutlinedInputProps, OutlinedInput, 
    FormHelperText, InputAdornment, Paper, Modal, ListItem, ListItemText, ListItemButton, MenuItem, Backdrop,
    CircularProgress, Switch, FormControl, InputLabel, TextFieldProps, Select, Typography, Button } from "@mui/material";
import '../../css/Common.css';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, styled} from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import { useState, ReactNode, useEffect } from "react";
import { grey } from '@mui/material/colors';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { IsValidAddress, Protocol, isValidHttpUrl, IsValidTokenType, IsValidPercent, IsValidInt, IsValidArgType, 
    ParseType, ResolveBalance, CoinTypeInfo,  RepositoryValueType, RepositoryValueTypeInfo, IsValidU8, IsValidU64,
    IsValidU128, MAX_U64, IsValidU256} from "wowok";
import { generateRandomString, sliceType, simpleAddress } from '../../util';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';
import { PaginatedObjectsResponse, CoinBalance } from "@mysten/sui/client";
import { FixedSizeList } from 'react-window';
import LinkIcon from '@mui/icons-material/Link';
import { Address } from "../util/Address";
import { PermIcon, PermissionIconProp, } from "../util/Icons";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export const U64_LENGTH = 20; // max u64
export const PersonalPermissionModule = 'PersonalPerm';
export const PersonalPermissionDescription = 'Business-Permissions';

interface SettingTitleProp {
    title: string | any;
    tips?: string | any;
    required?: boolean;
    checkbox?: string;
    id?:string;
    checked?: boolean;
    event?: SettingEvent;
    width?:string | number;
    perm?: PermissionIconProp;
    noPerm?: boolean;
    titleWidth?: string;
}

export type SettingEvent = (type:'value' | 'error' | 'click' | 'check' | 'starttime' | 'endtime' | 'close' | 'cancel' , value:string | any, id?:string) => void;

export type funcValidator = (value:string) => boolean;
export type async_funcValidator = (value:string) => Promise<boolean>;
export interface Validator {
    validator: funcValidator | async_funcValidator;
    err: string;
}

export const ADDRESS_VALIDATOR: Validator = {
    validator:(value:string) => { 
        return !value || IsValidAddress(value);
    },
    err:'Please enter a valid address. The address starts with "0x" and 66 length'
};

export const ADDRESS_VALIDATOR_REQUIRED: Validator = {
    validator:(value:string) => { 
        return IsValidAddress(value);
    },
    err:'Please enter a valid address. The address starts with "0x" and 66 length'
};

export const PERCENTAGE_VALIDATOR: Validator = {
    validator:IsValidPercent, 
    err:'Be a integer[1 - 100]'
};

export const U8_VALIDATOR: Validator = {
    validator:IsValidU8,
    err: 'Be Unsigned integer, and <= 256'
}
export const U64_VALIDATOR: Validator = {
    validator:IsValidU64,
    err: 'Be integer range: [0, 18446744073709551615]'
}
export const U128_VALIDATOR: Validator = {
    validator:IsValidU128,
    err: 'Value overflow'
}
export const U256_VALIDATOR: Validator = {
    validator:IsValidU256,
    err: 'Value overflow'
}
export const INT_VALIDATOR: Validator = {
    validator:IsValidInt,
    err: 'Be integer'
}
export const ENDPOINT_VALIDATOR: Validator = {
    validator:(value:string) => { 
        if (value) {
            return isValidHttpUrl(value);
        }; return true
    },
    err: 'Please enter a endpoint that Starts with "ipfs://" or "https://"'
}

const ERR_TOKENTYPE_INVALID = 'Please enter a valid token type';

interface SettingCoinTypeProp {
    placeholder?: string;
    event?:SettingEvent;
    err_empty?: string;
    id?: string;
    initValue?: string;
    disabled?: boolean;
    disablePortal?:boolean;
} ;

const useStyle = makeStyles(() => ({
    popperRoot: {
      [`& .MuiAutocomplete-noOptions`]: {
        display: 'none'
      }
    }
  }))

const CustomerPopper = (props:any) => {
    const classes = useStyle();
    const modifiers = [
      {
        name: 'flip',
        options: {
          fallbackPlacements: []
        },
      },
    ]
    return (
      <Popper
        {...props}
        modifiers={modifiers}
        className={classes.popperRoot}
        popperOptions={{
          placement: 'bottom-start',
        }}
      />
    )
  }

  export type SettingCoinInputProp = {
    value: string;
    symbol?: string;
    decimals?: number;
    max?: string;
    min?: string;
    event: SettingEvent;
    noPerm?: boolean;
    readOnly?: boolean;
  }& TextFieldProps ;

export function SettingCoinInput (props:SettingCoinInputProp) {
    const [err, setErr] = useState('');

    var endAdornment = '';
    if (props?.decimals && props?.symbol) {
        endAdornment = '≈' + ResolveBalance(props.value.toString(), props?.decimals) + ' ' + props?.symbol;
    }

    const validateInput = (value:string)  => {
        let err = '';
        if (value) {
            value = value.slice(0, U64_LENGTH);
        }

        try {
            const i = BigInt(value);   
            var max = props?.max !== undefined ? BigInt(props.max) : MAX_U64;
            if (max > MAX_U64) max = MAX_U64;
            var min = props?.min !== undefined ? BigInt(props.min) : BigInt(1);
            if (i > max) {
                err = 'Maximum overflow (Must be <= ' + max + ') ';
            } else if (i < min) {
                err = 'Minimum overflow (Must be >= ' + min + ') ';
            }
        } catch (e) {
            err = 'Please input a positive number';
            console.log(e)
        } 

        setErr(err);
        props.event(err? 'error' : 'value', value, props?.id);
    }

    const readonly = props?.readOnly ? {readOnly:true} : {}
    return (<TextField {...props} disabled={props.disabled || props?.noPerm === true} sx={{ 
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        display: "none",
        },
        "& input[type=number]": {
        MozAppearance: "textfield",
        },}} value={props.value} type="number"  InputProps={{ ...props.InputProps, ...readonly,
            endAdornment: <InputAdornment position="end">{<span style={{textAlign:'right'}}>{endAdornment}</span>}</InputAdornment>,
            inputProps:{min:1, max: props?.max ?? ''}, 
        }} onChange={(e) => { validateInput(e.target.value )}} helperText={err} error={err?true:false}
    />);
}

export type SettingPwdProp = {
    value: string;
    event: SettingEvent;
} & TextFieldProps;


export const PwdValidator:Validator = {
    err: '8-16 characters, including 2 types of letters, numbers and characters.',
    validator:(value:string) => {
        const regex = /^(?!\d+$)(?![a-zA-Z]+$)(?![^a-zA-Z\d]+$)\S{8,}$/;
        return regex.test(value);
    }
}

export function SettingPwd(props: SettingPwdProp) {
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    return (<TextField {...props}  error = {error ? true:false} helperText={error}
        variant="outlined" value={props.value}
        type={showPassword ? "text" : "password"} // <-- This is where the magic happens
        onChange={(e) => {
            let v  = e.target.value;
            let err = '';

            if (v) {
                v = v.slice(0, 16);
            }      
            console.log(v)

            if (!PwdValidator.validator(v)) {
                err = PwdValidator.err;
            }

            setError(err);
            props.event('value', v, props?.id)
        }}
        InputProps={{...props.InputProps,// <-- This is where the toggle button is added.
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />)
}

export type SettingInputTextProp = {
    event?: SettingEvent;
    min?: string | number; // for float
    max?: string | number; 
    err_empty?: string;
    err_bigint?: string;
    err_min?: string;
    err_max?: string;
    validator?: Validator ;
    maxlength: number;
    errorinline?: boolean;
    noNumberSpin?: boolean;
    label?:string;
    noneBorder?:boolean;
    noneScroll?:boolean;
    noPerm?: boolean;
} & OutlinedInputProps ;


export function SettingInputText (props: SettingInputTextProp) {
    const {event, errorinline, min, max, err_bigint, err_empty, err_max, err_min, validator, maxlength, sx, ...textfiledProps} = props;
    const [error, setError] = useState('');
    const validate = (value:string)  => {
        let err = '';
        if (value && maxlength > 0) {
            value = value.slice(0, maxlength);
        }

        if (err_empty && (!value || value?.length === 0)) {
            err = err_empty;
            if (event) { 
                event(err?'error':'value', value, props?.id)
            }
    
            setError(err);
        } 

        if (!err_empty && value.length === 0) {
            // dont valid
        } else if (err_bigint) {
            try {
                const v = BigInt(value);
                if (err_min && min !== undefined && v < BigInt(min)) {
                    err = err_min;
                } else if (err_max && max !== undefined && v > BigInt(max)) {
                    err = err_max;
                } else if (validator) {
                    err = validator.validator(value) ? '' : validator.err;
                }  
            } catch (e) {
                err = err_bigint;
            }
        } else {
            const v = parseFloat(value);
            const min_ = min !== undefined ? (typeof(min) === 'number' ? min : parseFloat(min as string)) : undefined;
            const max_ = max !== undefined ? (typeof(max) === 'number' ? max : parseFloat(max as string)) : undefined;
            
            if (err_empty !== undefined && value.length === 0) {
                err = err_empty;
            } else if (err_min !== undefined && min_ !== undefined && v < min_) {
                err = err_min;
            } else if (err_max !== undefined && max_ !== undefined && v > max_) {
                err = err_max;
            } else if (validator) {
                err = validator.validator(value) ? '' : validator.err;
            }            
        }

        if (event) { 
            event(err?'error':'value', value, props?.id)
        }

        setError(err);
    }

    const spin = props?.noNumberSpin ? {width:'100%',  
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        display: "none",
      },
        "& input[type=number]": {
        MozAppearance: "textfield",
      },} : {};
    const border = props?.noneBorder ?  {"& fieldset": { border: 'none' }} :{}

    return (
        <FormControl sx={{mt: props?.label ? 1 : 0}} style={{display:errorinline?'flex':'block', alignItems:'flex-end', flexDirection:'row'}}> 
            {props?.label && <InputLabel htmlFor={props?.id ? props.id : 'setting-OutlinedInput'} >{props.label}</InputLabel> }
            <OutlinedInput fullWidth {...textfiledProps} sx={{...spin, ...border, ...sx}}  className={props?.noneScroll?'noneScroll':'somesome'}
            autoComplete='off' disabled = {props.disabled || props?.noPerm === true}
            value={props.value}
            id={props?.id ? props.id : 'setting-OutlinedInput'}
            error={error?true:false}
            type={props.type === 'number' || err_bigint || err_min || err_max || min || max ? 'number':'text'}
            onChange={(e)=>validate(e.target.value)}
            onBlur={(e)=> {
                if (typeof(props?.value) === 'string') {
                    validate(props.value)
                } else if (typeof(props?.value) === 'number') {
                    validate(props.value.toString())
                } else {
                    if (props?.value === null || props?.value === undefined) return
                    validate(props.value.toString())
                }
            }}
            inputProps={{min:min, max:max}}
            label={props?.label}
            />
            {error && !props.disabled &&  <FormHelperText sx={{whiteSpace:'nowrap', ml:'1em'}} error>{error}</FormHelperText>}       
        </FormControl> 
    )
}

export type SettingSelectProp = {
    value: RepositoryValueType;
    err?: string;
    event:SettingEvent;
} & TextFieldProps;

export function SettingValueType(props: SettingSelectProp) {
    const {value, err, event, ...textFieldProps} = props;
    const [error, setError] = useState('');
    return (<TextField  select value={props.value} error = {error ? true:false} helperText = {error} 
        fullWidth {...textFieldProps} 
        onChange={(e) => {
            setError('');
            if (event) {
                event('value', parseInt(e.target.value) as RepositoryValueType, textFieldProps?.id);
            }
        }} onBlur={()=> {
            if (!value && err) {
                setError(err);
            }
        }}  SelectProps={{ MenuProps: { sx: { maxHeight: 400 } } }} >
        { RepositoryValueTypeInfo.map((v) => {
            return (<MenuItem key={v.type} value={v.type}>{v.name}</MenuItem>)
            })
        }
    </TextField> );  
}

export function SettingCoinType(props:SettingCoinTypeProp) {
    const {event, placeholder, err_empty, id, initValue} = props;

    const options: string[] = Protocol.Instance().COINS_TYPE().map((v:any)=>v.type);
    const name = (option:string) => {
        let f = Protocol.Instance().COINS_TYPE().find((v:any)=>v.type === option);
        if (f) return f.symbol;
        return ''
    } 

    const [input, setInput] = useState(initValue ?? "");
    const [error, setError] = useState('');

    const validate = (value:string) => {
        let err = '';
        if(err_empty && value.length === 0) {
            err = err_empty;
        }

        if (!IsValidTokenType(value)) {
            err = ERR_TOKENTYPE_INVALID;
        }

        if (event) {
            event(err?'error':'value', value, id)
        }
        setError(err);
    }

    return (
        <Autocomplete disablePortal={props?.disablePortal}
        freeSolo={true}
        forcePopupIcon={false}
        value={input}
        disabled={props?.disabled}
        PopperComponent={(props) => <CustomerPopper {...props} />}
        renderTags={() => null}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={generateRandomString(8)}>
            <Box
              sx={{
                flexGrow: 1,
                color:grey[700]
              }}
            >
              {option as string} 
            </Box>
            <Box         
                sx={{
                color:grey[500]
              }}>{name(option as string)}</Box>
          </li>
        )}
        options={[...options].sort((a, b) => {
          // Display the selected labels first.
          let ai = input.indexOf(a);
          ai = ai === -1 ? input.length + options.indexOf(a) : ai;
          let bi = input.indexOf(b);
          bi = bi === -1 ? input.length + options.indexOf(b) : bi;
          return ai - bi;
        })}
        onBlur={()=>validate(input)}
        onInputChange={(event, value, reason)=>{
            validate(value);
            setInput(reason === 'clear' ? '' : value);
        }}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            error = {error ? true:false}
            helperText = {error}
            placeholder={placeholder}
          />
        )}
      />
    );
}

interface SettingChipsProp {
    maxTags : number;
    maxTags_err: string;
    placeholder?: string;
    required ?: string;
    id?: string;
    limitTags?: number;
    event?: SettingEvent;
    tags: string[];
    autoFocus?: boolean;
    readonly?: boolean;
    type: RepositoryValueType.Address_Vec | RepositoryValueType.String_Vec | RepositoryValueType.PositiveNumber_Vec;
}

export function SettingChips (props:SettingChipsProp) {
    const [error, setError] = useState('');
    const [value, setValue] = useState('');
    
    let maxLen = 10240;
    if (props.type === RepositoryValueType.Address_Vec) { 
        maxLen = 66;
    } else if (RepositoryValueType.PositiveNumber_Vec) {
        maxLen = 77;
    }

    return (
        <Autocomplete
            clearIcon={false} autoFocus={props?.autoFocus} options={[]} freeSolo multiple  id={props?.id} 
            readOnly={props.readonly}
            isOptionEqualToValue={() => false}
            value={props.tags} onChange={(event, value) => {
                if (props?.event) {
                    props.event("value", value, props?.id)
                }
            }}
            ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
            limitTags={props?.limitTags}
            renderTags={(value, myprops) => {
                return (<>
                {
                    value.map((option, index) => {
                        if (props.type === RepositoryValueType.Address_Vec && IsValidAddress(option)) {
                            return (<Chip color="info"  clickable label={<Address address={option} />} {...myprops({ index })} />) 
                        } else if ((props.type === RepositoryValueType.PositiveNumber_Vec && IsValidU64(option)) ||
                            (props.type === RepositoryValueType.String_Vec && option.length <= maxLen)) {
                                if (option.length < 44) {
                                    return (<Chip color="info" clickable label={<span onClick={(e) => {
                                        navigator.clipboard.writeText(option).catch(e=>console.error(e));
                                    }} >{option}</span>} {...myprops({ index })} />) 
                                } else {
                                    return (<>
                                    <Tooltip title={option}>
                                        <Chip color="info" clickable label={<span onClick={(event:any) => {
                                            navigator.clipboard.writeText(option).catch(e=>console.error(e));
                                        }}>{option.slice(0, 44) + '...'}</span>} {...myprops({ index })} 
                                        />
                                    </Tooltip>
                                    </>)
                                }

                        } ; return (null);
                    })                    
                }
                </>)
            }}
            renderInput={(params) => <TextField {...params} error={error?true:false} helperText={error} autoFocus={props?.autoFocus}
                placeholder={props?.placeholder}
                value={value}
                maxRows={6}
                multiline
                onChange={(e) => {
                    setValue(e.target.value);
                    setError('')
                }}
                inputProps={{...params.inputProps, maxLength:{maxLen}}}
                onKeyDown={(event) => {
                    if(event.code === 'Enter') {
                        if (props.type === RepositoryValueType.Address_Vec) {
                            if (!IsValidAddress(value)) {
                                setError('Please enter a valid address or Object id');
                                event.stopPropagation();     
                                event.preventDefault()                           
                            }
                        } else if (props.type === RepositoryValueType.PositiveNumber_Vec) {
                            if (!IsValidU64(value)) {
                                setError('number invalid or greater than MAX_U256');
                                event.stopPropagation();    
                                event.preventDefault()   
                            }
                        }
                    }
                    if (props.tags.length >= props.maxTags) {
                        setError(props?.maxTags_err);
                        event.stopPropagation();
                        event.preventDefault();
                    }
                }}
            />}
        />
    )
}

interface SettingAddressChipsProp {
    maxTags : number;
    maxTags_err?: string;
    placeholder?: string;
    required ?: string;
    id?: string;
    limitTags?: number;
    event?: SettingEvent;
    initValue?: string[];
    autoFocus?: boolean;
    readonly?: boolean;
    size?: 'small' | 'medium';
    type?: string;
    validator?:Validator;
    noPerm?:boolean;
}

export function SettingAddressChips (props:SettingAddressChipsProp) {
    const [error, setError] = useState('');
    const [value, setValue] = useState('');
    const [tags, setTags] = useState<string[]>(props?.initValue ?? []);

    const validate = (tags: any[]) => {
        let err = ''
        if(props?.required && tags.length === 0) {
            err = props.required;
        }

        setError(err)
    }
    const border = props?.readonly ? {"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
        {
          border: "1px solid ", borderColor:grey[300]
        },  '& .MuiOutlinedInput-root': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor:grey[300]
            },'.MuiOutlinedInput-notchedOutline': {
                borderColor:grey[300]
            },
        },} : undefined;
    return (
        <Autocomplete sx={{...border}}
            clearIcon={false} autoFocus={props?.autoFocus} options={[]} freeSolo multiple  id={props?.id} readOnly={props.readonly}
            value={tags} size={props?.size } disabled={props?.noPerm ? true : false} onChange={async (event, value) => {
                setTags(value);
                if (props?.event) {
                    props.event("value", value, props?.id)
                }
            }}
            limitTags={props?.limitTags}
            onBlur={()=>validate(tags)}
            renderTags={(value, myprops) => {
                let res: ReactNode[] = [];
                value.forEach((option, index) => {
                    if (IsValidAddress(option)) {
                        res.push(<Chip color="info"  label={<Address address={option} showType={props?.type ?? true}/>} {...myprops({ index })} />  
                    );} 
                });
                return res;
            }}
            renderInput={(params) => <TextField {...params} error={error?true:false} helperText={error} autoFocus={props?.autoFocus}
                placeholder={props?.placeholder}
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    setError('');
                }}
                onKeyDown={async (event) => {
                    if (event.code === 'Enter') {
                        if(!IsValidAddress(value)) {
                            setError(ADDRESS_VALIDATOR.err);
                            event.stopPropagation();
                            return 
                        }
                        if (props?.validator) {
                            const r = await props.validator.validator(value);
                            if (!r) {
                                setTags([...tags.filter((v)=>v!==value)]);
                                setError(props.validator.err);
                                return 
                            }
                        }
                        if (tags.length >= props.maxTags) {
                            setError(props?.maxTags_err ?? '');
                            event.stopPropagation();
                        }                        
                    }
                }}
            />}
        />
    )
}
export interface SettingShowValueProp {
    title: string | any;
    tips?: string | any;
    value?: any;
    mt?: string;
    mb?: string;
    showNotSet?: boolean;
}

export function SettingShowValue (props: SettingShowValueProp) {
    return (
        <div style={{marginTop:props?.mt ?? '1.4em', marginBottom:props?.mb ?? '.4em', display:'flex', alignItems:'center'}}>
            <div style={{marginRight:'1em'}}>
                <Typography display='inline' sx={{fontSize:'1.1em', fontWeight:500}}>{props.title}</Typography>
                {props.tips && <Tooltip title={props.tips} arrow placement='right'>
                    <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                </Tooltip>}                
            </div>
            {props?.value}
            {!props?.value && props?.showNotSet && <span style={{color:grey[400]}}>Not set yet</span>}
        </div>
    );  
}

export function SettingTitle2 (props: SettingTitleProp) {
    const theme = useTheme();
    const width = props?.width ? {width:props.width} : {};
    const title_width = props?.titleWidth ? {width:props.width} : {};
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props?.event) {
            props.event('check', event.target.checked, props?.id);
        }
      };

      return (
        <div style={{margin:'1.4em 0 .4em 0', ...width, display:'flex', alignItems:'center'}}>
            {props.required && <Typography  color={theme.palette.primary.main} display='inline'>*</Typography>}
            <Typography display='inline' sx={{fontSize:'1.1em', fontWeight:500, ...title_width}}>{props.title}</Typography>
            {props?.perm && <PermIcon {...props.perm}/>}
            {props.tips && <Tooltip title={props.tips} arrow placement='right'>
                <HelpOutlineIcon sx={{width:'0.6em!important', height:'0.6em!important', paddingLeft:'0.1em', color:grey[500]}} />
            </Tooltip>}
            {
                props?.checkbox && <FormControlLabel control={<Checkbox  checked={props.checked} onChange={handleChange} size="small" disabled={props.noPerm}/>}
                label={props.checkbox} 
                sx={{ml:'1em', "& span input":{fontSize:'0.9em'},"& .MuiCheckbox-root":{pr:'4px!important', pt:'8px!important'},}} />
            }
        </div>
    );
}

export function SettingTitle (props: SettingTitleProp) {
    const theme = useTheme();
    const [checked, setChecked] = useState(props.checked);
    const width = props?.width ? {width:props.width} : {};
    const title_width = props?.titleWidth ? {width:props.width} : {};
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        if (props?.event) {
            props.event('check', event.target.checked, props?.id);
        }
      };

      return (
        <div style={{margin:'1.4em 0 .4em 0', ...width, display:'flex', alignItems:'center'}}>
            {props.required && <Typography  color={theme.palette.primary.main} display='inline'>*</Typography>}
            <Typography display='inline' sx={{fontSize:'1.1em', fontWeight:500, ...title_width}}>{props.title}</Typography>
            {props?.perm && <PermIcon {...props.perm}/>}
            {props.tips && <Tooltip title={props.tips} arrow placement='right'>
                <HelpOutlineIcon sx={{width:'0.6em!important', height:'0.6em!important', paddingLeft:'0.1em', color:grey[500]}} />
            </Tooltip>}
            {
                props?.checkbox && <FormControlLabel control={<Checkbox  checked={checked} onChange={handleChange} size="small" disabled={props.noPerm}/>}
                label={props.checkbox} 
                sx={{ml:'1em', "& span input":{fontSize:'0.9em'},"& .MuiCheckbox-root":{pr:'4px!important', pt:'8px!important'},}} />
            }
        </div>
    );
}

interface SettingTipsProp {
    text: any;
    command?: string;
    to?: string;
    ml?: string;
    mt?: string;
    mb?: string;
}


export function SettingTips (props: SettingTipsProp) {
    const theme = useTheme();

    return (
        <div style={{  marginTop:props?.mt ? props.mt : '1.4em', marginBottom:props?.mb ? props.mb : '.4em',  display:'flex', alignItems:'center', backgroundColor:'rgba(232,167,213,0.06)'}}>
            <AnnouncementIcon fontSize="small" sx={{color:theme.palette.primary.main}}/>
            <div style={{marginLeft:'.6em', lineHeight:'2em', fontSize:'1.1em', fontStyle:'italic', color:grey[800]}}>{props.text}</div>
            { props.command && props.to && 
            <div className='cmdText' onClick={()=>window.open(props.to, '_blank')?.focus()}
                style={{marginLeft: props?.ml ? props.ml : 0, 
                    color:theme.palette.primary.main, cursor:'pointer'}}
            > 
            {props.command} </div> 
            }
        </div>
    )
}

interface SettingLaunchProp {
    text: string;
    id?: string;
    event: SettingEvent;
    cancel?: string;
    marginTop?: string | number;
}

export function SettingLaunch (props:SettingLaunchProp) {
    return (
        <div style={{display:'flex', alignItems:'baseline'}}>    
            <button  id={props?.id} className='cmdButton' style={{marginTop: props.marginTop ?? '3em'}}
        onClick={()=>props.event('click',  props.text, props?.id)}>{props.text}</button>
        { props?.cancel && <div style={{cursor:'pointer', marginLeft:'2em', fontSize:'1.1em', 
            color:grey[500]}} className="cmdText" 
        onClick={()=>props.event('cancel', props?.cancel, props?.id)}>{props?.cancel}</div> }
        </div>
    );
}

interface SettingGuardPercentProp {
    title: string;
    tips?: string;
    required?: boolean;
    id?:string;
    event: SettingEvent;
    max:number;
    initValue?:  SettingGuardRatio[];
    perm?: PermissionIconProp;
    noPerm?: boolean;
}

export interface SettingGuardRatio {
    guard: string;
    ratio: string;
}

export function SettingGuardPercent (props: SettingGuardPercentProp) {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [guard, setGuard] = useState<SettingGuardRatio>({guard:'', ratio:''});
    const [result, setResult] = useState<SettingGuardRatio[]>(props?.initValue ?? []);

    return (
        <div style={{margin:'1.4em 0 .4em 0'}}>
            <div style={{display:'flex', alignItems:'center'}}>
                {props.required && <Typography  color={theme.palette.primary.main} display='inline'>*</Typography>}
                <Typography display='inline' sx={{fontSize:'1.1em', fontWeight:500}}>{props.title}</Typography>
                {props?.perm && <PermIcon {...props.perm}/>}
                {props.tips && <Tooltip title={props.tips} arrow placement='right'>
                    <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                </Tooltip>}
                <Button variant='text' style={{fontSize:'1.1em', fontWeight:300, marginLeft:'2em', textTransform:'none'}} 
                    disabled={props.noPerm === true} onClick={()=>{
                        if (result.length >= props.max) {
                            enqueueSnackbar('Maximum of 16 guards', { variant: "error" });
                            return;
                        }
                        setGuard({guard:'', ratio:''});
                        setEdit(false);
                        setOpen(true);
                    }}>+ Add Guard</Button>
            </div>
            <div style={{backgroundColor:'rgba(232,167,213,0.06)', marginTop:'.4em', padding:'.6em 1em', maxWidth:'28em'}}>
                {result.length === 0 && <div style={{color:grey[500]}}>No Guard added yet</div>}
                {result.map((v, index) => {
                    return (
                    <div style={{display:'flex', alignItems:'center', width:'100%'}} key={index}>
                        <div style={{}}>
                            <Tooltip title={v.guard}> 
                                <Address address={v.guard} showType='Guard' maxWidth='20em'/>
                            </Tooltip>
                        </div>
                        <div style={{marginLeft:'2em', width:'4em', fontStyle:'italic', fontWeight:'600'}}>{v.ratio + '%'}</div>  
                        <div style={{marginLeft:'auto'}}>
                            <Tooltip title='Edit' arrow placement="left"> 
                                <IconButton className="cmdText" disabled={props.noPerm === true} onClick={()=>{
                                    setGuard({guard:v.guard, ratio:v.ratio}); 
                                    setEdit(true);
                                    setOpen(true);
                                }}><EditIcon sx={{width:'0.8em', height:'0.8em'}}/></IconButton>
                            </Tooltip>
                            <Tooltip title='Delete' arrow placement="right">
                                <IconButton className="cmdText" disabled={props.noPerm === true} onClick={()=>{
                                    //setResult(result.filter((i)=>i.guard!==v.guard));
                                    props.event('cancel', v.guard, props?.id);
                                }} sx={{ml:'.2em'}}><CloseIcon sx={{width:'0.9em', height:'0.9em'}}/></IconButton>
                            </Tooltip>
                        </div>
                    </div>
                )})}
            </div>
            <Modal disableRestoreFocus
                open={open}
                onClose={(event, reason)=> {
                }}
            >
                <Paper sx={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', width:'42em', p:'1em 2em 2em 2em',
                    boxShadow:'none', border:'2px solid', borderColor:theme.palette.primary.main,
                }}>
                    <IconButton sx={{float:'right'}} onMouseDown={()=>{setOpen(false)}}>
                        <CloseIcon />
                    </IconButton>
                    <Box width='100%' sx={{textAlign:'center'}}>
                        <Typography variant="h5">{'Add' + props.title} </Typography>
                     </Box>       
                    <div style={{marginTop:'2em'}}>
                        <SettingTitle required title={props.title} 
                            tips='Set withdrawal guards, the payee can only withdraw funds when the guard conditions are met. Support multiple guards. '/>
                        <SettingInputText autoFocus placeholder="Enter Guard address" maxlength={66} validator={ADDRESS_VALIDATOR} disabled={edit}
                            id='add-guard' value={guard.guard} err_empty="Please enter guard address" event={(type, value, id) => {
                                guard.guard = value;
                                setGuard({...guard});
                            }}/>
                        <SettingTitle required title={props.title + 'ratio'} tips="If the guard conditions are met, the payee will withdraw according to the corresponding ratio."/>
                        <SettingInputText maxlength={3} validator={PERCENTAGE_VALIDATOR} type="number" min={0} max={100} errorinline
                            endAdornment={<InputAdornment position="end">%</InputAdornment>} sx={{width:'18em'}} value={guard.ratio}
                            placeholder="[0-100] Integer"
                            id='add-guard-ratio' event={(type, value, id) => {
                                guard.ratio = type==='value'?value:'';
                                setGuard({...guard});
                            }}/>
                        <button id={props?.id} className='cmdButton' style={{marginTop:'1.5em'}} onClick={()=>{
                            props.event('value', guard, props?.id);
                        }}>Add Guard</button>                    
                    </div>
                </Paper>
            </Modal>
        </div>
    );
}

interface SettingTimeTimeProp {
    id?: string;
    start: string;
    end: string;
    event: SettingEvent;
}

export function SettingTimeTime (props: SettingTimeTimeProp) {

    const [startTime, setStartTime] = useState<Dayjs | null>(dayjs(props.start));
    const [endTime, setEndTime] = useState<Dayjs | null>(dayjs(props.end));
    const [error, setError] = useState('');

    return (
        <div style={{display:'flex', alignItems:'flex-end',}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}> 
                <DateTimePicker ampm={false}  value={startTime}  sx={{width:'16em'}}  onChange={(e:any) => {
                    setStartTime(e);
                    props.event('starttime', e?(e as Dayjs).valueOf():0, props?.id);   
                    if (!e) {
                        setError('Start time invalid')
                        return
                    } else if (endTime) {
                        if ((e as Dayjs).valueOf() + 60000 >= (endTime as Dayjs).valueOf()) {
                            setError('End time less than Start time');
                            return
                        }   
                    } 
                    setError('')                   
                }}   
                ></DateTimePicker >
                <div style={{display:'inline-block', marginLeft:'1em', width:'1em', height:'1.5em', borderTop:'1px solid', borderColor:grey[400]}}></div>
                <DateTimePicker ampm={false} value={endTime} sx={{ml:'1em', width:'16em'}} disablePast onChange={(e:any) => {
                    setEndTime(e);
                    props.event('endtime', e?(e as Dayjs).valueOf():0, props?.id);  
                    if (!e) {
                        setError('End time invalid')
                        return
                    } else if (startTime) {
                        if ((startTime as Dayjs).valueOf() + 60000 >= (e as Dayjs).valueOf()) {
                            setError('End time less than Start time');
                            return
                        }   
                    }
                    
                    setError('')
                }} />
            </LocalizationProvider>
            {error && <FormHelperText sx={{whiteSpace:'nowrap', ml:'1em'}} error>{error}</FormHelperText>}       
        </div>
    )
}

export interface SettingSwitchProp {
    title: string;
    tips?: string;
    disabled?: boolean;
    checked : boolean;
    event ?: SettingEvent;
    id ?: string;
    perm?: PermissionIconProp;
    readonly?: boolean;
    noPerm?: boolean;
}

export function SettingSwitch (props: SettingSwitchProp) {
    const theme = useTheme();
    const [checked, setChecked] = useState(props.checked);
    const color_style = (checked:boolean) => {
        return {color:grey[900]}
 /*       if (checked) return {color: theme.palette.primary.main};
        else return {color:grey[900]};*/
    }
    return (
        <div style={{margin:'1em 0 0 0', display:'flex', alignItems:'center'}}>
            <Typography display='inline' sx={{fontSize:'1.1em', fontWeight:500, ...color_style(checked)}}>{props.title}</Typography>
            {props?.perm && <PermIcon {...props.perm}/>}
            {props.tips && <Tooltip title={props.tips} arrow placement='right'>
                <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
            </Tooltip>}
            {
               <Switch checked={checked} sx={{ml:'1em'}} disabled={props?.disabled || props?.noPerm === true} 
                onChange={(e) => {
                    if (props?.readonly) return ;
                    setChecked(e.target.checked) 
                    if (props?.event) props.event('check', e.target.checked, props?.id);
               }}/>
            }
        </div>
    );
}

interface SettingTimePickerProp {
    tips?: string;
    bDuration: boolean;
    time?: number;
    required?: boolean;
    locker?: boolean;
    event?: SettingEvent;
    id?: string;
    duration?: number;
    perm?: PermissionIconProp;
}

const CssTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
    }
});

export interface SettingOwnedTypeProp {
    owner: string;
    event?: SettingEvent;
    id?: string;
}

interface SettingOwnedObjectType {
    type: string;
    count: number;
}

export function SettingOwnedType (props:SettingOwnedTypeProp) {
    const [data, setData] = useState<SettingOwnedObjectType[]>([]);

    const QueryTypes = (count:number, result:SettingOwnedObjectType[], cursor?:any) => {
        Protocol.Client().getOwnedObjects({owner:props.owner, options:{showType:true}}).then((res) => {
            count += 1;
            res.data.forEach((v) => {
                const index = result.findIndex((i) => v.data?.type &&  i.type === v.data?.type);
                if (index === -1) {
                    result.push({type:v.data!.type!, count:1});
                } else {
                    result[index].count += 1;
                }
            })
            setData([...result]);
            if (res.hasNextPage && count <= 40) {
                QueryTypes(count, result, res.nextCursor)
            }
        }).catch((e) => {
            console.log(e)
        });
    }

    var result:SettingOwnedObjectType[]  = [];
    var count = 0;

    useEffect(() =>  {
        QueryTypes(count, result);
    }, []);

    const [input, setInput] = useState('');
    const [error, setError] = useState('');

    const validate = (value:string) => {
        let err = '';
        if (!IsValidArgType(value)) {
            err = 'Please enter valid type'
        }

        if (props?.event) {
            props.event(err?'error':'value', value, props?.id)
        }
        setError(err);
        setInput(value);
    }

    return (
        <Autocomplete id = 'ownerd-type-selector'
        freeSolo={true}
        forcePopupIcon={false}
        value={input}
        renderTags={() => null}
        renderOption={(props, option, { selected }) => {
            const count = option.count > 999 ? '999+ objects' : option.count + ' objects';

            return (
                <li {...props} key={generateRandomString(8)}>
                    <Box
                    sx={{
                        whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',
                        flexGrow: 1, color:grey[700], width:'80%', letterSpacing:2, fontWeight:700
                    }}
                    >
                    {sliceType(option.type)} 
                    </Box>
                    <Box         
                        sx={{
                        color:grey[500]
                    }}>{count}</Box>
                </li>
            )}
        }
        options={[...data].sort((a, b) => {
          // Display the selected labels first.
          let ai = input.indexOf(a.type);
          ai = ai === -1 ? input.length + data.indexOf(a) : ai;
          let bi = input.indexOf(b.type);
          bi = bi === -1 ? input.length + data.indexOf(b) : bi;
          return ai - bi;
        })}
        onBlur={()=>validate(input)}
        onInputChange={(event, value, reason)=>{
            validate(value);
            setInput(reason === 'clear' ? '' : value);
        }}
        getOptionLabel={(option) => {
            if (typeof(option) === 'string') {
                return option;
            } else {
                return option.type;
            }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            variant="outlined"
            error = {error ? true:false}
            helperText = {error}
            placeholder={'Enter object type'}
          />
        )}
      />
      );
}

export interface SettingOwnedCoinSpliterProp {
    owner: string;
    type: string;
    max_portions: number;
    event?: SettingEvent;
    id?: string;
}
export interface SettingOwnedCoinSpliterData {
    total: string;
    object: string;
    portions: string;
    split: 'random' | 'average';
}

export const SettingOwnedCoinSpliter = (props: SettingOwnedCoinSpliterProp) => {
    const token = ParseType(props.type);
    const theme = useTheme();
    const [coin, SetCoin] = useState<CoinBalance | null>(null);
    const [data, setData] = useState<SettingOwnedCoinSpliterData>({object:'', total:'', portions:'10', split:'average'});

    const protocol = Protocol.Instance();
    const [coinType, setCoinType] = useState<CoinTypeInfo | 'loading'>(protocol.coinTypeInfo(token.coin, (res) => {
      if (res.type === token.coin) {
        setCoinType({...res});    
      }
    }))

    useEffect(() => {
        Protocol.Client().getBalance({owner: props.owner, coinType: token.coin}).then((res) => {
            SetCoin({...res});
            data.total = res.totalBalance;
            setData({...data})
        }).catch (e => {
            console.log(e)
        })        
    }, [props.owner, token.coin])

    var tips = ''
    if (coinType !== 'loading' && coin && data.total && data.total !== '0') {
        tips = ('≈' + ResolveBalance(data.total, coinType.decimals) + ' ' + coinType.symbol);
    } else {
        tips = ('')
    }

    return (<div>
        <Backdrop sx={{ color: '#fff', zIndex: 1000 }} open={!coin}>
        <CircularProgress color="inherit" />
        </Backdrop>
        {coin && <div>
            <SettingTitle title="Splitting portions" tips='How many cuts from bonus. Max potions 255.' required/>
            <div id='setting-portions' style={{border:'1px solid rgba(0, 0, 0, 0.23)', display:"inline-block", borderRadius:'0.3em', }}
            onMouseOver={()=>{  document.getElementById('setting-portions')!.style.borderColor='black'}}
            onMouseLeave={()=>{  document.getElementById('setting-portions')!.style.borderColor='rgba(0, 0, 0, 0.23)' }}
            onFocus={()=>{document.getElementById('setting-portions')!.style.borderColor=theme.palette.primary.main; document.getElementById('setting-portions')!.style.borderWidth='2px';}}
            onBlur={()=>{ document.getElementById('setting-portions')!.style.borderColor='rgba(0, 0, 0, 0.23)'; document.getElementById('setting-portions')!.style.borderWidth='1px'}}
            >
                <CssTextField type="number" id='setting-portions-number' InputProps={{inputProps: { min: 0, max: props.max_portions } }}  onChange={(e)=>{
                    data.portions = e.target.value;
                    setData({...data});
                    if (props?.event) 
                        props.event('value', data, props?.id)

                }} value={data.portions}  inputProps={{maxLength:3}}></CssTextField>
                <div style={{width:'1px', height:'100%', borderLeft:'1px solid #ccc  ', display:'inline'}}></div>
                <Select value={data.split} onChange={(e) => {
                    data.split = e.target.value as any;
                    setData({...data})
                    if (props?.event) props.event('value', data, props?.id)
                }} sx={{boxShadow: 'none', '& fieldset': {border:'none!important'}, '& input':{border:'none!important'}}}>
                    <MenuItem value='average'>Portions by Average splitting</MenuItem>
                    <MenuItem value='random'>Portions by Random splitting</MenuItem>
                </Select>
            </div>
            <SettingTitle title="Bonus added to reward" tips='Input bonus to deposit.' required/>
            <SettingOwnedObject owner={props.owner} type_filter={props.type} event={(type, value:SettingOwnedObjectResult, id) => {
                if (type ===  'value') {
                    data.object =  value.object;
                    data.total = value.balance;
                } else {
                    data.object =  ''
                    data.total = '';
                }
                setData({...data})
                if (props?.event) props.event('value', data, props?.id)
            }}/>
        </div>}
    </div>)
}
export interface SettingOwnedObjectMultiProp {
    owner: string;
    type_filter?:string;
    event?: SettingEvent;
    id?: string;
    max_select: number;
    err_max_select?: string;
}

export function SettingOwnedObjectMulti (props: SettingOwnedObjectMultiProp) {
    const [error, setError] = useState('');
    const [response, setResponse] = useState<PaginatedObjectsResponse | null>(null);
    const [selected, setSelected] = useState<string[]>([]);
    const filter = props.type_filter ? {StructType: props.type_filter} : null;

    const handleOwnerObject = (res: PaginatedObjectsResponse, nextCursor?:string|null|undefined) => {
        const f = res.data.filter((v) => (v.data?.content as any)?.hasPublicTransfer);
        if (nextCursor && nextCursor === response?.nextCursor) {
            res.data = response.data.concat([...f])
        } else {
            res.data = f
        }
        setResponse({...res});
    }

    const handleBottom = () => {
        if (response && response?.hasNextPage) {
            Protocol.Client().getOwnedObjects({owner:props.owner, filter:filter, cursor:response?.nextCursor, options:{showContent:true, showType:true}}).then((res) => {
                handleOwnerObject(res, response.nextCursor);
            }).catch((e) => {
                console.log(e);
            }) 
        }
    }
 
    useEffect( () => {
        Protocol.Client().getOwnedObjects({owner:props.owner, filter:filter, options:{showContent:true, showType:true}}).then((res) => {
            handleOwnerObject(res);
            setSelected([]);
        }).catch((e) => {
            console.log(e);
        })       
    }, []);

    //let tips = response?.hasNextPage ? 'Too many results, input words and press enter to filter.' : '';
    //let options: SuiObjectResponse[] = response?.data ?? [];

    const itemsize = 48; 
    const totalsize = 240;

    const Row = (props:any) => {
        const {data, index, style} = props;
        var tips = '';
        for (const prop in data[index].data.content.fields) {
            if (data[index].data.content.fields.hasOwnProperty(prop) && prop !== 'id') {
                tips += prop + ' : ' + JSON.stringify(data[index].data.content.fields[prop]) + '\n';
            }
        }
        let bSelected = selected.includes(data[index].data.objectId)
        return (
            <ListItem style={{...style, }} key={index} component="div" disablePadding onClick={() => {
                let i = selected.findIndex((v) => v === data[index].data.objectId);
                if (i === -1) { // add 
                    if (selected.length >= props.max_select) {
                        setError(props?.err_max_select);
                        return
                    }
                    selected.push(data[index].data.objectId);
                } else { // reduce
                    selected.splice(i, 1);
                }
                setError('');
                setSelected([...selected]);
                if (props?.event) {
                    props.event('value', selected, props?.id)
                }
            }}>
                <ListItemButton selected={bSelected} 
                    sx={{display:'flex', alignItems:'center', height:'48px', paddingLeft:'0', paddingRight:'.2em',
                        "&.Mui-selected": {
                            backgroundColor: "rgba(232,167,213,0.36)!important",
                          },
                    }} >
                    <Checkbox sx={{paddingLeft:'.4em'}} checked={bSelected}/>
                    <ListItemText sx={{color:grey[600]}} >
                        <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{tips}</div>}>
                        <span>{simpleAddress(data[index].data.objectId)} </span>
                        </Tooltip>
                        <Tooltip title='Click to object details.'  placement='right' arrow>
                            <LinkIcon className='cmdText' sx={{color:grey[400], height:'.6em', width:'.6em', transform:'rotateZ(-20deg)', ml:'.2em'}} onClick={() => {
                                window.open(Protocol.Instance().explorerUrl(data[index].data.objectId), '_blank')?.focus()
                            }}/>
                        </Tooltip>
                        <span style={{float:'right', color:grey[500], paddingRight:'.4em'}}>{sliceType(data[index].data.type)}</span>
                    </ListItemText>                  
                </ListItemButton>
            </ListItem>
        );
    }
    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper'}} 
        >
            <FixedSizeList height={totalsize} width='100%' itemData={response?.data} 
                style={{display:'block', border:'1px solid', borderColor:grey[200], marginBottom:'1em'}} 
                itemSize={itemsize} itemCount={response?.data?.length ?? 0} overscanCount={10}    onScroll={(e)=> {
                    if (response?.data && e.scrollOffset  >= (itemsize * response?.data.length - itemsize*5) * 0.9) {
                        handleBottom();
                    }
                }}>
                {(p) => Row({...p, ...props})}
            </FixedSizeList>
            {error && <FormHelperText sx={{whiteSpace:'nowrap'}} error>{error}</FormHelperText>}
        </Box>
      );
}

interface SettingOwnedObjectProp {
    owner: string;
    type_filter?:string;
    event?: SettingEvent;
    id?: string;
}

export interface SettingOwnedObjectResult {
    type: string;
    object: string;
    balance: string;
    coinType: CoinTypeInfo | 'loading';
    err?: string;
}

export function SettingOwnedObject (props: SettingOwnedObjectProp) {
    const [input, setInput] = useState('');
    const [response, setResponse] = useState<PaginatedObjectsResponse | null>(null);
    const [selected, setSelected] = useState(-1);
    const [result, setResult] = useState<SettingOwnedObjectResult>({type:'', object:'', balance:'', coinType:'loading', err:''});
    const filter = props.type_filter ? {StructType: props.type_filter} : null;

    const handleOwnerObject = (res: PaginatedObjectsResponse, nextCursor?:string|null|undefined) => {
        const f = res.data.filter((v) => (v.data?.content as any)?.hasPublicTransfer);
        if (nextCursor && nextCursor === response?.nextCursor) {
            res.data = response.data.concat([...f])
        } else {
            res.data = f
        }
        setResponse({...res});
    }

    const handleBottom = () => {
        if (response && response?.hasNextPage) {
            Protocol.Client().getOwnedObjects({owner:props.owner, filter:filter, cursor:response?.nextCursor, options:{showContent:true, showType:true}}).then((res) => {
                handleOwnerObject(res, response.nextCursor);
            }).catch((e) => {
                console.log(e);
            }) 
        }
    }
 
    useEffect( () => {
        Protocol.Client().getOwnedObjects({owner:props.owner, filter:filter, options:{showContent:true, showType:true}}).then((res) => {
            handleOwnerObject(res);
            setSelected(-1);
        }).catch((e) => {
            console.log(e);
        })       
    }, []);

    //let tips = response?.hasNextPage ? 'Too many results, input words and press enter to filter.' : '';
    //let options: SuiObjectResponse[] = response?.data ?? [];

    const itemsize = 48; 
    const totalsize = 240;

    const Row = (props:any) => {
        const {data, index, style} = props;
        var tips = '';
        for (const prop in data[index].data.content.fields) {
            if (data[index].data.content.fields.hasOwnProperty(prop) && prop !== 'id') {
                tips += prop + ' : ' + JSON.stringify(data[index].data.content.fields[prop]) + '\n';
            }
        }
        return (
            <ListItem style={{...style, }} key={index} component="div" disablePadding onClick={() => {
                setSelected(index)
                if (data && index >=0 && index < data.length) {
                    setInput((data[index].data?.content as any)?.fields?.balance ?? '')
                    result.object = data[index].data?.objectId;
                    result.type = data[index].data?.type;
                    result.balance = '';
                    result.coinType = 'loading';
                    const t = ParseType(data[index].data?.type);
                    if (t.isCoin) {
                        result.balance = (data[index].data?.content as any)?.fields?.balance ?? '';
                        result.coinType = Protocol.Instance().coinTypeInfo(t.coin, (res) => {
                            if (res.symbol === result.type) {
                                result.coinType = res;
                                setResult({...result})
                            }
                        })
                    } 
                    if (props.event) {
                        props.event('value', result, props?.id)
                    }
                    setResult({...result});
                }
            }}>
                <ListItemButton selected={selected === index} 
                    sx={{display:'flex', alignItems:'center', paddingLeft:'0', paddingRight:'.2em', height:'48px',
                        "&.Mui-selected": {
                            backgroundColor: "rgba(232,167,213,0.36)!important",
                          },
                    }} >
                    <Checkbox sx={{paddingLeft:'.4em', }} checked={selected===index}/>
                    <ListItemText sx={{color:grey[600]}} >
                        <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{tips}</div>}>
                            <span style={{letterSpacing:1, fontWeight:700, }}>{simpleAddress(data[index].data.objectId)} </span>
                        </Tooltip>
                        <Tooltip title='Click to object details.'  placement='right' arrow>
                            <LinkIcon className='cmdText' sx={{color:grey[400], height:'.6em', width:'.6em', transform:'rotateZ(-20deg)', ml:'.2em'}} onClick={() => {
                                window.open(Protocol.Instance().explorerUrl(data[index].data.objectId), '_blank')?.focus()
                            }}/>
                        </Tooltip>
                        <span style={{float:'right', color:grey[500], paddingRight:'.4em'}}>{sliceType(data[index].data.type)}</span>
                    </ListItemText>                  
                </ListItemButton>
            </ListItem>
        );
    }

    const validateInput = (value:string)  => {
        let err = '';
        try {
            var max = BigInt((response?.data[selected].data?.content as any)?.fields?.balance ?? '');
            if (max > MAX_U64) max = MAX_U64;

            const i = BigInt(value);   
            if (i > max) {
                err = 'Max overflow the balance(' + max + ') of this object.';
            } else if (i <= BigInt(0)) {
                err = 'Please input a positive number';
            }
        } catch (e) {
            err = 'Please input a positive number';
            console.log(e)
        } 

        if (err) {
            result.err = err;
            if (props.event) props.event('value', result, props?.id);
        } else {
            if (response && selected >= 0 && selected < response?.data?.length && props.event) {
                result.balance = value; result.err = '';
                setResult({...result})
                props.event('value', result, props?.id)
            }
        }
        setInput(value)
    }

    var endAdornment = '';
    if (result.coinType !== 'loading') {
       endAdornment = '≈' + ResolveBalance(result.balance, result.coinType.decimals) + ' ' + result.coinType.symbol;
    }
    
    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper'}} 
        >
            <div>
                {
                    response && selected >= 0  && sliceType(response?.data[selected].data?.type) === '0x2::coin::Coin' &&
                        <TextField id='SettingOwnedObject-number' sx={{width:'100%',  
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                            display: "none",
                          },
                            "& input[type=number]": {
                            MozAppearance: "textfield",
                          },}} value={input} type="number"  InputProps={{
                            endAdornment: <InputAdornment position="end">{<span style={{textAlign:'right'}}>{endAdornment}</span>}</InputAdornment>,
                            inputProps:{min:1, max: (response?.data[selected].data?.content as any)?.fields?.balance ?? ''}, 
                        }} onChange={(e) => { validateInput(e.target.value )}} helperText={result.err} error={result.err?true:false}
                        />
                }
                {
                    response && selected >= 0  && sliceType(response?.data[selected].data?.type) !== '0x2::coin::Coin' &&
                    <TextField sx={{width:'100%',     "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: grey[600],
                      },}} value={response?.data[selected].data?.objectId} disabled
                         InputProps={{
                            endAdornment: <InputAdornment position="end">{sliceType(response?.data[selected].data?.type)}</InputAdornment>
                        }}/>
                }
            </div>
            <FixedSizeList height={totalsize} width='100%' itemData={response?.data} 
                style={{display:'block', border:'1px solid', borderColor:grey[200], marginBottom:'1em',}} 
                itemSize={itemsize} itemCount={response?.data?.length ?? 0} overscanCount={10}    onScroll={(e)=> {
                    if (response?.data && e.scrollOffset  >= (itemsize * response?.data.length - itemsize*5) * 0.9) {
                        handleBottom();
                    }
                }}>
                {(p) => Row({...p, id:props?.id, event:props?.event})}
            </FixedSizeList>
        </Box>
      );
} 

export interface SettingTimePickerResult {
    bDuration: boolean;
    time: number;
    bLock: boolean;
}

export function SettingTimePicker (props:SettingTimePickerProp) {
    const [bDuration, setDuration] = useState(props.bDuration);
    const [value, setValue] = useState(props.duration !== undefined ? props.duration.toString() : '0');
    const [multiplier, setMultiplier] = useState('10080');
    const [timeFocus, setTimeFocus] = useState(false);
    const [bLock, setLock] = useState(false);

    const d1 = new Date().getTime() + 604800000; // 1 week
    const d2 = new Date(); d2.setTime(d1);

    const d: any = props?.time ?? d2.getFullYear() + '-' + (d2.getMonth()+1) + '-' + d2.getDate() + 'T23:59';
    const [deadline, setDeadline] = useState<Dayjs | null>(dayjs(d));

    const theme = useTheme();
    const min = 0;
    const max = 999999;

    const handleSwitch = ()  => { setDuration(!bDuration); }
    const handleMultiplierChange = (e: any) => { 
        setMultiplier(e.target.value as string); 
    }

    useEffect(() => {
        if (bDuration) {
            if (props.event) {
                const m = parseInt(multiplier);
                const v = parseInt(value);
                if (isNaN(m) || isNaN(v)) {
                    props.event('error', null);
                } else {
                    props.event('value', {bDuration:true, time:m*v*60000, bLock:bLock}, props?.id)
                }
            }
        } else {
            if (props.event) {
                if (deadline && deadline?.isValid()) {
                    props.event('value', {bDuration:false, time:deadline?.valueOf(), bLock:bLock}, props?.id)
                } else {
                    props.event('error', null);
                }
            }
        }
    }, [bDuration, multiplier, value, deadline, bLock])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.value === '') {
            setValue('');
            return
        }

        let input = parseInt(e.target.value);
        if (!isNaN(input) && input >= min && input <= max) {
            setValue(input.toString());
        }
    }

    const enable = props.perm === undefined || (props.perm?.permission === true);
    return (
        <div>
            <div style={{margin:'1em 0 .4em 0', display:'flex', alignItems:'center'}}>
                {props.required && <Typography  color={theme.palette.primary.main} display='inline'>*</Typography>}
                <Typography display='inline' sx={{fontSize:'1.1em', fontWeight:500}}>
                    {bDuration? 'Duration' : 'Deadline'}
                </Typography>
                {props?.perm && <PermIcon {...props.perm}/>}
                {props.tips && <Tooltip title={props.tips} arrow >
                    <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} /></Tooltip>}
                <Tooltip title={bDuration?'Switch to Dealine mode':'Switch to Duration mode'} arrow placement="right">
                <IconButton sx={{width:'0.9em', height:'0.9em', marginLeft:'0.6em'}} onClick={()=>handleSwitch()}><MultipleStopIcon /></IconButton>
                </Tooltip>
            </div>
            <div>
                {bDuration && (
                    <div id='setting-time' style={{border:'1px solid rgba(0, 0, 0, 0.23)', display:"inline-block", borderRadius:'0.3em', }}
                    onMouseOver={()=>{ if (!timeFocus && props.perm?.permission) document.getElementById('setting-time')!.style.borderColor='black'}}
                    onMouseLeave={()=>{ if (!timeFocus && props.perm?.permission) document.getElementById('setting-time')!.style.borderColor='rgba(0, 0, 0, 0.23)' }}
                    onFocus={()=>{setTimeFocus(true); document.getElementById('setting-time')!.style.borderColor=theme.palette.primary.main; document.getElementById('setting-time')!.style.borderWidth='2px';}}
                    onBlur={()=>{setTimeFocus(false); document.getElementById('setting-time')!.style.borderColor='rgba(0, 0, 0, 0.23)'; document.getElementById('setting-time')!.style.borderWidth='1px'}}
                    >
                        <CssTextField id='setting-time-number' type="text" disabled={!enable}
                            onChange={handleInputChange} value={value}  inputProps={{maxLength:6}}></CssTextField>
                        <div style={{width:'1px', height:'100%', borderLeft:'1px solid #ccc  ', display:'inline'}}></div>
                        <Select value={multiplier} onChange={handleMultiplierChange} disabled={!enable}
                            sx={{boxShadow: 'none', '& fieldset': {border:'none!important'}, '& input':{border:'none!important'}}}>
                            <MenuItem value={10080}>Week</MenuItem>
                            <MenuItem value={1440}>Day</MenuItem>
                            <MenuItem value={60}>Hour</MenuItem>
                            <MenuItem value={1}>Minute</MenuItem>
                        </Select>
                    </div>
                )} 
                {!bDuration && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}> 
                        <DateTimePicker disabled={!enable} ampm={false} onChange={(e:any) => setDeadline(e)} value={deadline} 
                            disablePast  
                            ></DateTimePicker>
                    </LocalizationProvider>
                )}
            </div>
            {props.locker &&  <div>
                <FormControlLabel  control={<Checkbox size="small" checked={bLock} onSelect={(e) => {setLock(!bLock)}}/>} label=" I promise to the immutability of Deadline" sx={{marginRight:0, "& .MuiCheckbox-root":{pr:'4px!important',pt:'8px!important'},}} />
                <Tooltip title='Provide participants with immutable commitments.' arrow placement='right'>
                    <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                </Tooltip>
            </div>}
        </div>
    )
}