import { useEffect, useState,  } from 'react';
import '../../css/App.css';
import { Protocol, CoinTypeInfo, ResolveBalance, parseObjectType, MAX_DESCRIPTION_LENGTH, PermissionAnswer, PermissionIndex,
    Permission, Arbitration, PermissionIndexType, Passport, Service,
    IsValidU64,
} from 'wowok'
import { Dialog, Tooltip, Box, DialogTitle, Button, DialogContent, IconButton } from '@mui/material';
import { SettingShowValue, SettingTitle, SettingInputText, SettingLaunch, SettingCoinInput} from '../launch/Settings';
import { Address } from '../util/Address';
import { grey, pink } from '@mui/material/colors';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { PermIcon } from '../util/Icons';
import { make_permIconProp } from '../launch/PermCheck';
import { TransactionBlock, } from 'wowok';
import { generateRandomString } from '../../util';
import { NotSetYet, Transition } from '../util/Common';
import CloseIcon from '@mui/icons-material/Close';
import { WithdrawInfo, SettingWithdraw } from '../launch/Withdraw';
import { useAccount, add_resource_payment } from '../util/Account';

interface Result {
    indemnity: string;
    feedback: string;
}
export default function Data (props:any) {
    //console.log(props)
    const arb_id = props?.contents?.fields?.id?.id ?? '';
    const type = parseObjectType(props?.type, 'arb::Arb<');
    const orderType = Service.parseOrderObjectType(props?.OrderType);
    const answer: PermissionAnswer | undefined = props?.perms;
    const arbitration = props?.arbitration;
    const { enqueueSnackbar } = useSnackbar();
    const wallet = useWallet();
    const account = useAccount();

    const [coinType, setCoinType] = useState<CoinTypeInfo | 'loading'>(Protocol.Instance().coinTypeInfo(type, (info) => {
        setCoinType(info);
      }))
    const [orderCoinType, setOrderCoinType] = useState<CoinTypeInfo | 'loading'>(Protocol.Instance().coinTypeInfo(orderType, (info) => {
        setOrderCoinType(info);
      }))

    
    const [result, setResult] = useState<Result|undefined>(undefined);
    const [showWithdraw, setShowWithdraw] = useState(false);

    useEffect(() => {
        setOrderCoinType(Protocol.Instance().coinTypeInfo(orderType, (info) => {
                setOrderCoinType(info);
              }));
    }, [orderType]);

    const fee = coinType !== 'loading' ? ResolveBalance(props?.contents?.fields?.fee, coinType.decimals) : undefined;
    var fee_txt = 'Free';
    if (props?.contents?.fields?.bWithdrawed) {
        fee_txt = 'Withdrawn';
    } else if (fee !== '0' && coinType !== 'loading') {
        fee_txt = fee + ' ' + coinType.symbol;
    }

    const launch = async(op:'withdraw'|'arbitration', param?:WithdrawInfo) => {
        if (!wallet.connected || !wallet.address) {
            enqueueSnackbar('Please login wallet', { variant: "error" });
            document.getElementById('header-wallet-cmd')?.click();
            return ;
        }
        
        const txb = new TransactionBlock();
        const obj = Arbitration.From(txb, type, arbitration?.content?.fields?.permission, props.contents.fields?.arbitration);
        const pid = generateRandomString(8);
        const perm:PermissionIndexType[] = [];
        if (op === 'withdraw') {
            perm.push (PermissionIndex.arbitration_withdraw);
        } else if (op === 'arbitration') {
            perm.push(PermissionIndex.arbitration_arbitration);
        }

        props.permissioncheck({id:pid, txb:txb, answer:answer, index:perm, handler:
          (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
            try {
              if (id === pid) {
                if (index.includes(PermissionIndex.arbitration_withdraw)) {
                    add_resource_payment(account, txb, [obj.withdraw_fee(txb.object(arb_id), {treasury:arbitration?.content?.fields?.fee_treasury, index:BigInt(param!.index),
                            remark:param!.remark, for_object:param?.for_object, for_guard:param?.for_guard
                        }, passport?.get_object())]);
                };  
                if (index.includes(PermissionIndex.arbitration_arbitration)) {
                    obj.arbitration({arb:txb.object(arb_id), feedback:result!.feedback, indemnity:result!.indemnity}, 
                        passport?.get_object());
                }
                passport?.destroy(); // destroy passport
                props.exe(id, txb);            
              }
            } catch (e) {
              console.log(e)
              enqueueSnackbar( 'Launch Failed', { variant: "error" });
            }
          }
        });
    }
    return ( 
    <Box sx={{maxWidth:'100%', padding:'0 1em', pb:'2em', mt:'2em'}}>
        <SettingShowValue title='Order' value={<Address address={props.contents.fields?.order} showType='Order'/>} tips='Order for arbitration.'/>
        <SettingShowValue title='Arbitration' value={<Address address={props.contents.fields?.arbitration} showType='Arbitration' />} tips='Arbitration object, through voting to give feedback on the order and compensation suggestions.'/>
        <div style={{display:'flex', alignItems:'center'}}>
            <SettingShowValue title='Arbitration Fee' value={fee_txt} tips='A fee for each arbitration, which may be withdrawn after the conclusion of the arbitration.'/>
            { fee !== '0' && <Button size='small' variant='contained' disabled={Permission.HasPermission(answer, PermissionIndex.arbitration_withdraw, true)?.has === false}
                sx={{mt:'1.4em', mb:'.4em', ml:'3em', textTransform:'none', alignItems:'center', display:'flex', }} 
                onClick={ () => {
                    if (props.contents.fields?.indemnity === null) {
                        enqueueSnackbar( 'The arbitration result has not yet been submitted', { variant: "error" });
                        return
                    }
                    setShowWithdraw(true)
                }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                <span>Withdraw Fee</span> 
                {<PermIcon {...make_permIconProp(answer, PermissionIndex.arbitration_withdraw)} />}
            </div>}</Button>
            }
        </div>
        <SettingShowValue title='Arbitration Endpoint' value={props?.arbitration?.content?.fields?.endpoint ? 
            <div className='cmdText' style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'28em'}} onClick={()=> {
                window.open(props?.arbitration?.content?.fields?.endpoint + '?' + arb_id, '_blank')?.focus()
            }}>{props?.arbitration?.content?.fields?.endpoint}</div>:<NotSetYet/>} />
        <div style={{display:'flex', alignItems:'center'}}>
            <SettingShowValue title='Indemnity' tips='When the arbitration is completed, the order payer can then claim a payout from the External Treasury of the Service (if its Withdraw Guard supports the payout decision).' 
                value={(props.contents.fields?.indemnity === null || orderCoinType === 'loading') ? <span style={{color:grey[500]}}>Pending</span>: 
                    (ResolveBalance(props.contents.fields?.indemnity, orderCoinType.decimals)+' ' +orderCoinType.symbol)} />
            <Button  variant='contained' disabled={Permission.HasPermission(answer, PermissionIndex.arbitration_arbitration, true)?.has === false}
                sx={{mt:'1.4em', mb:'.4em', ml:'3em', textTransform:'none', alignItems:'center', display:'flex',}} size='small'
                onClick={ () => {
                    setResult({indemnity:props.contents.fields?.indemnity??'', feedback:props.contents.fields?.feedback??''})
                }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                <span>Arbitration</span> 
                {<PermIcon {...make_permIconProp(answer, PermissionIndex.arbitration_arbitration)} />}
            </div>}</Button>
        </div>
        { props.contents.fields?.feedback && <>
            <SettingTitle title='Feedback' tips='Statement of arbitration opinion.' />
            <SettingInputText placeholder='Feedback not set yet.' maxlength={MAX_DESCRIPTION_LENGTH} multiline maxRows={6} readOnly
                value={props.contents.fields?.feedback} noneScroll={true} noneBorder sx={{backgroundColor:grey[100]}}
            />
        </>}
        { !props.contents.fields?.feedback &&
            <SettingShowValue title='Feedback' tips='Statement of arbitration opinion.' value={<span style={{color:grey[500]}}>None currently</span>} />
        }
        <SettingWithdraw coinType={coinType} withdrawGuards={[]}  hideAmount={true} 
            to={arbitration?.content?.fields?.fee_treasury} open={showWithdraw} event={(t,v,d) =>{
                if (t === 'close') setShowWithdraw(false)
                else if (t === 'value') {
                    launch('withdraw', v)
                }
            }} />
        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus 
            open={result !== undefined}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>setResult(undefined)}
            >
        <DialogTitle sx={{textAlign:'center'}} > Arbitration Conclusion
            <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setResult(undefined)}}> <CloseIcon /> </IconButton>   
        </DialogTitle>
            <DialogContent >
            { orderCoinType !== 'loading' && result && <> 
            <SettingTitle title='Indemnity' tips='The amount of the arbitration award; If it is empty, it means that no arbitration proposal has been made.' />
            <SettingCoinInput autoFocus placeholder={'Enter the proposed compensation amount'} decimals={orderCoinType?.decimals ?? -1} id='arb-arbitration-indemnity'
                symbol={orderCoinType?.symbol ?? ''} value={result?.indemnity} min='0' fullWidth event={(t, v, d) => {
                    result.indemnity = v;
                    setResult({...result});
                }}/> 
            </>}
            <SettingTitle title='Feedback' tips='The arbitration information feedback and comments.' />
            <SettingInputText placeholder='Enter the feedback for this Arb' maxlength={MAX_DESCRIPTION_LENGTH} multiline maxRows={6} autoFocus
            value={result?.feedback} event={(t, v, d)=> {
                result!.feedback = v;
                setResult({...result!});                
            }}/>   
                
            <SettingLaunch text='Launch' event={(t, v, _) => {
                if (t ==='click') {
                    if (result?.indemnity && !IsValidU64(result.indemnity)) {
                        enqueueSnackbar('Indemnity invalid', { variant: "error" });
                        document.getElementById('arb-arbitration-indemnity')?.click();
                        return ;
                    }
                    launch('arbitration')
                }
            }}/>
            </DialogContent>
        </Dialog>
    </Box>
    );

}