
import { Entity, Protocol, Bcs, Tags, TagName, Resource } from 'wowok';
import { useWallet } from "@suiet/wallet-kit";
import { useEffect, useRef, useState } from 'react';
import { TransactionBlock } from 'wowok';
import { type TransactionResult } from '@mysten/sui/transactions';
export interface EntityInfo {
    info?: string; // json of info
    resource?: string,
    like?: number,
    dislike?: number,
    status: 'none' |'loading' | 'done' | 'error' | 'notExist',
    err?: string,
    address?:string,
    net?: string,
    time?: number,
    lastActive?: string,
}

export interface useAccountRet {
    address: string;  // 账号地址
    account: EntityInfo;  // 账号信息
    //safer: Safer[]; // 个人加密信息
    tags: Tags[]; // 签发的标签列表
}

export function useAccount(bRefresh:boolean=false) : useAccountRet {
    const wallet = useWallet();
    const protocol = Protocol.Instance();
    const account = useRef<EntityInfo>({status:'none'});
    //const [account, setAccount] = useState<EntityInfo>({status:'none'});
    const [tags, setTags] = useState<Tags[]>([]);

    const d = new Date();
    const current = d.getTime();


    useEffect(() => {
        const QueryResource = (resourceId:string) => {
          Protocol.Client().getDynamicFields({parentId:resourceId}).then((res) => {
            if (res.data.length > 0) {
              Protocol.Client().multiGetObjects({ids:res.data.map(v => v.objectId), options:{showContent:true}}).then((fields) => {
                const t:Tags[] = fields.map((i:any) => {
                    return {address:i.data.content.fields.name, name:i.data.content.fields.value.fields.nick, tags:i.data.content.fields.value.fields.tags};
                })
                setTags([...t]);
              }).catch((e) => {
                console.log(e)
              })  
            }
          }).catch((e) =>{
            console.log(e)
          });
        }

        //@ 遗留问题： 强行变更地址，之前的数据要做地址校验，从repsonse里获取原地址和新地址比较
        const QueryEntity = () => {
            const addr = wallet.address!;
            account.current.address = addr;
            account.current.status = 'loading'; // loading
            account.current.net = protocol.package('wowok');

            const txb = new TransactionBlock();     
            Entity.From(txb).query_ent(addr);
            Protocol.Client().devInspectTransactionBlock({sender:addr, transactionBlock:txb}).then((res) => {
              if (res.results && res.results[0].returnValues && res.results[0].returnValues[0][0][0] === 0 )  {
                account.current.status = 'notExist';
                const t = new Date(); account.current.time = t.getTime();
                return 
              }

              const r1 = Bcs.getInstance().de_ent(Uint8Array.from((res.results as any)[0].returnValues[0][0]!));
              account.current.info = r1.avatar; account.current.resource = r1?.resource?.some ?? '';
              account.current.like = r1.like; account.current.dislike = r1.dislike; account.current.err = ''; 

              const t = new Date(); account.current.time = t.getTime();
              account.current.status = r1.resource?.some ? 'loading' : 'done';

              if (r1?.resource?.some) {
                  QueryResource(r1.resource?.some);
                  /*Protocol.Client().getObject({id:r1.resource?.some, options:{showContent:true, showType:true}}).then((res) => {
                      const tags:Tags[] = (res?.data?.content as any)?.fields?.tags?.map((v:any) => {
                          return {address:v.fields.object, nick:v.fields.nick, tags:v.fields.tags}
                      })
                      setTags([...tags]);
                      account.current.resource_object = res?.data;
                      account.current.status = 'done';
                      const t = new Date(); account.current.time = t.getTime();
                  }).catch((e) => {
                      console.log(e);
                      account.current.status = 'error';
                      const t = new Date(); account.current.time = t.getTime();
                      account.current.err = e;
                  });  */
                }            
            }).catch((e)=>{
                console.log(e)
                const t = new Date(); account.current.time = t.getTime();
                account.current.status = 'error';
                account.current.err = e;
            })        
        }

        if ((account.current.address !== wallet.address && wallet.address) || account.current?.net !== protocol.package('wowok')) {
          if (account.current.status !== 'loading' && wallet.address) {
            QueryEntity();
          }
        } else if (bRefresh || account.current?.time === undefined || current > (account.current.time + 30000)){
          if (account.current.status !== 'loading' && wallet.address) {
            QueryEntity();
          }
        }
    })
    
    if (account.current?.net && account.current.net === protocol.package('wowok') && account.current?.address && account.current.address === wallet.address) {
      return {address:account.current.address, account:account.current, tags:tags}
    } else {
        return {address:'', account:{status:'none'}, tags:[]};
    }
}

export  const add_resource_launched = (account:useAccountRet, txb:TransactionBlock, obj_address:TransactionResult[]) => {
  add_tag_name([TagName.Launch], account, txb, obj_address)
}

export  const add_resource_payment = (account:useAccountRet, txb:TransactionBlock, obj_address:TransactionResult[]) => {
  add_tag_name(['payment'], account, txb, obj_address)
}


const add_tag_name = (name:string[], account:useAccountRet, txb:TransactionBlock, obj_address:TransactionResult[]) => {
  if (account.account.resource) {
    const r = Resource.From(txb, account.account.resource);
    obj_address.forEach(v => r.add(v, name));
  } else {
    const r = Resource.From(txb, Entity.From(txb).create_resource2());
    obj_address.forEach(v => r.add(v, name));
    r.launch();
  }
}