import React, { useState } from 'react';
import '../../css/Launch.css';
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Button } from '@mui/material';
import 'reactflow/dist/style.css';
import { useLocation } from "react-router-dom";
import { NotSetYet, Pannel, a11yProps} from '../util/Common';
import { Bcs, } from 'wowok';
import Header, {Description} from './Header';
import Resource from '../launch/personal/Resource';
import { SettingShowValue, SettingLaunch } from '../launch/Settings';

export default function Personal(props:any) {
  const location = useLocation()
  var t = parseInt(location.hash.slice(1));
  if (t < 0 || t > 1 ||  isNaN(t))  { t = 0; }
  window.location.hash = '#' + t;

  const [value, setValue] = React.useState(t);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    window.location.hash = '#' + newValue;
    setValue(newValue);
  };

  const info = Bcs.getInstance().de_entInfo(Uint8Array.from(props?.info?.info));
  return (
      <Box sx={{ width: '100%' }}>
        <Header {...props} {...info} />
        <Description description={info?.description} />
        <Paper sx={{ width: '100%', pt:'.4em'}}>
            <Tabs value={value} onChange={handleChange} sx={{pt:'1em', pl:'1em', pr:'1em'}}>
              <Tab label="Resource" {...a11yProps(0)} sx={{textTransform:'none', pb:'.2em'}} value={0}/>
              <Tab label="Social" {...a11yProps(1)} sx={{textTransform:'none', pb:'.2em'}} value={1}/>
              {/*<Tab label="StrongBox" {...a11yProps(2)} sx={{textTransform:'none', pb:'.2em'}} value={2}/>*/}
            </Tabs>
          <Pannel value={value} index={0}>
              <Resource {...props}/>
          </Pannel>
          <Pannel value={value} index={1}>
            <Paper sx={{padding:'1em 2em'}}>
              <SettingShowValue title='Twitter' value={info?.twitter ? info.twitter : <NotSetYet/>}/>
              <SettingShowValue title='Discord' value={info?.discord ? info.discord :  <NotSetYet/> }/>
              <SettingShowValue title='Homepage' value={info?.homepage ? <div className='cmdText' onClick={()=>{
                window.open(info?.homepage, '_blank')?.focus()
              }}>{info.homepage}</div> : <NotSetYet/> }/>
              <SettingLaunch text='More...' event={(type, value, id) => {
                if (type === 'click') {
                    window.location.href = '/' + (location.pathname.length === 67 ? location.pathname.slice(1) : '') + '?setting';
                }
            }}/>
            </Paper>
          </Pannel>
          <Pannel value={value} index={2}>
          </Pannel>
          <Pannel value={value} index={3}>
            Item Three
          </Pannel>
          <Pannel value={value} index={4}>
            Item Three
          </Pannel>
      </Paper>
    </Box>
  )
}