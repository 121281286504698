
import React, { } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useLocation } from 'react-router-dom';
import Basic from './Basic';
import Resource from './Resource';
import Social from './Social';
import SettingHeader from '../SettingHeader';
import { Pannel, a11yProps } from '../../util/Common';

export default function PersonalSetting (props:any) {  
  const id = props?.contents?.fields?.id?.id ?? '';
  const location = useLocation()
    var t = parseInt(location.hash.slice(1));
    if (t < 0 || t > 2 ||  isNaN(t))  { t = 0; }
    window.location.hash = '#' + t;

    const [value, setValue] = React.useState(t);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        window.location.hash = '#' + newValue;
        setValue(newValue);
    };

    return (
      <Paper sx={{ width: '100%', pt:'.4em'}} >
      <SettingHeader name={id ? 'Personal Setting' : 'Launch Personal'}/>
              <Tabs value={value} onChange={handleChange} aria-label="reward" sx={{height:props?.info ? '3em' : '3.8em', margin:'.4em 1em'}}>
                <Tab label="Basic" {...a11yProps(0)} sx={{textTransform:'none'}}/>
                <Tab disabled={props?.info ? undefined : true}
                  icon={props?.info ? undefined : <VpnKeyIcon className='trans270' 
                  sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" 
                  label={'Resource'} {...a11yProps(1)} sx={{textTransform:'none'}}/>
                <Tab disabled={props?.info ? undefined : true}
                  icon={props?.info ? undefined : <VpnKeyIcon className='trans270' 
                  sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" label="Social" 
                  {...a11yProps(2)} sx={{textTransform:'none'}}/>
              </Tabs>
            <Pannel value={value} index={0}>
                <Basic {...props}/>
            </Pannel>
            <Pannel value={value} index={1}>
                <Resource {...props}/>
            </Pannel>
            <Pannel value={value} index={2}>
              <Social {...props}/>
            </Pannel>
        </Paper>
    );
}