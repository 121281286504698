import { useState,  } from 'react';
import '../../css/App.css';
import { Protocol, CoinTypeInfo, ResolveBalance, parseObjectType} from 'wowok'
import { Paper, Tooltip, Box, Typography } from '@mui/material';
import { SettingShowValue, SettingTitle, } from '../launch/Settings';
import { Address } from '../util/Address';
import { grey, pink } from '@mui/material/colors';

export default function Payment (props:any) {
    //console.log(props)

    const type = parseObjectType(props?.type);
    const [coinType, setCoinType] = useState<CoinTypeInfo | 'loading'>(Protocol.Instance().coinTypeInfo(type, (info) => {
        setCoinType(info);
      }))

    return ( <>
    <Balance {...props} />
    <Paper sx={{pt:'.4em', pl:'1em', pr:'1em',  mb:'2em', pb:'4em'}}>
        <SettingShowValue title='Remark' value={props.contents.fields?.remark} tips='Remark information of the payment.'/>
        <SettingShowValue title='Biz-ID' value={props.contents.fields?.index} tips='Business ID number of the payment.'/>
        {props.contents.fields?.from && <SettingShowValue title='From' tips='Object related to the source of payment.' value={<Address address={props?.contents?.fields?.from} showType/>}/>} 
        {props.contents.fields?.for_object && <SettingShowValue title='For' tips='Object related to the payment.' value={<Address address={props?.contents?.fields?.for_object} showType/>}/>} 
        {props.contents.fields?.for_guard && <SettingShowValue title='For Guard' tips='Guard related to the payment.' value={<Address address={props?.contents?.fields?.for_guard} showType/>}/>} 
        <SettingShowValue title='Signer' tips='Singer of the payment.' value={<Address address={props?.contents?.fields?.signer} showType/>}/>
        <SettingShowValue title='Time' tips='Time of the payment.' value={new Date(parseInt(props?.contents?.fields?.time ?? '')).toLocaleString()}/>
        <SettingTitle title='Payees' tips='Details of all payees and amounts received for the payment.' />
        <div style={{backgroundColor:'#e8a7d53b', marginTop:'.4em', padding:'.6em 1em', maxWidth:'28em', }}>
        {
            props.contents.fields.record.map((v:any, index:number) => {
                return (<div style={{display:'flex', alignItems:'center', marginBottom:'.6em', marginTop:'.6em'}}>
                    <span style={{marginRight:'1em', color:grey[500], fontStyle:'italic'}}>{(index+1).toString()}</span>
                    <Address address={v?.fields?.recipient} showType/>
                    {coinType !== 'loading' && <div style={{marginLeft:'1em'}}>  
                    <span style={{fontWeight:500}}>{ResolveBalance(v?.fields?.amount, coinType.decimals) + ' ' + coinType.symbol}</span>
                    </div>} 
                </div>)
            })
        }</div>
    </Paper>
    </>);
}

function Balance(props:any) {
    const type = parseObjectType(props?.type)
    const [coinType, setCoinType] = useState<CoinTypeInfo | 'loading'>(Protocol.Instance().coinTypeInfo(type, (info) => {
        setCoinType(info);
      }))

    const balance  = BigInt(props.contents.fields?.amount ?? 0);
    const tips = coinType !== 'loading' ? ResolveBalance(balance.toString(), coinType.decimals) : '';

    return (<Box margin='1em 0em'  width="100%" height="10em" bgcolor="primary.main" >
            <Box display="flex" width="100%" justifyContent="center" alignItems="center" height='10em'>
                <Tooltip title='Current balance' arrow placement='top'>
                <Typography  variant="h3" gutterBottom fontWeight="800">
                    {tips}
                </Typography>
                </Tooltip>
                <Tooltip title={type} arrow >
                    <Typography  variant="h5" gutterBottom fontWeight="200" paddingLeft="0.4em" paddingTop="0.2em">
                        {coinType !== 'loading' && coinType.symbol}
                    </Typography>
                </Tooltip>
            </Box>
        </Box>)
}