
import { useWallet, useSuiClient } from '@suiet/wallet-kit';
import { TransactionBlock } from 'wowok';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

export interface SignAndExcuteProp {
    id: string;
    txb:TransactionBlock;
    to?:string;
    force?:string;
}

export const SignAndExcute = (props:SignAndExcuteProp)  => {
    const wallet = useWallet(); 
    const client = useSuiClient();
    const [id, setId] = useState('');

    const { enqueueSnackbar } = useSnackbar();

    const launch = async () => {
        try {
            const ret:any = await wallet.signAndExecuteTransaction({transaction:props.txb}, {execute: async ({ bytes, signature }) => {
                const r =  await client.executeTransactionBlock({
                    transactionBlock: bytes,
                    signature: signature,
                    options: {
                    showObjectChanges: true,
                    showRawEffects:true,
                    },
                }); return r; }});
            //console.log(ret)
            if (ret) {
                if (props.force) {
                    window.location.href = props.force;
                } else if (props.to) {
                    const obj = ret.objectChanges.filter((v:any) => {
                        return v.objectType.includes(props.to) && v.type === 'created' && !v.objectType.includes('0x2::dynamic_field::Field');
                    })
                    //console.log(obj)
                    if (obj.length === 1) {
                        window.location.href = ('/' + obj[0].objectId);
                    }
                } else {
                    window.location.reload();   
                }
                enqueueSnackbar('Launch success', { variant: "success" });
                return 
            } else {
                enqueueSnackbar('Launch failed', { variant: "error" });
            }
        } catch (e) {
            console.log(e); //@ chrome error
        }
    };

    if (props.id && id !== props.id) {
        setId(props.id);
        launch();
    }

    return (<></>);
}