import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { Box, Chip, Tooltip, Paper, Tabs, Tab } from '@mui/material';
import { Address } from '../util/Address';
import { useLocation } from 'react-router-dom';
import { Pannel, a11yProps} from '../util/Common';
import Entity  from '../launch/permission/Entity';
import Index  from '../launch/permission/Index';


function Admin(props:any) {
    //const builder = props.contents.fields.builder;

    return (
        <Paper sx={{ width: '100%', mb: 2, p:2}}>
            <Tooltip title='Admin is responsible for specific business operations.' arrow placement='right'>
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="admin" component="div" display='inline'>
            Admin                  
            </Typography>
            </Tooltip>
            {props.contents.fields.admin.length === 0 &&
                <Box sx={{color:grey[500], fontWeight:200, pt:'1em'}}>Admin not added yet.</Box>
            }
            {props.contents.fields.admin.length > 0 && <Box sx={{width:'100%', mt:1}}>
                {props.contents.fields.admin.map((admin:string) => {
                    //if (admin === builder) return (null);
                    return (
                        <Chip color="primary"  label={<Address address={admin}/>}
                        sx={{mr:1, mb:0.4}} variant="outlined"
                        />           
                    )            
                })}
            </Box>}
        </Paper>
    )
}


export default function Permission(props:any) {
    const location = useLocation()
    var t = parseInt(location.hash.slice(1));
    if (t < 0 || t > 1 ||  isNaN(t))  { t = 0; }
    window.location.hash = '#' + t;
  
    const [value, setValue] = React.useState(t);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      window.location.hash = '#' + newValue;
      setValue(newValue);
    };

    return (
        <>
        <Admin {...props}/>
        <Paper sx={{pt:'.4em',  mb:'2em'}}>
                <Tabs value={value} onChange={handleChange} sx={{pl:'1em', pr:'1em'}}>
                  <Tab label="Entity view" {...a11yProps(0)} sx={{textTransform:'none', pb:'.2em'}}/>
                  <Tab label="Permission view" {...a11yProps(1)} sx={{textTransform:'none', pb:'.2em'}}/>
                </Tabs>
              <Pannel value={value} index={0}>
                    <Entity {...props} />
              </Pannel>
              <Pannel value={value} index={1}>
                  <Index {...props} />
              </Pannel>
          </Paper>
        </>    
    )
}


