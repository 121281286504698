import React, { useState, useEffect } from 'react';
import '../../css/Header.css';
import '@suiet/wallet-kit/style.css';
import { SEARCH_HISTORY_KEY } from '../../const';
import { Button, IconButton, Paper, TextField, Tooltip, Avatar} from '@mui/material';
import { useTheme, } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { grey } from '@mui/material/colors';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { load_object_type_string, sliceAddress } from '../../util';
import { ConnectModal } from '@suiet/wallet-kit';
import HomeIcon from '@mui/icons-material/Home';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import LogoutIcon from '@mui/icons-material/Logout';
import ListIcon from '@mui/icons-material/List';
import { useLocation } from 'react-router-dom'
import { useWallet } from '@suiet/wallet-kit';
import Autocomplete, {
  AutocompleteCloseReason,
} from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import { Protocol } from 'wowok';
import { Icons } from '../util/Icons';
import { useAccount } from '../util/Account';

const useStyle = makeStyles(() => ({
  popperRoot: {
    [`& .MuiAutocomplete-noOptions`]: {
      display: 'none'
    }
  }
}))

const CustomerPopper = (props:any) => {
  const classes = useStyle();
  const modifiers = [
    {
      name: 'flip',
      options: {
        fallbackPlacements: []
      },
    },
  ]
  return (
    <Popper sx={{zIndex:'99999'}}
      {...props}
      modifiers={modifiers}
      className={classes.popperRoot}
      popperOptions={{
        placement: 'bottom-start',
      }}
    />
  )
}

export default function Head(props:any) {
  document.getElementById('root')!.style.transform = 'none';
  document.getElementById('root')!.style.paddingLeft = 'calc(100vw - 100%)'; // 右侧出现滚动条不跳动
  
  const theme = useTheme();
  const wallet = useWallet();
  const path = useLocation();

/*  let address = '';
  if (path.pathname.length === 67) {
    const i = path.pathname.slice(1);
    if (isValidSuiAddress(i)) {
      address = i;
    }
  } */

    const [input, setInput] = useState('');
    const [walletState, setWalletState] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [walletLight, setWalletLight] = useState(false);
    const [leaveWallet, setLeaveWallet] = useState(true);
    const [active, setActive] = useState(false);

    const exploreClass = path.pathname.toLowerCase() === '/explore' ? 'focus-button' : 'unfocus-button';
    const launchClass = path.pathname.toLowerCase() === '/launch' ? 'focus-button' : 'unfocus-button';
    const account = useAccount();
    useEffect(() => {
      if (account.account.info && !active) {
        setActive(true)
      }
    }, [account])


    const handleChange = (event:any, option:any, reason:any, bSearch:boolean=false): void => {
      setInput(option);
      if (bSearch) {
        window.location.href = '/' + option;
      }
    };

    const handleWallet = (param: 'switch' | 'logout' | 'profile' | 'home' | 'notification') => {
        switch(param) {
            case 'switch':
                setWalletState(true);
                break;
            case 'logout':
                wallet.disconnect();
                break;
            case 'profile':
              if (active) {
                window.location.href = '/' + wallet.address + '?setting';
              } else {
                window.location.href = '/personal';
              }
                break;
            case 'home':
              if (active) {
                window.location.href = '/' + wallet.address;
              } else {
                window.location.href = '/personal';
              }
                break;
        }
    }

    const handleWalletClick = () => {
        if (wallet?.address) {
            handlePerson('switch');
        } else {
            setWalletState(true);
        }
    }

    const handlePerson = (param:'enter' | 'leave' | 'switch') => {
        if (wallet?.address) {
            if (param === 'enter') { 
                if (leaveWallet) {
                    setAnchorEl(document.getElementById('hidden-right')!); 
                    setLeaveWallet(false)
                }
            } else if (param === 'leave') {
                setLeaveWallet(true)
                setAnchorEl(null);
            } else if (param === 'switch')  {
                if (anchorEl) {
                    setAnchorEl(null);
                    setLeaveWallet(false);
                } else {
                    setAnchorEl(document.getElementById('hidden-right')!);
                }
            }            
        } else {
            if (param === 'enter') {
                setWalletLight(true);
            } else if (param === 'leave') {
                setWalletLight(false);
            }
        }
    }
  
    const handleInputClick = (param: 'search' | 'clear'): void => {
      if (param === 'search' && input) {
        opHistory('add', input);
        window.location.href = '/' + input;
      } else if (param === 'clear') {
        setInput('')
        document.getElementById('head-search-box')?.focus();
      }
    };
    
    const [history, setHistory] = React.useState<string[]>([]);
    const [historyOpen, SetHistoryOpen] = React.useState(false);
  
    const opHistory = (op:'add' | 'remove' | 'clear' | 'read', option?:string) => {
      let local = localStorage.getItem(SEARCH_HISTORY_KEY);
      if (op === 'add' && option) {
        if (local) {
          const n = (JSON.parse(local) as string[]).filter(v => v!== option);
          n.unshift(option); 
          if (n.length > 10) {
            n.splice(10)
          }

          setHistory(n);
          localStorage.setItem(SEARCH_HISTORY_KEY, JSON.stringify(n));
        } else {
          setHistory([option]);
          localStorage.setItem(SEARCH_HISTORY_KEY, JSON.stringify([option]));
        }
      } else if (op === 'remove' && option) {
        if (local) {
          const n = (JSON.parse(local) as string[]).filter(v => v!== option);
          setHistory(n);
          localStorage.setItem(SEARCH_HISTORY_KEY, JSON.stringify(n));
        }
      } else if (op === 'clear') {
        localStorage.setItem(SEARCH_HISTORY_KEY, JSON.stringify([]));
        setHistory([]);
      } else if (op === 'read') {
        if (local) {
          setHistory(JSON.parse(local) as string[]);
        } else {
          setHistory([]);
        }
      }
    }

    useEffect(() => {opHistory('read')}, []);

      return (
        <Paper style={{marginBottom:'1em', marginTop:'0.4em'}} >
          <div className='app-header'>
            <div className='logo' onClick={()=>window.location.href = ('/')}></div>
            <Button size='large' className={exploreClass} onClick={()=>window.location.href = ('/explore')}>Explore</Button>
            <Button size='large' className={launchClass} onClick={()=>window.location.href = ('/launch')}>Launch</Button>
            <Autocomplete
              freeSolo={false}
              forcePopupIcon={false}
              open={historyOpen}
              onOpen={() => SetHistoryOpen(true)}
              onInputChange={handleChange}
              onClose={(
                event: React.ChangeEvent<{}>,
                reason: AutocompleteCloseReason,
              ) => {
                if (reason === 'removeOption') return;
                SetHistoryOpen(false)
              }}
              value={input}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  ((event as React.KeyboardEvent).key === 'Backspace' ||
                    (event as React.KeyboardEvent).key === 'Delete') &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                if (newValue) {
                  handleChange(event, newValue, reason, true);
                }
              }}
              PopperComponent={(props) => <CustomerPopper {...props} />}
              renderTags={() => null}
              noOptionsText="No history filted" 
              renderOption={(props, option, { selected }) => {
                return (
                  <OptionItem option={option} input={input} onRemove={(event:any, option:string)=> {
                    opHistory('remove', option); event.stopPropagation();}} {...props}/>
              )}}
              options={[...history].sort((a, b) => {
                // Display the selected labels first.
                let ai = input.indexOf(a);
                ai = ai === -1 ? input.length + history.indexOf(a) : ai;
                let bi = input.indexOf(b);
                bi = bi === -1 ? input.length + history.indexOf(b) : bi;
                return ai - bi;
              })}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{marginLeft:'1em'}} id='head-search-box'
                  size="small"
                  variant="outlined"
                  value={input}
                  onFocus={() => setInput(input)}
                  placeholder='Input address or objectid, and press Enter'
                  sx={{"& fieldset": { padding:'8px', borderRadius:'2em', borderColor:'rgba(232,167,213,0.36)!important'}, 
                    "&:hover fieldset": { borderColor:theme.palette.primary.main + '!important'},
                    input: {color: grey[600]}}}
                  onKeyDown={(event) => {if(event.code === 'Enter') {
                    handleInputClick('search')} 
                  }}
                  inputProps={{
                    ...params.inputProps,
                    maxLength: 66,
                    style: { width: '37em', paddingLeft:'1em', paddingRight:'0.1em', paddingTop:'0.3em', paddingBottom:'0.2em'},
                    endadornment: (
                      <> <InputAdornment
                        position="end"
                        style={{ display: input.length > 0 ? 'flex' : 'none' }}
                        onClick={() => handleInputClick('clear')}>
                        <ClearIcon />
                      </InputAdornment>
                      <InputAdornment position="start" 
                        style={{ display: input.length === 0 ? 'flex' : 'none'}}>
                      <SearchIcon />
                      </InputAdornment></>
                    )
                  }}
                />
              )}
            />
            <div id='header-wallet-cmd' className='wallet'  /* onMouseEnter={()=>handlePerson('enter')} 
                onMouseLeave={()=>handlePerson('leave')}*/
                onClick={()=>handleWalletClick()}>
                <IconButton sx={{width:'100%!important', height:'100%!important', padding:0}}>
                  <AccountCircleIcon sx={{color:(walletLight || wallet?.address) ?'#E8A7D5':grey[700], 
                    padding:0, width:'100%!important', height:'100%!important'}}/>
                </IconButton>
                { wallet?.address && <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={anchorEl ? true : false}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                            <MenuItem onClick={()=>handleWallet('home')} >
                                <ListItemIcon><HomeIcon sx={{}}/></ListItemIcon>
                            {sliceAddress(wallet?.address) + ' ' + wallet.chain!.name.slice(4)}
                            </MenuItem>
                            {/*<MenuItem onClick={()=>handleWallet('notification')}>
                            <ListItemIcon><NotificationsNoneIcon /></ListItemIcon>
                            Notification
                            </MenuItem> */}
                            <MenuItem onClick={()=>handleWallet('profile')}>
                            <ListItemIcon><ListIcon /></ListItemIcon>
                            My Profile
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={()=>handleWallet('switch')}>
                            <ListItemIcon><SwitchAccountIcon /></ListItemIcon>
                            Switch Account
                            </MenuItem>
                            <MenuItem onClick={()=> {handleWallet('logout'); window.location.reload();}}>
                            <ListItemIcon><LogoutIcon /></ListItemIcon>
                            Logout
                            </MenuItem>
                    </Menu>
                }
            </div>
            <div id='hidden-right'></div>
            <ConnectModal
                    open={walletState}
                    onOpenChange={(open) => {
                      setWalletState(open); 
                    }}
                    onConnectSuccess={() => {
                      setWalletState(false);
                      window.location.reload();
                    }}
                    onConnectError={(err) => {
                      console.log(err)
                    }}
                    >
            </ConnectModal>
          </div>
        </Paper>
      );  
  }

  const OptionItem = (props:any) => {
    const arr:string[] = props.option.split(props.input);
    const theme = useTheme();
    const [type, setType] = React.useState(Protocol.Instance().object_name_from_type_repr(
      load_object_type_string(props.option, (id:string, t:string) => {
        if (id===props.option) setType(Protocol.Instance().object_name_from_type_repr(t));
    })));

    return (
    <li {...props}>
      <div style={{display:'flex', alignItems:'center', width:'100%', color:grey[600]}}>
      { type  && <Avatar style={{ width:'1em', height:'1em' , marginRight:2, marginLeft:-2, backgroundColor:'transparent'}}>
                        <Icons type={type} defaultIcon={true}/>
                    </Avatar>}
        <div>
            {arr.map((v, i) => {
                if (i < arr.length-1) {
                    return <span>{v}<span style={{fontWeight:400, color:theme.palette.primary.main}}>{props.input}</span></span>
                } else {
                    return <span>{v}</span>
                }
            })}
        </div>
        <Tooltip title='Remove it' placement='right' arrow>
            <IconButton  className='cmdText' sx={{ml:'auto', padding:'6px', mr:'-.6em'}} onClick={(event) => props.onRemove(event, props.option)
            }><CloseIcon className='cmdText' sx={{color:grey[400], width:'.8em', height:'.8em'}}/></IconButton>
        </Tooltip>
    </div>
    </li>
  )
}

