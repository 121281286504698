import { useState,  } from 'react';
import '../../css/App.css';
import { PermissionAnswer, parseObjectType, Permission, PermissionIndex, Arbitration, Passport} from 'wowok'
import { LinearProgress, Box, Button, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemButton, Checkbox,
    IconButton, ListItemText, } from '@mui/material';
import { SettingLaunch, SettingTips } from '../launch/Settings';
import { Address } from '../util/Address';
import { grey, pink } from '@mui/material/colors';
import { useTheme, } from '@mui/material/styles';
import { green } from '@material-ui/core/colors';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { PermIcon } from '../util/Icons';
import { make_permIconProp } from '../launch/PermCheck';
import { TransactionBlock } from 'wowok';
import { generateRandomString } from '../../util';
import { Transition } from '../util/Common';
import CloseIcon from '@mui/icons-material/Close';


export interface Result {
    index: number;
    option: string;
    votes: number;
}

export interface Guard {
    guard: string;
    weight: string;
}

export default function Voting (props:any) {
    // console.log(props)
    const arb_id = props?.contents?.fields?.id?.id ?? '';
    const type = parseObjectType(props?.type, 'arb::Arb<');
    const answer: PermissionAnswer | undefined = props?.perms;
    const arbitration = props?.arbitration;

    const { enqueueSnackbar } = useSnackbar();
    const wallet = useWallet();

    const data: Result[] = props?.contents?.fields?.proposition?.fields?.contents?.map((v:any, index:number) => {
        return {option:v.fields.key, index:index, votes: parseInt(v.fields.value)} ;
    });
    data.sort((a, b)=>b.votes-a.votes);

    const [select, setSelect] = useState<number[]>([]);
    const [guards, setGuards] = useState<Guard[]>([]);
    const [current, setCurrent] = useState<Guard | undefined>(undefined);

    const launch = async(voting_guard?:string) => {
        if (!wallet.connected || !wallet.address) {
            enqueueSnackbar('Please login wallet', { variant: "error" });
            document.getElementById('header-wallet-cmd')?.click();
            return ;
        }

        const txb = new TransactionBlock();
        const obj = Arbitration.From(txb, type, arbitration?.content?.fields?.permission, props.contents.fields?.arbitration);
        const pid = generateRandomString(8);
        var g: string[] = [];
        if (voting_guard) g.push(voting_guard);
        const votePerm = Permission.HasPermission(answer, PermissionIndex.arbitration_vote);
        if (votePerm?.guard) g.push(votePerm.guard);

        props.guardcheck({id:pid, txb:txb, guards:g, handler:
            (id:string, txb:TransactionBlock, passport?:Passport) => {
            try {
                if (id === pid) {
                    obj.vote({arb:arb_id, voting_guard:voting_guard, agrees:select}, passport?.get_object());
                    passport?.destroy(); // destroy passport
                    props.exe(pid, txb);
                }
            } catch (e) {
                console.log(e)
                enqueueSnackbar( 'Launch Failed', { variant: "error" });
            }}
        });    
    }

    if (props?.contents?.fields?.proposition?.fields?.contents?.length === 0) {
        return (<Box sx={{maxWidth:'100%', padding:'0 1em', pb:'2em', mt:'2em'}}><SettingTips text={'The Arb does not require a vote.'} /></Box> )
    } else {
        return (
            <Box sx={{maxWidth:'100%', padding:'0 1em', pb:'2em', mt:'2em'}}>
               {
                   data.map((v) => {
                       return <Option option={v} data={data} select={select} onSelect={()=> {
                           if (!select.includes(v.index)) {
                               setSelect([...select, v.index]);
                           } else {
                               setSelect([...select.filter(i => i!==v.index)]);
                           }
                       }}/>
                   })
               }
               <div>
               <Button  variant='contained' disabled={Permission.HasPermission(answer, PermissionIndex.arbitration_vote, true)?.has === false}
                       sx={{ mb:'.4em', textTransform:'none', alignItems:'center', display:'flex', fontSize:'1.2em'}} 
                       onClick={ () => {
                           if (props?.arbitration?.content?.fields?.voting_guard?.fields?.contents?.length === 0) {
                               launch();
                           } else if (props?.arbitration?.content?.fields?.voting_guard?.fields?.contents?.length === 1) {
                               launch(props?.arbitration?.content?.fields?.voting_guard?.fields?.contents[0]?.fields?.key);
                           } else {
                               setGuards(props?.arbitration?.content?.fields?.voting_guard?.fields?.contents?.map((v:any) => {
                                   return {guard:v?.fields?.key, weight:v?.fields?.value}
                               }))                        
                           }
                       }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                       <span>Vote</span> 
                       {<PermIcon {...make_permIconProp(answer, PermissionIndex.arbitration_vote, undefined, guards.map((v)=>v.guard))} />}
                   </div>}</Button>
               </div>
               <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'sm'} disableRestoreFocus 
                   open={guards.length > 0}
                   TransitionComponent={Transition}
                   keepMounted
                   onClose={()=>setGuards([])}
                   >
               <DialogTitle sx={{textAlign:'center'}} > Guard and Weight for Voting
                   <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setGuards([])}}> <CloseIcon /> </IconButton>   
               </DialogTitle>
                   <DialogContent >
                   <List sx={{border:'1px solid', borderColor:grey[200], mt:'.4em', p:0}}>
                       { guards.map((v, index) => {
                          return ( <ListItem key={v.guard} component="div" disablePadding 
                           sx={{display:'flex', alignItems:'center', backgroundColor:v.guard === current?.guard ? pink[50] : undefined}}  
                           secondaryAction={
                           <Checkbox
                             edge="end"
                             onChange={(e) => {
                               if (v.guard === current?.guard) {
                                   setCurrent(undefined);
                               } else {
                                   setCurrent(v);
                               }
                             }}
                             checked={v.guard === current?.guard}
                             inputProps={{ 'aria-labelledby': 'guard-list-checkbox' }}
                           />
                         }>
                               <ListItemButton sx={{pl:'.7em', pr:'.7em', color:grey[600], flex:'auto', pt:0, pb:0}}>
                                   <ListItemText sx={{width:'6em', ml:0}}>
                                       <div style={{display:'flex', alignItems:'center'}}>
                                           <span style={{fontStyle:'italic', width:'1.6em', color:grey[400], marginRight:'.1em', fontSize:'.9em', paddingTop:'.1em'}}>{index+1+'.'}</span>
                                           <Address address={v.guard} showType='Guard'/>
                                       </div>
                                   </ListItemText>
                                   <ListItemText >{v.weight}</ListItemText>
                               </ListItemButton>   
                           </ListItem>)                      
                           })
                       }
                   </List>  
                   <SettingLaunch text='Verify & Vote' event={(t, v, _) => {
                       if (t ==='click') {
                           if (!current) {
                               enqueueSnackbar( 'Select Guard to verify', { variant: "error" });
                               return;
                           }
       
                           launch(current.guard);
                       }
                   }}/>
                   </DialogContent>
               </Dialog>
           </Box>
        );
    }
}

function Option (props:any) {
    const option:Result = props.option;
    const data:Result[] = props.data;
    const select: Number[] = props.select;
    const theme = useTheme();

    const max = data.length > 0 && data[0].votes ? data[0].votes : 100;
    const bSelect = select.includes(option.index);
    const btnBg = bSelect ? {}:{backgroundColor:green[500]};
     
    return (
        <div style={{display:'flex', alignContent:'center'}}>
            <div style={{marginBottom:'2em', width:'90%', maxWidth:'84%'}}>
                <div style={{display:'flex', alignItems:'baseline', }}>
                    <div style={{fontSize:'1.2em', fontWeight:600, whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',maxWidth:700}}>
                        {option.option}
                    </div>
                    <div style={{marginLeft:'auto', marginRight:'.2em', color:theme.palette.primary.main}}> 
                        <span style={{fontSize:'1.2em', fontWeight:400, fontStyle:'italic'}}>{option.votes}</span>
                        <span style={{marginLeft:'.4em', color:grey[500]}}>votes</span>
                    </div>
                </div>
                <div>
                    <LinearProgress variant='determinate' value={option.votes/max*100} sx={{height:'1em'}}/>
                </div>
            </div>
            <div style={{flexGrow:'1', marginTop:'.5em', marginLeft:'1.4em', display:'flex', height:'40%'}}>
                <Button variant='contained' sx={{width:'8em', marginLeft:'auto', ...btnBg}} 
                    onClick={ props.onSelect }>{bSelect?'Selected':'Select'}</Button>
        </div>                
    </div>)

}