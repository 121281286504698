
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box, Select, MenuItem,  } from '@mui/material';
import { PermissionIndex, PermissionIndexType, Repository, PermissionAnswer, Passport as WowokPassport, Permission} from 'wowok';
import { SettingTitle,  SettingLaunch, ADDRESS_VALIDATOR, SettingAddressChips, 
} from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { TransactionBlock, } from 'wowok';
import { make_permIconProp } from '../PermCheck';
import { generateRandomString } from '../../../util';

export default function Reference (props:any) {
    const id = props?.contents?.fields?.id?.id ?? '';
    const permission  = props?.contents?.fields?.permission;
    const answer: PermissionAnswer | undefined = props?.perms;
    const [data, setData] = useState<string[]>(props?.contents?.fields?.reference??[]);

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();

    const launch = async (add:string[], remove:string[]) => {      
      const txb = new TransactionBlock()
      const obj = Repository.From(txb, permission, id);
      const pid = generateRandomString(8);
      props.permissioncheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.repository_reference], handler:
        (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:WowokPassport) => {
          try {
            if (id === pid) {
              if (index.includes(PermissionIndex.repository_reference)) {
                obj.remove_reference(remove, undefined, passport?.get_object());
                obj.add_reference(add, passport?.get_object());
              };  
              passport?.destroy(); // destroy passport
              props.exe(id, txb);            
            }
          } catch (e) {
            console.log(e)
            enqueueSnackbar( 'Launch Failed', { variant: "error" });
          }
        }
      });
    }
    
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
          <SettingTitle title='Reference' tips='Confirm that Objects that reference this repository'
            perm={make_permIconProp(answer, PermissionIndex.repository_reference)}/>
          <SettingAddressChips placeholder='Input object address and press Enter'  
            id='reference' maxTags={Repository.MAX_REFERENCE_COUNT} maxTags_err='Max reference count' 
                noPerm={Permission.HasPermission(answer, PermissionIndex.repository_reference)?.has === false}
                initValue={data} event={(type, value, id) => {
                const d = type==='value' ? value : [];
                setData([...d])
            }}/>
   
             <SettingLaunch text='Launch' event={(t, v, _) => {
              if (t === 'click') {
                if (!wallet.connected) {
                  enqueueSnackbar('Please login wallet', { variant: "error" });
                  document.getElementById('header-wallet-cmd')?.click();
                  return 
                }
                
                const add = data.filter((v) =>props?.contents?.fields?.reference?.find((i:string)=>i === v) === undefined);
                const remove = props?.contents?.fields?.reference?.filter((v:string) =>data.find((i:string)=>i === v) === undefined);
          
                if (add.length === 0 && remove.length === 0) {
                  enqueueSnackbar('No reference changed to launch', { variant: "success" });
                  return 
                }

                launch(add, remove);
              }
          }}/>
        </Box>
    );  
  }