import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import  App  from './compoments/app/App';
import  Home  from './compoments/app/Home';
import reportWebVitals from './reportWebVitals';
import { Routes, Route } from 'react-router-dom'
import { BrowserRouter } from "react-router-dom";
import { WalletProvider } from '@suiet/wallet-kit';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "@mui/material/styles/createPalette";
import {ReactFlowProvider} from 'reactflow';
import Launch from './compoments/launch/Launch';
import Explore from './compoments/explore/Explore';
import Head from './compoments/app/Header';
import RepositorySetting from './compoments/launch/repository/Repository';
import DemandSetting from './compoments/launch/demand/Demand';
import MachineSetting from './compoments/launch/machine/Machine';
import PermissionSetting from './compoments/launch/permission/Permission';
import ServiceSetting from './compoments/launch/service/Service';
import TreasurySetting from './compoments/launch/treasury/Treasury';
import GuardSetting from './compoments/launch/guard/Guard';
import { ENTRYPOINT, Protocol } from 'wowok';
import { SnackbarProvider} from 'notistack';
import PersonalSetting from './compoments/launch/personal/Personal';
import { useAccount } from './compoments/util/Account';
import { SignAndExcute, SignAndExcuteProp } from './compoments/util/SignAndExcute';
import { TransactionBlock } from 'wowok';
import { Passport, PassportProp } from './compoments/util/Passport';
import ArbitrationSetting from './compoments/launch/arbitration/Arbitration';
import { PermissionAnswer, PermissionIndexType, Permission as WowokPermission, Passport as WowokPassport } from 'wowok';

Protocol.Instance().use_network(ENTRYPOINT.testnet);
/*
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const client = new ApolloClient({
  uri: 'https://sui-testnet.mystenlabs.com/graphql',
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});
*/
const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#E8A7D5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    info: {
      light: '#757ce8',
      main: 'rgba(232,167,213,0.66)',
      dark: '#002884',
      contrastText: '#fff',
    }
  },
  
});

export type handlePermissionSuccess = (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:WowokPassport) => void;
export type handleGuardSuccess = (id:string, txb:TransactionBlock, passport?:WowokPassport) => void;
export interface PermissionCheckParam {
  id:string;
  txb:TransactionBlock;
  answer:PermissionAnswer;
  index:PermissionIndexType[];
  guards?: string[];
  handler:handlePermissionSuccess;
}

export interface GuardCheckParam {
  id:string;
  txb:TransactionBlock;
  guards:string[];
  handler:handleGuardSuccess;
}

function Entry() {
  // const element = useRoutes(ROUTES);
  useAccount();
  const [exe, setExe] = React.useState<SignAndExcuteProp | undefined>(undefined);
  const [pst, setPst] = React.useState<PassportProp | undefined>(undefined);

  const onExe = React.useCallback((id:string, txb:TransactionBlock, to?:string, force?:string) => {
    setExe({id:id, txb:txb, to:to, force:force});
  },[]);

  const guardcheck = React.useCallback((param:GuardCheckParam) => {
    if (param.guards.length === 0) {
      param.handler(param.id, param.txb);
    } else {
      setPst({id:param.id, guards:param.guards, txb:param.txb,
        onPassportResult:(bSuccess:boolean, data?:any, passport?:WowokPassport) => {
            param.handler(param.id, param.txb, passport);
      }})      
    }  
  }, []);

  const permissioncheck = React.useCallback((param:PermissionCheckParam) => {
    const guards: string[] = param?.guards ?? [];
    param.index.forEach((v) => {
        const i = WowokPermission.HasPermission(param.answer, v);
        if (i && i.guard) {
            guards.push(i.guard);
        }
    })

    const perm: PermissionIndexType[] = [];
    param.index.forEach((v) => {
      const i = WowokPermission.HasPermission(param.answer, v);
      if (i && i.has) { perm.push(v)}
    });

    if (guards.length === 0) {
      param.handler(param.id, param.txb, perm);
    } else {
      setPst({id:param.id, guards:guards, txb:param.txb,
        onPassportResult:(bSuccess:boolean, data?:any, passport?:WowokPassport) => {
          if (bSuccess) {
            param.handler(param.id, param.txb, perm, passport);
          }
      }})      
    }
  }, []);

  const comp:any = <>{pst && <Passport {...pst}/>}{exe && <SignAndExcute {...exe}/>}</>
  return (<Routes>
    <Route path = '/launch' element = {<><Head /><Launch /></>}/>
    <Route path = '/launch/*' element = {<><Head /><Launch /></>}/>
    <Route path = '/arbitration' element = {<><Head />{comp}<ArbitrationSetting exe={onExe}  guardcheck={guardcheck}/></>}/>
    <Route path = '/arbitration/*' element = {<><Head />{comp}<ArbitrationSetting exe={onExe} guardcheck={guardcheck}/></>}/>
    <Route path = '/demand' element = {<><Head />{comp}<DemandSetting exe={onExe} guardcheck={guardcheck}/></>}/>
    <Route path = '/demand/*' element = {<><Head />{comp}<DemandSetting exe={onExe} guardcheck={guardcheck}/></>}/>
    <Route path = '/service'  element = {<><Head />{comp}<ServiceSetting exe={onExe} guardcheck={guardcheck}/></>}/>
    <Route path = '/service/*'  element = {<><Head />{comp}<ServiceSetting exe={onExe} guardcheck={guardcheck}/></>}/> 
    <Route path = '/machine' element = {<><Head />{comp}<MachineSetting exe={onExe} guardcheck={guardcheck}/></>}/> 
    <Route path = '/machine/*'  element = {<><Head />{comp}<MachineSetting exe={onExe} guardcheck={guardcheck}/></>}/> 
    <Route path = '/guard'  element = {<><Head />{comp}<GuardSetting exe={onExe} /></>}/> 
    <Route path = '/guard/*'  element = {<><Head />{comp}<GuardSetting  exe={onExe} /></>}/>
    <Route path = '/repository'  element = {<><Head />{comp}<RepositorySetting  exe={onExe}  guardcheck={guardcheck}/></> }/>
    <Route path = '/repository/*'  element = {<><Head />{comp}<RepositorySetting  exe={onExe}  guardcheck={guardcheck}/></>}/>
    <Route path = '/permission' element = {<><Head />{comp}<PermissionSetting  exe={onExe} /></> }/>
    <Route path = '/permission/*'  element = {<><Head />{comp}<PermissionSetting  exe={onExe} /></> }/>
    <Route path = '/treasury' element = {<><Head />{comp}<TreasurySetting  exe={onExe} /></> }/>
    <Route path = '/treasury/*'  element = {<><Head />{comp}<TreasurySetting  exe={onExe} /></> }/>
    <Route path = '/personal'  element = {<><Head />{comp}<PersonalSetting   exe={onExe}/></> }/>
    <Route path = '/personal/*'  element = {<><Head />{comp}<PersonalSetting  exe={onExe} /></> }/>
    <Route path = '/explore'  element = {<><Head /><Explore/></> }/>
    <Route path = '/explore/*'  element = {<><Head /><Explore/></>}/>
    <Route path = '/'  element = {<Home />}/>
    <Route path = '/*'   element = {<><Head/>{comp}<App exe={onExe} permissioncheck={permissioncheck} guardcheck={guardcheck}/></> }/>  
    <Route path = '*'  element = {<Home />} />
  </Routes>)
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter> 
        <WalletProvider>
          <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={3} autoHideDuration={4000}>
                <ReactFlowProvider>
                  <Entry />  
                </ReactFlowProvider> 
              </SnackbarProvider>
          </ThemeProvider>   
        </WalletProvider> 
    </BrowserRouter>  
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
