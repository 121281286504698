import React, { useState, useEffect } from 'react';
import '../../css/App.css';
import { Service, PermissionAnswer, Protocol, CoinTypeInfo, ResolveBalance, PermissionIndex,
    Passport, Customer_RequiredInfo, Permission, Arbitration, } from 'wowok'
import { IconButton, Tooltip, Box, Button, Dialog, DialogTitle, DialogContent, List, Checkbox, ListItemButton,
    ListItem, ListItemText,} from '@mui/material';
import { SettingShowValue, SettingTitle, SettingAddressChips, SettingTips } from '../launch/Settings';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MaterialTable from "material-table";
import { Address } from '../util/Address';
import { grey, pink } from '@mui/material/colors';
import { PermGuardIcon, PermIcon } from '../util/Icons';
import { NotSetYet, Transition } from '../util/Common';
import CloseIcon from '@mui/icons-material/Close';
import { SettingLaunch, SettingInputText } from '../launch/Settings';
import { generateRandomString } from '../../util';
import { useSnackbar } from 'notistack';
import { TransactionBlock, } from 'wowok';
import { useWallet } from '@suiet/wallet-kit';
import JSEncrypt from 'jsencrypt';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, } from '@mui/material/styles';
import { make_permIconProp } from '../launch/PermCheck';
import { SettingWithdraw, WithdrawInfo } from '../launch/Withdraw';
import { useAccount, add_resource_payment } from '../util/Account';

interface Summary {
    service: string;
    payer: string;
    payed: string;
    balance: string;
    discount?: string | null;
    progress?: string | null ;
}

interface Item {
    index: number;
    name: string;
    price: string;
    inventory: string;
    total: string;
    endpoint?: string | null;
}

interface GuardInfo {
    guard: string;
    percent: string;
}

interface Guards {
    guards:GuardInfo[];
    op:'refund' | 'withdraw';
}

interface ArbInfo {
    object: string;
    type: string;
    indemnity: string;
}

interface ArbData {
    data: ArbInfo[];
    current: string;
    show: boolean;
}

interface ReqInfo {
    title: string;
    info: string;
}
export default function Data (props:any) {
    //console.log(props)
    const wallet = useWallet();
    const { enqueueSnackbar } = useSnackbar();
    const type = Service.parseOrderObjectType(props?.type);
    const permission = props?.service?.content?.fields?.permission;
    const order_id = props?.contents?.fields?.id?.id ?? '';
    const service_id = props?.service?.content?.fields?.id?.id;
    const answer: PermissionAnswer | undefined = props?.perms;
    const theme = useTheme();
    const account = useAccount();
    var customer_pub = props?.contents?.fields?.required_info?.fields?.customer_pub ?? '';

    const [coinType, setCoinType] = useState<CoinTypeInfo | 'loading'>(Protocol.Instance().coinTypeInfo(type, (info) => {
        setCoinType(info);
      }))

    const [guards, setGuards] = useState<Guards>({guards:[], op:'refund'});
    const [select, setSelect] = useState<GuardInfo | undefined>(undefined);
    const [reqInfo, setReqInfo] = useState<ReqInfo[]>([]);
    const [agent, setAgent] = useState<string[]>(props?.contents?.fields?.agent);
    const [refundArb, setRefundArb] = useState<ArbData>({data:[], current:'', show:false});
    const [privKey, setPrivKey] = useState<string | undefined>(undefined);
    const [decryptedInfo, setDecryptInfo] = useState('');

    useEffect(() => {
        if (props?.contents?.fields?.dispute?.length > 0) {
            Protocol.Client().multiGetObjects({ids:props?.contents?.fields?.dispute?.map((v:string)=>v), options:{showContent:true}}).then((res) => {
                refundArb.data = res.map(v => {
                    return {object:v.data?.objectId ?? '', type:Arbitration.parseArbObjectType((v.data?.content as any)?.type), indemnity:(v.data?.content as any)?.fields?.indemnity ?? ''}                 
                });
                setRefundArb({...refundArb});
            }).catch(e => {
                console.log(e)
            })
        }
    }, [props?.contents?.fields?.dispute]);

    const tips = (coinType !== 'loading' && coinType.decimals > -1 && coinType.symbol)? (coinType.symbol + ' decimals: ' + coinType.decimals) : '';
    const summary:Summary = {service:props?.contents?.fields?.service, payer:props?.contents?.fields?.payer, balance:props?.contents?.fields?.payed,
        payed: props?.contents?.fields?.amount, discount:props?.contents?.fields?.discount, progress:props?.contents?.fields?.progress
    };
    const pubkey:string =props?.service?.content?.fields?.customer_required?.fields?.service_pubkey ?? '';

    const items:Item[] = props?.contents?.fields?.items?.map((v:any, index:number) => {
        return {index:index, name:v.fields.name, price:v.fields.price, inventory:v.fields.stock, 
            total:(BigInt(v.fields.price)*BigInt(v.fields.stock)).toString(), endpoint:v.fields.enpoint}
    })
    const requires:string[] = props?.service?.content?.fields?.customer_required?.fields?.customer_required_info ?? []; 
    const requires_tips = 'The service requires providing this information, which will be encrypted with Secret-Key and can only be seen by the service party.';
    const withdrawPerm = Permission.HasPermission(answer, PermissionIndex.service_withdraw);

    var payerPerm = true;
    if (wallet.address) {
        if (wallet.address !== props?.contents?.fields?.payer && !props?.contents?.fields?.agent?.find((v:string) => v===wallet.address)) {
            payerPerm = false;
        }
    }
    const payerTips = payerPerm ? undefined : 'Only the Order payer or his agents has the permission.';

    const launch = async(op:'refund' | 'withdraw' | 'agent' , guard?: string, param?:WithdrawInfo) => {
        if (!wallet.connected) {
            enqueueSnackbar('Please login wallet', { variant: "error" });
            document.getElementById('header-wallet-cmd')?.click();
            return ;
        }
        var guards = guard?[guard]:[]; 
        //@ 既要满足提款要求，也要满足权限要求
        if (op === 'withdraw') {
            if (!withdrawPerm) { //@ 没有权限
                enqueueSnackbar('No withdrawal permission', { variant: "error" });
                return ;
            }

            if (withdrawPerm?.guard) {
                guards.push(withdrawPerm.guard);
            }
        } else if (op === 'refund' || op === 'agent' ) {
            if (!payerPerm) {
                enqueueSnackbar(payerTips, { variant: "error" });
                return ;
            }
        }

        const txb = new TransactionBlock();
        const obj = Service.From(txb, type, permission, service_id);
        const pid = generateRandomString(8);
        if (op === 'withdraw' || op === 'refund') {
            props.guardcheck({id:pid, txb:txb, guards:guards, handler:
                (id:string, txb:TransactionBlock, passport?:Passport) => {
                try {
                    if (id === pid) {
                        if (op === 'refund') {
                            obj.refund(txb.object(order_id), guard, passport?.get_object());
                        } else if (op === 'withdraw' && passport) {
                            add_resource_payment(account, txb, [obj.withdraw(txb.object(order_id), {treasury:props?.service?.content?.fields?.payee, withdraw_guard:param!.guard,
                                index:BigInt(param!.index), remark:param!.remark, for_object:param?.for_object, for_guard:param?.for_guard
                            }, passport?.get_object())]);
                        }
                        passport?.destroy(); // destroy passport
                        props.exe(pid, txb);
                    }
                } catch (e) {
                    console.log(e)
                    enqueueSnackbar( 'Launch Failed', { variant: "error" });
                }
                }
            });            
        } else if (op === 'agent')  {
            try {
                obj.set_order_agent(txb.object(order_id), agent, props?.contents?.fields?.progress ?? undefined);
                props.exe(pid, txb);                
            } catch (e) {
                console.log(e)
                enqueueSnackbar( 'Launch Failed', { variant: "error" });
            }
        } 
    }

    return ( 
    <Box sx={{maxWidth:'100%', padding:'0 1em', pb:'2em', mt:'2em'}}>
        <SettingTitle title='Summary' />
        <Box sx={{mt:'1em', mb:'2em'}}>
        <MaterialTable style={{border:'none', boxShadow:'none'}} title=''
            columns={[
                {
                    title: "Service", field: "service",  render:(data:Summary, type:any) => {
                    return (<Address address={data.service} showType='Service'/>)
                    },
                },
                { title: "Payer", field: "payer", render:(data:Summary, type:any) => {
                    return (<Address address={data.payer}/>)
                    },
                },
                { title: "Payed", field: "payed", render:(data:Summary, type:any) => {
                    const p = coinType!=='loading' ? (ResolveBalance(data.payed, coinType.decimals) + ' ' + coinType.symbol) : data.payed;
                    return (p)
                    },
                },
                { title: "Current Balance", field: "balance", cellStyle:{textAlign:'center'}, render:(data:Summary, type:any) => {
                    const p = coinType !== 'loading' ? (ResolveBalance(data.balance, coinType.decimals) + ' ' + coinType.symbol) : data.balance;
                    return (p)
                    },
                },
                {
                    title: "Discount", field: "discount", align:'center', render:(data:Summary, type:any) => {
                        if (data.discount) return (<Address address={data.discount} showType={'Discount'}/>)
                        return '-'
                        },
                }, {
                    title:'Progress', field:'progress', align:'center', render:(data:Summary, type:any) => {
                        if (data.progress) return <div style={{display:'flex', justifyContent:'center'}}><Address address={data.progress} showType='Progress'/></div>
                        return '-'
                    },
                }, 
                ]}
            options={{toolbar: false, paging:false, padding:'default',}}
            actions={[]}
            data={[summary]}
            components={{
                Header: (header_props) => {
                    return (<TableHead {...header_props} >
                    <TableRow>
                        <TableCell  variant='head' style={{paddingTop:4, paddingBottom:4}}>{header_props.columns[0].title} </TableCell>
                        <TableCell   variant='head' style={{paddingTop:4, paddingBottom:4}}>{header_props.columns[1].title}</TableCell>
                        <TableCell  variant='head' align='center' style={{paddingTop:4, paddingBottom:4}}>
                        <div style={{display:'flex', justifyContent:'center',  }}>
                            <div>{header_props.columns[2].title}</div>
                            <div><Tooltip title={tips} arrow placement='right'>
                            <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                            </Tooltip></div>                      
                        </div>
                        </TableCell>
                        <TableCell variant='head' align='center' style={{paddingTop:4, paddingBottom:4}}>
                        <div style={{display:'flex', justifyContent:'center', }}>
                            <div>{header_props.columns[3].title}</div>
                            <div><Tooltip title='Order balance that would be Refunded or withdrew currently.' arrow placement='right'>
                            <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                            </Tooltip></div>                      
                        </div>
                        </TableCell>
                        <TableCell  variant='head' align='center' style={{paddingTop:4, paddingBottom:4}}>  {header_props.columns[4].title}         
                        </TableCell>
                        <TableCell  variant='head' align='center' style={{paddingTop:4, paddingBottom:4}}>  {header_props.columns[5].title}         
                        </TableCell>
                    </TableRow>
                    </TableHead>)
                }
            }}
        /> 
        </Box>
        <SettingTitle title='Operations' />
        <Box sx={{mb:'2em', display:'flex', alignItems:'center'}}>
            <Tooltip title={payerTips} arrow> 
            <span><Button variant='outlined' className='cmdButton' sx={{mr:'2em', textTransform:'none'}} 
                disabled={summary.balance === '0' || !payerPerm} onClick={()=> {
                    if (props?.service?.content?.fields?.refund_guard?.fields?.contents?.length > 1) {
                        setGuards({op:'refund', guards:
                            props?.service?.content?.fields?.refund_guard?.fields?.contents?.map((v:any)=>{
                                return {guard:v.fields.key, percent:v.fields.value}}) ?? []});
                    } else if (props?.service?.content?.fields?.refund_guard?.fields?.contents?.length === 1) {
                        launch('refund', props?.service?.content?.fields?.refund_guard?.fields?.contents[0].fields.key)
                    } else {
                        launch('refund');
                    }
                }}>{<PermGuardIcon guard={props?.service?.content?.fields?.refund_guard?.fields?.contents?.map((v:any)=>v.fields.key) ?? []} 
                text={summary.balance === '0' ? 'Refunded' : 'Refund'}/>}
            </Button>   
            </span></Tooltip>
            {summary.balance !== '0' && <Tooltip title={payerTips} arrow> 
            <span><Button variant='outlined' className='cmdButton' sx={{mr:'2em', textTransform:'none'}} 
                disabled={summary.balance === '0' || !payerPerm } onClick={()=> {
                    if (refundArb.data.length === 0) {
                        enqueueSnackbar('There is no Arb for refunding', { variant: "error" });
                        return ;
                    }
                    /*if (refundArb.data.filter(v => v.indemnity).length === 0) {
                        enqueueSnackbar('Refunds may not be made until the arbitration claim is confirmed', { variant: "error" });
                        return ;
                    }*/
                    refundArb.show = true;
                    setRefundArb({...refundArb});
                }}>Refund with Arb
            </Button>   
            </span></Tooltip>}
            <Button variant='outlined' className='cmdButton' sx={{mr:'2em', textTransform:'none'}} 
                disabled={summary.balance === '0'} onClick={()=> {
                    setGuards({op:'withdraw', guards:
                        props?.service?.content?.fields?.withdraw_guard?.fields?.contents?.map((v:any)=>{
                            return {guard:v.fields.key, percent:v.fields.value}}) ?? []});
                }}><PermIcon  {...make_permIconProp(answer, PermissionIndex.service_withdraw, undefined, props?.service?.content?.fields?.withdraw_guard?.fields?.contents?.map((v:any)=>v.fields.key))}
                /> {summary.balance === '0' ? 'Withdrew' : 'Widthdraw'}
            </Button>   
        </Box>
        <SettingTitle title='Items Payed' />
        <Box sx={{mt:'1em', mb:'1em'}}>
        <MaterialTable style={{border:'none', boxShadow:'none'}} title=''
            columns={[
                {
                    title: "", field: "index", cellStyle:{paddingRight:0, maxWidth:'1em', fontStyle:'italic', color:grey[400], fontWeight:200}, render:(data:Item, type:any) => {
                    return (data.index+1)
                    },
                },
                { title: "Name", field: "name", cellStyle:{minWidth:'28em'}, render:(data:Item, type:any) => {
                    if (data.endpoint) {
                        return (<div className="cmdText" style={{display:'flex', alignItems:'center', cursor:'pointer'}}>
                            <Tooltip title={data.endpoint}  placement='right' arrow>
                              <a color='black' className="cmdText" target="_blank" href={data.endpoint} rel="noreferrer" >{data.name} </a>
                            </Tooltip>
                            </div>)
                      } else {
                        return (<div style={{display:'flex', alignItems:'center'}}>{data.name}
                        </div>)
                      }
                    },
                },
                { title: "Price", field: "price", render:(data:Item, type:any) => {
                    const p = coinType !== 'loading' ? (ResolveBalance(data.price, coinType.decimals) + ' ' + coinType.symbol) : data.price;
                    return (p)
                    },
                },
                { title: "Inventory", field: "inventory", align:'center', cellStyle:{paddingRight:0, paddingLeft:0}, render:(data:Item, type:any) => {
                    return (data.inventory)
                    },
                },
                { title: "Total", field: "total",cellStyle:{paddingRight:0,}, render:(data:Item, type:any) => {
                    const p = coinType !== 'loading' ? (ResolveBalance(data.total, coinType.decimals) + ' ' + coinType.symbol) : data.total;
                    return (p)
                    },
                },
                ]}
            options={{toolbar: false, paging:false, padding:'default',}}
            actions={[]}
            data={items}
            components={{
                Header: (header_props) => {
                    return (<TableHead {...header_props} >
                    <TableRow>
                        <TableCell  variant='head' style={{paddingTop:4, paddingBottom:4, maxWidth:'3em'}}>{header_props.columns[0].title} </TableCell>
                        <TableCell   variant='head'  style={{paddingTop:4, paddingBottom:4}}>{header_props.columns[1].title}</TableCell>
                        <TableCell  variant='head' align='left' style={{paddingTop:4, paddingBottom:4}}>
                        <div style={{display:'flex', justifyContent:'left', marginLeft:'1em' }}>
                            <div>{header_props.columns[2].title}</div>
                            <div><Tooltip title={tips} arrow placement='right'>
                            <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                            </Tooltip></div>                      
                        </div>
                        </TableCell>
                        <TableCell variant='head' align='center' style={{paddingTop:4, paddingBottom:4}}>{header_props.columns[3].title}</TableCell>
                        <TableCell  variant='head' align='left' style={{paddingTop:4, paddingBottom:4}}>
                        <div style={{display:'flex', justifyContent:'left', marginLeft:'1em' }}>
                            <div>{header_props.columns[4].title}</div>
                            <div><Tooltip title={tips} arrow placement='right'>
                            <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                            </Tooltip></div>                      
                        </div>
                        </TableCell>
                    </TableRow>
                    </TableHead>)
                }
            }}
        /> 
        </Box>
        { pubkey.length > 1 && requires.length > 0 && <>
            <SettingTitle title='Payer Encrypted Information' tips='The plaintext of your infomation is not recorded anywhere, and the encrypted message can only be decrypted and seen by the Service.' />
            <Box sx={{mb:'2em', display:'flex', alignItems:'center'}}>
            <Tooltip title={payerTips} arrow>
                <span>
                <Button variant='outlined' className='cmdButton' sx={{mr:'2em', textTransform:'none'}} 
                disabled={!payerPerm} onClick={()=> {
                    if (!wallet.connected) {
                        enqueueSnackbar('Please login wallet', { variant: "error" });      
                        document.getElementById('header-wallet-cmd')?.click();
                        return ;
                      }
                      setReqInfo(requires.map(v=>{
                        return {title:v, info:''}
                      }))
                }}>Update My Infomation 
                </Button>  </span></Tooltip>
                <Button variant='outlined' className='cmdButton' sx={{mr:'2em', textTransform:'none'}} 
                onClick={()=> {
                    setPrivKey('')
                }}>Decrypt & View 
                </Button>
                <Button variant='outlined' className='cmdButton' sx={{mr:'2em', textTransform:'none'}} onClick={()=>{
                    navigator.clipboard.writeText(props?.contents?.fields?.required_info?.fields?.info ?? '').catch(e=>console.error(e));
                }}>Copy Encrypted Infomation</Button> 
            </Box>
        </>}
        {/*
            requires.length === 0 && <SettingShowValue title='Payer Encrypted Information' tips='The service does not define customer information that needs to be encrypted.' />
        */}
        <SettingTitle title='Agents' tips='Agents have the same order operation power as the order payer and can only be set by the order payer.'/>
        <SettingAddressChips  maxTags={Service.MAX_ORDER_AGENT_COUNT} maxTags_err='Max agent count' placeholder='Enter agent address and press Enter'
            initValue={props?.contents?.fields?.agent??[]} event={(t, v, d) => {
                if (t === 'value') {
                    setAgent([...v]);
                } else {
                    setAgent([]);
                }
            }}/>
        <Tooltip title={payerTips} arrow>
        <span>
            <Button variant='outlined' className='cmdButton' sx={{mt:'1em', textTransform:'none'}} 
                disabled={!payerPerm} onClick={()=> {
                    launch('agent');
                }}>Set Agents
            </Button>   
        </span></Tooltip>
        <SettingWithdraw open={guards.guards.length > 0} hideAmount={guards.guards.length === 0 ? true:false} withdrawGuards={guards.guards.map(v=> {
            return {guard:v.guard, info:v.percent + '%'}})} coinType={coinType} to={props?.service?.content?.fields?.payee} event={(t,v,d) => {
                if (t === 'close') setGuards({guards:[], op:'withdraw'})
                else if (t === 'value') {
                    launch('withdraw', (v as WithdrawInfo).guard, v);
            }
            }} />
        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'sm'} disableRestoreFocus 
        open={guards.guards.length > 0 && guards.op === 'refund'}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>setGuards({guards:[], op:'refund'})}
        >
        <DialogTitle sx={{textAlign:'center'}} > Operation Guard List
            <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setGuards({guards:[], op:'refund'})}}> <CloseIcon /> </IconButton>   
        </DialogTitle>
            <DialogContent >
            <List sx={{border:'1px solid', borderColor:grey[200], mt:'.4em', p:0}}>
                { guards.guards.map((v, index) => {
                   return ( <ListItem key={v.guard} component="div" disablePadding 
                    sx={{display:'flex', alignItems:'center', backgroundColor:v.guard === select?.guard ? pink[50] : undefined}}  
                    secondaryAction={
                    <Checkbox
                      edge="end"
                      onChange={(e) => {
                        if (v.guard === select?.guard) {
                            setSelect(undefined);
                        } else {
                            setSelect(v);
                        }
                      }}
                      checked={v.guard === select?.guard}
                      inputProps={{ 'aria-labelledby': 'guard-list-checkbox' }}
                    />
                  }>
                        <ListItemButton sx={{pl:'.7em', pr:'.7em', color:grey[600], flex:'auto', pt:0, pb:0}}>
                            <ListItemText sx={{width:'6em', ml:0}}>
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <span style={{fontStyle:'italic', width:'1.6em', color:grey[400], marginRight:'.1em', fontSize:'.9em', paddingTop:'.1em'}}>{index+1+'.'}</span>
                                    <Address address={v.guard} showType='Guard'/>
                                </div>
                            </ListItemText>
                            <ListItemText >{v.percent + '%'}</ListItemText>
                        </ListItemButton>   
                    </ListItem>)                      
                    })
                }
            </List>  
            <SettingLaunch text='Verify & Claim' event={(t, v, _) => {
                if (t ==='click') {
                    if (!select) {
                        enqueueSnackbar( 'Select Guard to verify', { variant: "error" });
                        return;
                    }

                    launch(guards.op, select.guard);
                }
            }}/>
            </DialogContent>
        </Dialog>
        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus 
        open={reqInfo.length > 0}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>setReqInfo([])}
        >
        <DialogTitle sx={{textAlign:'center'}} > {'Change Your Infomation'}
            <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setReqInfo([])}}> <CloseIcon /> </IconButton>   
        </DialogTitle>
            <DialogContent >
            <SettingTips text={'The plaintext of your infomation is not recorded anywhere, and the encrypted data can only be decrypted and seen by the Service.'} />
            { reqInfo.map((v) => { return <>
                <SettingTitle title={v.title.toUpperCase()} tips={requires_tips} required/>
                <SettingInputText maxlength={128} value={v.info} err_empty={"Please enter your " + v.title.toUpperCase()} 
                     placeholder={'Enter your ' + v.title.toUpperCase()} event={(t, value, _) => {
                        v.info = value;
                        setReqInfo([...reqInfo]);
                }}/>   
            </>})             
            }

            <SettingLaunch text='Launch' event={(t, v, _) => {
                if (t ==='click') {
                    for (let i = 0; i < reqInfo.length; ++i) {
                        if (!reqInfo[i].info) {
                            enqueueSnackbar(reqInfo[i].title.toUpperCase() + ' empty.', { variant: "error" });
                            return 
                        }                        
                    }
                    if (!pubkey) {
                        enqueueSnackbar('Service Secret-Key invalid', { variant: "error" });
                        return 
                    }

                    try {
                        const txb = new TransactionBlock();
                        const obj = Service.From(txb, type, permission, service_id);
                        const jse = new JSEncrypt();
                        jse.setPublicKey(pubkey);
                        var c = jse.encrypt(JSON.stringify(reqInfo.map((v)=>{return v.title+' : '+v.info})));
                        if (c === false) {
                            c = ''
                        }
            
                        const info: Customer_RequiredInfo = {customer_pubkey:pubkey, customer_info_crypt:c}
                        obj.update_order_required_info(txb.object(order_id), info);  
                        props.exe(generateRandomString(8), txb);                      
                    } catch (e) {
                        console.log(e)
                        enqueueSnackbar('Launch Failed', { variant: "error" });
                    }
                }
            }}/>
            </DialogContent>
        </Dialog>
        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'sm'} disableRestoreFocus 
        open={refundArb.show}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>{ refundArb.show = false; setRefundArb({...refundArb})}}
        >
        <DialogTitle sx={{textAlign:'center'}} > Refund with An Arb
            <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{refundArb.show = false; setRefundArb({...refundArb})}}> <CloseIcon /> </IconButton>   
        </DialogTitle>
            <DialogContent >
            <SettingTitle title={'Arb'} tips={'Arb Object generated by Arbitration that Service supported.'} required/>
            <List sx={{border:'1px solid', borderColor:grey[200], mt:'.4em', p:0}}>
                { coinType !== 'loading' &&  refundArb.data.map((v, index) => {

                   return ( <ListItem key={v.object} component="div" disablePadding 
                    sx={{display:'flex', alignItems:'center', backgroundColor:v.object === refundArb.current? pink[50] : undefined}}  
                    secondaryAction={
                    <Checkbox disabled={!v.indemnity}
                      edge="end"
                      onChange={(e) => {
                        if (v.object === refundArb.current) {
                            refundArb.current = '';
                        } else {
                            refundArb.current = v.object;
                        }
                        setRefundArb({...refundArb});
                      }}
                      checked={v.object === refundArb.current}
                      inputProps={{ 'aria-labelledby': 'guard-list-checkbox' }}
                    />
                  }>
                    <ListItemButton sx={{pl:'.7em', pr:'.7em', color:grey[600], flex:'auto', pt:0, pb:0}} >
                        <ListItemText sx={{width:'6em', ml:0}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <span style={{fontStyle:'italic', width:'1.6em', color:grey[400], marginRight:'.1em', fontSize:'.9em', paddingTop:'.1em'}}>{index+1+'.'}</span>
                                <Address address={v.object} showType='Arb'/>
                            </div>
                        </ListItemText>
                        <ListItemText >{v.indemnity ?   (ResolveBalance(v.indemnity, coinType.decimals) + ' ' + coinType.symbol) : 'Arbitration in progress'}</ListItemText>
                    </ListItemButton>   
                </ListItem>)                      
                })
            }</List>  
            <SettingLaunch text='Launch' event={(t, v, _) => {
                if (t ==='click') {
                    if (!refundArb.current) {
                        enqueueSnackbar('Select the Arb and its payout amount', { variant: "error" });
                        return 
                    }

                    try {
                        const txb = new TransactionBlock();
                        const obj = Service.From(txb, type, permission, service_id);
                        obj.refund_withArb(order_id, refundArb.current, refundArb.data.find((v)=>v.object===refundArb.current)?.type ?? '');
                        props.exe(generateRandomString(8), txb);                      
                    } catch (e) {
                        console.log(e)
                        enqueueSnackbar('Launch Failed', { variant: "error" });
                    }
                }
            }}/>
            </DialogContent>
        </Dialog>
        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus 
        open={privKey !== undefined}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>setPrivKey(undefined)}
        >
        <DialogTitle sx={{textAlign:'center'}} > {'Display Encrypted Information'}
            <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setPrivKey(undefined)}}> <CloseIcon /> </IconButton>   
        </DialogTitle>
            <DialogContent >
            <SettingTitle title='Private Key' tips='Only the private key corresponding to the public key encrypting the information can decrypt the information.' />
            <SettingInputText placeholder='Enter the private key' maxlength={3072} multiline maxRows={6} id='order-info-privkey'
                value={privKey ?? ''} noneScroll={true} event={(t,v,d)=>{
                    setPrivKey(v);
                }}
            />
            {decryptedInfo.length > 0 && <>
                <SettingTitle title='Decrypted Information' tips='Decrypted information.' />
                <SettingInputText maxlength={Service.MAX_PUBKEY_SIZE} multiline maxRows={6}
                value={decryptedInfo} noneBorder={true} noneScroll={true} readOnly sx={{backgroundColor:grey[200]}}
            /></>}
            <div style={{display:'flex', alignItems:'center'}}>
                <Button variant='outlined' className='cmdButton' sx={{mt:'1em', textTransform:'none'}} onClick={()=>{
                    if (!privKey) {
                        enqueueSnackbar('Private Key invalid', { variant: "error" });
                        document.getElementById('order-info-privkey')?.focus();
                        return
                    }
                    const jse = new JSEncrypt();
                    jse.setPrivateKey(privKey);
                    const r  = jse.decrypt(props?.contents?.fields?.required_info?.fields?.info ?? '');
                    //console.log(r)
                    if (!r) {
                        enqueueSnackbar('Decrypt failed: Incorrect Private Key', { variant: "error" });
                        document.getElementById('order-info-privkey')?.focus();
                        return
                    }
                    setDecryptInfo(r.toString());
                }}>Decrypt and Show Information</Button>
            </div>
            <div style={{borderTop:'2px dashed', borderColor:grey[300], width:'100%', height:'2px', marginTop:'1.4em'}}></div>
            <SettingInputText maxlength={3072} multiline maxRows={6} readOnly
                value={customer_pub} noneBorder={true} sx={{backgroundColor:grey[200]}}
            />
            </DialogContent>
        </Dialog>
    </Box>
    );
}