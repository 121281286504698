import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box, InputAdornment} from '@mui/material';
import { DicountDispatch, PermissionAnswer, Service as WowokService, IsValidPercent, PermissionIndexType, PermissionIndex,
  Service_Discount_Type, MAX_NAME_LENGTH,  IsValidU64, Permission, Passport
} from 'wowok';
import { SettingTitle, PERCENTAGE_VALIDATOR, U64_VALIDATOR, SettingLaunch, SettingAddressChips, SettingTimeTime, SettingInputText, U64_LENGTH } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { TransactionBlock, } from 'wowok';
import { generateRandomString } from '../../../util';
import { make_permIconProp } from '../PermCheck';


const enum DiscountType  {
  PERCENT = 'Percentage discount',
  FIXED = 'Fixed discount'
}

interface DiscountData {
  name: string;
  type: string;
  percent: number;
  fixed: number;
  min: bigint;
  quanlity: number;
  receiver: string[];
  start: number;
  end:  number;
}

export default function Discount (props:any) {
  const id = props?.contents?.fields?.id?.id ?? '';
  const type = WowokService.parseObjectType(props?.type);
  const permission = props?.contents?.fields?.permission;
  const answer: PermissionAnswer | undefined = props?.perms;

  const d1 = new Date();
  const d2 = new Date(); 
  d2.setTime(d1.getTime()+604800000);// 1 week
  const start: string = d1.getFullYear() + '-' + (d1.getMonth()+1) + '-' + d1.getDate() + 'T00:00';
  const end: string = d2.getFullYear() + '-' + (d2.getMonth()+1) + '-' + d2.getDate() + 'T23:59';

  const wallet = useWallet(); 
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<DiscountData>({ name:'', type:DiscountType.PERCENT, percent:0, fixed:0, min:BigInt(0), quanlity:1, 
  receiver:[], start:d1.getTime(), end:d2.getTime()});

    const launch = async () => {
      if (data.type===DiscountType.PERCENT && !IsValidPercent(data.percent)) {
        enqueueSnackbar('Discount type inputs error', { variant: "error" });
        document.getElementById('percent')?.focus();
        return
      } else if (data.type===DiscountType.FIXED && !IsValidU64(data.fixed)) {
        enqueueSnackbar('Discount type inputs error', { variant: "error" });
        document.getElementById('fixed')?.focus();
        return
      } 

      if (!IsValidU64(data.min)) {
        enqueueSnackbar('Please enter Minimum order total', { variant: "error" });
        document.getElementById('min')?.focus();
        return
      }
      //console.log(data)
      if (!IsValidU64(data.quanlity) || data.quanlity > WowokService.MAX_DISCOUNT_COUNT_ONCE || data.quanlity < 1) {
        enqueueSnackbar('Please enter Quantity', { variant: "error" });
        document.getElementById('quantity')?.focus();
        return
      } 

      if (data.receiver.length === 0 || data.receiver.length > WowokService.MAX_DISCOUNT_RECEIVER_COUNT) {
        enqueueSnackbar('Please input Receivers and type enter', { variant: "error" });
        document.getElementById('receiver')?.focus();
        return
      }

      if (data.start === 0 || data.end === 0 || data.start + 60000 >= data.end) {
        enqueueSnackbar('Please enter Duration', { variant: "error" });
        document.getElementById('receiver')?.focus();
        return
      }

      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return ;
      }
        
      const txb = new TransactionBlock();
      const obj = WowokService.From(txb, type, permission, id);
      const pid = generateRandomString(8);
      let dispatch:DicountDispatch[] = data.receiver.map((i) => {
        return {receiver:i, count:data.quanlity, discount:{
          name:data.name, 
          type: (data.type === DiscountType.PERCENT)?Service_Discount_Type.ratio:Service_Discount_Type.minus,
          off: (data.type === DiscountType.PERCENT)?data.percent:data.fixed,
          price_greater: data.min,
          time_start: data.start,
          duration_minutes: Math.ceil((data.end - data.start)/60000),
        }}
      })
      props.permissioncheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.service_discount_transfer], handler:
        (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
          try {
            if (id === pid) {
              if (index.includes(PermissionIndex.service_discount_transfer)) {
                  obj.discount_transfer(dispatch);
              };  
              passport?.destroy(); // destroy passport
              props.exe(pid, txb);
            }
          } catch (e) {
            console.log(e)
            enqueueSnackbar( 'Launch Failed', { variant: "error" });
          }
        }
      });
    }

    const disabled = Permission.HasPermission(answer, PermissionIndex.service_discount_transfer, true)?.has === false;
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
          <SettingTitle title='Discount Name' perm={make_permIconProp(answer, PermissionIndex.service_discount_transfer)}/>
          <SettingInputText placeholder='Pick a name for this discount, eg.  Pu-erh tea discount' maxlength={MAX_NAME_LENGTH}
          noPerm={Permission.HasPermission(answer, PermissionIndex.service_discount_transfer, true)?.has === false} autoFocus
            id='discount-name' value={data.name} event={(type, value, id) => {
              data.name = value;
              setData({...data})
            }}/>
  
          <SettingTitle title='Discount type ' required perm={make_permIconProp(answer, PermissionIndex.service_discount_transfer)}/>
          <div style={{display:'flex', alignItems:'flex-end'}}>
            <Select value={data.type} sx={{minWidth:'16em'}} disabled = {disabled}
              onChange={(e)=> {
                data.type = e.target.value;
                setData({...data});
            }}>
              <MenuItem key={DiscountType.PERCENT} value={DiscountType.PERCENT}>{DiscountType.PERCENT}</MenuItem>
              <MenuItem key={DiscountType.FIXED} value={DiscountType.FIXED}>{DiscountType.FIXED}</MenuItem>
            </Select>
            {data.type === DiscountType.PERCENT && <SettingInputText maxlength={3} validator={PERCENTAGE_VALIDATOR}
               sx={{ml:'1em', minWidth:'18em'}} type='number' errorinline min={0} max={100} 
              endAdornment={<InputAdornment position="end">%</InputAdornment>} value={data.percent}
              id='discount-percent' event={(type, value, id) => {
                data.percent = value;
                setData({...data});
              }}/>}
            {data.type === DiscountType.FIXED && <SettingInputText placeholder='Enter value off' maxlength={U64_LENGTH} value={data.fixed}
              sx={{ml:'1em', minWidth:'18em'}} errorinline={true} validator={U64_VALIDATOR} min={0} max={'18446744073709551615'} 
              err_bigint='Input error: Be Integer'  err_min='Overflow, Be >= 0' err_max='Overflow Max' id='fixed' event={(type, value, id) => {
                data.fixed = value ;
                setData({...data});
              }}/>}
          </div>
  
          <SettingTitle title='Minimum total order value'  perm={make_permIconProp(answer, PermissionIndex.service_discount_transfer)}
            tips='The total order value must be equal to or greater than the Minimum Order Value to be eligible for the corresponding discount.'/>
          <SettingInputText maxlength={U64_LENGTH} type='number' validator={U64_VALIDATOR} min={0} max={'18446744073709551615'} sx={{width:'16em'}}
          err_bigint='Input error: Be Integer'  err_min='Overflow, Be >= 0' err_max='Overflow Max' value={data.min.toString()}
            id='discount-min' event={(type, value, id) => {
              data.min = value;
              setData({...data})
            }}/>
  
          <SettingTitle title='Quantity of Dicount Object to dispatch' required perm={make_permIconProp(answer, PermissionIndex.service_discount_transfer)}
            tips='The number of discounts each receiver possesses.'/>
          <SettingInputText maxlength={3} type='number' validator={U64_VALIDATOR} min={1} max={WowokService.MAX_DISCOUNT_COUNT_ONCE} 
            sx={{width:'16em'}}  err_max='Maximum of 200 discount coupons sended once' err_min='Overflow, Be >= 1' value={data.quanlity.toString()}
          id='discount-quantity' event={(type, value, id) => {
            data.quanlity = value;
            setData({...data})
          }}/>
  
          <SettingTitle title='Duration' required tips='Discount applicable dates fomatted "Month / Day / Year hh:mm"' 
            perm={make_permIconProp(answer, PermissionIndex.service_discount_transfer)} />
          <SettingTimeTime id='discount-duration' start={start} end={end} event={(type, value, id) => {
            if (type === 'starttime') {
              data.start = value;
            }
            if (type === 'endtime') {
              data.end = value;
            }

            setData({...data})
          }}/>
  
          <SettingTitle title='Receivers' required perm={make_permIconProp(answer, PermissionIndex.service_discount_transfer)}
            tips=" The receiver will get the discount. The receiver can use the discount directly on an order or send it to others as a transferable asset in Demand or Reward."/>
          <SettingAddressChips placeholder='Input receiver address and press Enter' required='Please input receiver address and press Enter' 
            id='discount-receiver' maxTags={WowokService.MAX_DISCOUNT_RECEIVER_COUNT} maxTags_err='Max receiver count'
              event={(type, value, id) => {
                data.receiver = type==='value' ? value : [];
                setData({...data})
            }}/>
  
          <SettingLaunch text='Send Discount' id='discount-discount' event={(type, value, id) => {
                if (type === 'click') launch();
            }}/>
        </Box>
    );  
  }