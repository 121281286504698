
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useTheme} from '@mui/material/styles';
import { Box} from '@mui/material';
import { TxbObject, Demand as WowokDemand } from 'wowok';
import { SettingTitle, SettingLaunch, SettingOwnedObjectMulti, SettingOwnedObject, SettingOwnedObjectResult} from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { generateRandomString, sliceType } from '../../../util';
import { TransactionBlock } from 'wowok';
import { type TransactionArgument } from '@mysten/sui/transactions';

export default function Bounty (props:any) {
  const id = props?.contents?.fields?.id?.id ?? '';
  const type = WowokDemand.parseObjectType(props?.type);
  const bonus_remains = WowokDemand.MAX_BOUNTY_COUNT - props?.contents?.fields?.bounty?.length;
  const permission = props?.contents?.fields?.permission;

  const [data, setData] = useState<SettingOwnedObjectResult | string[] | null>(null);

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    const launch = async () => {      
      if (!data) {
          enqueueSnackbar( 'Bounty not specified', { variant: "error" });
          return;
      }

      if (!wallet.connected) {
          enqueueSnackbar('Please login wallet', { variant: "error" });
          document.getElementById('header-wallet-cmd')?.click();
          return 
      }
    
      if (sliceType(type) === '0x2::coin::Coin') {
          const d = data as SettingOwnedObjectResult;
          if (d.err) {
            enqueueSnackbar('Bounty invalid', { variant: "error" });
            return
          }
          let total: any = undefined;
          try {
              total = BigInt(d.balance);
          } catch(e) {
              console.log(e)
          }

          if (!total || !d.object) {
              enqueueSnackbar( 'Bounty not specified', { variant: "error" });
              document.getElementById('SettingOwnedObject-number')?.focus();
              return;
          }
      } 

      try {
          const txb = new TransactionBlock();
          const obj = WowokDemand.From(txb, type, permission, id);
          
          if (sliceType(type) === '0x2::coin::Coin') { // modify old
              const d = data as SettingOwnedObjectResult;
              var bounty:any = d.object;
              if (type.includes('0x2::sui::SUI') || type.includes('0x0000000000000000000000000000000000000000000000000000000000000002::sui::SUI')) {
                bounty = txb.splitCoins(txb.gas, [d.balance])[0];
                obj.deposit(bounty);
              } else {
                bounty = txb.splitCoins(d.object, [d.balance])[0];
                obj.deposit(bounty);
              }
          }  else {
            const bounty = (data as string[]).map((v)=>(txb.object(v) as TransactionArgument));
            bounty.forEach((v) => {
              obj.deposit(v as TxbObject);
            })
          }

          props.exe(generateRandomString(8), txb);
      } catch (e) {
          console.log(e);
          enqueueSnackbar('Launch failed', { variant: "error" });
      }      
    }

    var tips = '';
    if (sliceType(type) !== '0x2::coin::Coin') {
        tips = (data ? (data as string[]).length : 0) + ' objects selected for bonus'
    }

    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
     {/*         <SettingTitle title='Add Bounty' required 
              tips='Bounty can be added but not subtracted and any address can be recharged. ' />
              { !wallet.connected && <div className='cmdText' style={{color:theme.palette.primary.main, marginLeft:'1em'}} 
                onClick={() => {document.getElementById('header-wallet-cmd')?.click();} }>Login to select your transferable asserts</div>}
              { wallet.connected && <SettingOwnedObject owner={wallet.address ?? ''} type_filter={type} event={(type, value, id) => {
                  if (type === 'value' && !id) {
                      setData({...value})
                  } else {
                      setData(null)
                  }
            }}/> */} 
            { !wallet.connected && <div className='cmdText' style={{color:theme.palette.primary.main, marginLeft:'1em'}} 
            onClick={() => {document.getElementById('header-wallet-cmd')?.click();} }>Login to select your transferable asserts</div>}
            { wallet.connected && sliceType(type) !== '0x2::coin::Coin' && <div>
                <SettingTitle title='Add Bounty' required tips='Bounty can be added but not subtracted and any address can be recharged. '/>
                <div style={{color:theme.palette.primary.main, marginBottom:'1em', marginLeft:'.4em', marginTop:'1.4em'}}>
                {tips}</div>

                <SettingOwnedObjectMulti owner={wallet.address ?? ''} 
                type_filter={type}  max_select={bonus_remains} err_max_select='Tips: Max bounty count'
                event={(type, value, id) => {
                    if (type === 'value') {
                        setData(value as string[])
                    } else {
                        setData(null)
                    }
                }}/> 
            </div>}
            { wallet.connected && sliceType(type) === '0x2::coin::Coin' && <div >
                <SettingOwnedObject owner={wallet.address ?? ''} type_filter={type}  
                    event={(type, value, id) =>  {
                        if (type === 'value') setData({...value})
                        else setData(null)
                    }}/>
            </div>}
        <SettingLaunch text='Launch' event={(t, v, _) => {
            if (t === 'click') launch();
          }}/>
        </Box>
    );  
  }