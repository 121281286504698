
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box } from '@mui/material';
import { PermissionIndex, PermissionAnswer, Service as WowokService, Permission as WowokPermission, IsValidAddress, PermissionIndexType,
  MAX_DESCRIPTION_LENGTH,  Passport, Treasury} from 'wowok';
import { SettingTitle, SettingLaunch, SettingCoinType, ADDRESS_VALIDATOR, SettingInputText, } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { TransactionBlock, } from 'wowok';
import { generateRandomString } from '../../../util';
import { make_permIconProp, make_permOwnerIconProp } from '../PermCheck';
import { Address } from '../../util/Address';
import { useAccount,add_resource_launched } from '../../util/Account';
export interface BasicData {
    description: string;
    new_permission: boolean;
    new_treasury: boolean;
    permission: string;
    token: string;
    payee: string;
}
  
export default function Basic (props:any) {
  const id = props?.contents?.fields?.id?.id ?? '';
  const permission = props?.contents?.fields?.permission;
  const answer: PermissionAnswer | undefined = props?.perms;
  const type = WowokService.parseObjectType(props?.type);
    const [data, setData] = useState<BasicData>({
      description:props?.contents?.fields?.description ?? "", 
      permission:props?.contents?.fields?.permission ?? "", 
      token:type, 
      payee:props?.contents?.fields?.payee ?? '', 
      new_permission:false, new_treasury:false});

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const account = useAccount();

    const launch = async (txb:TransactionBlock, obj?:WowokService) => {
      try {
        if (obj) { // modify old
          if (data.new_permission) {
              let permission = WowokPermission.New(txb, data.description);
              obj.change_permission(permission.get_object());
              permission.launch();            
          } else if (data.permission !== props?.contents?.fields?.permission) {
            obj.change_permission(data.permission);
          }     
          props.exe(generateRandomString(8), txb);
        } else { // new
            if (data.new_permission) {
                let new_permission = WowokPermission.New(txb, data.description); 
                let tre: any = undefined;
                if (data.new_treasury) {
                  tre = Treasury.New(txb, data.token, new_permission.get_object(), data.description);
                }
                let d = WowokService.New(txb, data.token, new_permission.get_object(), data.description, tre?.get_object() ?? data.payee);
                const addr = [d.launch(), new_permission.launch()];
                if (tre) addr.push(tre.launch());
                add_resource_launched(account, txb, [...addr]);
                props.exe(generateRandomString(8), txb, 'service::Service');           
            } else {
              const query_txb = new TransactionBlock(); //@ new session before object.From
              const p = WowokPermission.From(query_txb, data.permission);
              const pid = generateRandomString(8);
              /*const perms = [PermissionIndex.service];
              if (data.new_treasury) {
                perms.push(PermissionIndex.treasury);
              }*/
              const guards:string[] = [];
              const tips = <div style={{display:'flex', alignItems:'center'}}>
                <Address address={data.permission} />
                <span>: No Permission for Launching Service</span>
                </div>;
              p.QueryPermissions(permission, wallet.address??'', (answer:PermissionAnswer) => {
                var i = WowokPermission.HasPermission(answer, PermissionIndex.service, true);
                if (!i?.has) {
                  enqueueSnackbar(tips, { variant: "error" });
                  return;
                }
                if (i?.guard) guards.push(i.guard);

                if (data.new_treasury) {
                  i = WowokPermission.HasPermission(answer, PermissionIndex.treasury, true);
                  if (!i?.has) {
                    const tips_treasury = <div style={{display:'flex', alignItems:'center'}}>
                    <Address address={data.permission} />
                    <span>: No Permission for Launching Treasury</span>
                    </div>;
                    enqueueSnackbar(tips_treasury, { variant: "error" });
                    return;
                  }      
                  if (i?.guard) guards.push(i.guard);        
                }

                //用原有的txb继续提交
                props.guardcheck({id:pid, txb:txb, answer:answer, guards:[...guards], handler:
                  (id:string, txb:TransactionBlock, passport?:Passport) => {
                    try {
                      if (id === pid) {
                        var tre : any = undefined;
                        if (data.new_treasury) {
                          tre = Treasury.New(txb, data.token, data.permission, data.description, passport?.get_object());
                        }
                        let d = WowokService.New(txb, data.token, data.permission, data.description, tre?.get_object() ?? data.payee, passport?.get_object());
                        passport?.destroy(); // destroy passport
                        const addr = [d.launch()];
                        if (tre) addr.push(tre.launch());

                        add_resource_launched(account, txb, addr);
                        props.exe(generateRandomString(8), txb, 'service::Service');
                      }
                    } catch (e) {
                      console.log(e)
                      enqueueSnackbar( 'Launch Failed', { variant: "error" });
                    }
                  }
                });
              });  
            }          
        }
      } catch (e) {
        console.error('excute error: ', e);
        enqueueSnackbar('Launch failed', { variant: "error" });
      }
    }
  
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
          <SettingTitle title='Description' perm={make_permIconProp(answer, PermissionIndex.service_description)}/>
          <SettingInputText placeholder='Enter the name or description of the service' maxlength={MAX_DESCRIPTION_LENGTH} multiline maxRows={6}
            value={data.description} noPerm={WowokPermission.HasPermission(answer, PermissionIndex.service_description)?.has === false}
            event={(type, value, id)=>{
              data.description = value;
              setData({...data});
            }}/>
  
          <SettingTitle title='Permission' tips='Manage your objects and personnel. Achieve separation of operations and management.' required={!id} checkbox='Create a New Permission for me' 
            id='permission' noPerm={(!answer || answer.owner)  ? false:true} 
            perm={data.new_permission ? undefined : make_permOwnerIconProp(answer) } event={(type, value, id) => {
              data.new_permission = type === 'check' ? value : false
              setData({...data});
            }}/> 
            {data.new_permission && (<SettingInputText  disabled fullWidth value='Create a new Permission, and as the creator, you hold all permission indexes'
                placeholder='Create a new Permission, and as the creator, you hold all permission indexes' maxlength={66} 
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: '#A6008B',
                        },
                      }}/>) }
            {!data.new_permission &&  <SettingInputText placeholder='Enter Permission object address' err_empty='Please enter Permission object address' 
                validator={ADDRESS_VALIDATOR} maxlength={66} value={data.permission} noPerm={answer && !answer.owner}
                  id='permission' event={(type, value, id) => {
                    data.permission = value;
                    setData({...data})
                }}/>}
          <SettingTitle title='Token' required={!id} tips='The payer uses this token to pay for the service order, and the payee receives this token. Once the service is launched, the token cannot be changed, but you can clone the service to modify the token.'/>
          <SettingCoinType err_empty='Please enter token type that payed by payer'  placeholder='Enter token type that payed by payer' 
            disabled={props?.type ? true:false} disablePortal={false}
            id='token' initValue={data.token} event={(type, value, id) => {
              data.token = type === 'value' ? value : '';
              setData({...data})
            }}/>
          <SettingTitle title='Payee Treasury' required={!id} tips='The payee Treasury is used to receive withdrawals for all orders.'  
            perm={make_permIconProp(answer, PermissionIndex.service_payee)} checkbox='Create a New payee Treasury for me' event={(type, value, id) => {
              data.new_treasury = type === 'check' ? value : false
              setData({...data});
            }}/>
          {data.new_treasury && (<SettingInputText  disabled fullWidth value='Create a new payee Treasury for me'
              placeholder='Create a new payee Treasury for me' maxlength={66} 
                  sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: '#A6008B',
                      },
                    }}/>) }
          {!data.new_treasury && <SettingInputText placeholder='Enter the payee Treasury address' err_empty='Please enter the payee Treasury address' 
                validator={ADDRESS_VALIDATOR} maxlength={66} value={data.payee} 
                noPerm={WowokPermission.HasPermission(answer, PermissionIndex.service_payee)?.has === false}
                id='payee' event={(type, value, id) => {
                  data.payee = value ;
                  setData({...data})
                }}/>}

          <SettingLaunch text='Launch' event={(t) => {
            if (t === 'click') {      
              if (!data.new_permission && !IsValidAddress(data.permission)) {
                enqueueSnackbar('Permission not specified', { variant: "error" });
                document.getElementById('permission')?.focus();
                return;
              }
          
              if (!data.token) {
                enqueueSnackbar('Token not specified', { variant: "error" });
                document.getElementById('token')?.focus();
                return
              }
          
              if (!data.new_treasury && !IsValidAddress(data.payee)) {
                enqueueSnackbar('Payee Treasury not specified', { variant: "error" });
                document.getElementById('payee')?.focus();
                return
              }
          
              if (!wallet.connected) {
                enqueueSnackbar('Please login wallet', { variant: "error" });
                document.getElementById('header-wallet-cmd')?.click();
                return 
              }
              
              if (id) {
                const txb = new TransactionBlock(); // new session
                const obj = WowokService.From(txb, type, permission, id);
                const pid = generateRandomString(8);
                const perms: PermissionIndexType[] = [];
                if (data.description !== props?.contents?.fields?.description) {
                  perms.push(PermissionIndex.service_description);
                };
                
                if (data.new_treasury) {
                  perms.push(PermissionIndex.treasury);
                  perms.push(PermissionIndex.service_payee);
                } else if (data.payee !== props?.contents?.fields?.payee) { //@ 不用data里的payee
                  perms.push(PermissionIndex.service_payee);
                }

                props.permissioncheck({id:pid, txb:txb, answer:answer, index:perms, handler:
                  (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
                    try {
                      if (id === pid) {
                        if (index.includes(PermissionIndex.service_description)) {
                          obj.set_description(data.description, passport?.get_object());
                        };  
                        if (index.includes(PermissionIndex.service_payee)) {
                            obj.set_payee(data.payee, passport?.get_object()); //@ payee
                        }    
                        if (index.includes(PermissionIndex.treasury))  {
                          const tre = Treasury.New(txb, data.token, permission, data.description, passport?.get_object());
                          obj.set_payee(tre.get_object(), passport?.get_object()); //@ payee
                          add_resource_launched(account, txb, [tre.launch()]);
                        }
                        passport?.destroy(); // destroy passport
                        launch(txb, obj);          
                      }
                    } catch (e) {
                      console.log(e)
                      enqueueSnackbar( 'Launch Failed', { variant: "error" });
                    }
                  }
                });
              } else {
                launch(new TransactionBlock());
              }
            }
          }}/>
        </Box>
    );  
  }