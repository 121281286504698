
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box, } from '@mui/material';
import { PermissionAnswer, Machine, Permission, MAX_DESCRIPTION_LENGTH, MAX_ENDPOINT_LENGTH, IsValidAddress,
  PermissionIndex, PermissionIndexType, Passport as WowokPassport, Resource, Entity 
} from 'wowok';
import { SettingTitle,  SettingLaunch, ADDRESS_VALIDATOR, SettingInputText, ENDPOINT_VALIDATOR,
    } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { TransactionBlock, } from 'wowok';
import { generateRandomString, } from '../../../util';
import { make_permIconProp, make_permOwnerIconProp } from '../PermCheck';
import { Address } from '../../util/Address';
import { useAccount, add_resource_launched } from '../../util/Account';

export interface BasicData {
    description: string;
    new_permission: boolean;
    permission: string;
    endpoint?: string | null;
    endpoint_err: string;
}
  
export default function Basic (props:any) {
    //console.log(props)
    const id = props?.contents?.fields?.id?.id ?? '';
    const permission = props?.contents?.fields?.permission;
    const [data, setData] = useState<BasicData>({
      description:props?.contents?.fields?.description ?? "", 
      permission:props?.contents?.fields?.permission ?? "", 
      endpoint:props?.contents?.fields?.endpoint ? props?.contents?.fields?.endpoint : null, 
      endpoint_err:'',
      new_permission:false});

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const account = useAccount();

    const answer: PermissionAnswer | undefined = props?.perms;

    const launch = async (txb:TransactionBlock, obj?:Machine) => {      
      try {
        if (obj) { // modify old
          // others txb in async progress.
          if (data.new_permission) {
              let permission = Permission.New(txb, '');
              obj.change_permission(permission.get_object());
              permission.launch();            
          } else if (data.permission !== props?.contents?.fields?.permission) {
              obj.change_permission(data.permission);
          }     
          props.exe(generateRandomString(8), txb); 
        } else { // new
            if (data.new_permission) {
                let permission = Permission.New(txb, '');
                let d = Machine.New(txb, permission.get_object(), data.description, data.endpoint);
                add_resource_launched(account, txb, [d.launch(), permission.launch()]);
                props.exe(generateRandomString(8), txb, 'machine::Machine');           
            } else {
              // 获取 perm 的当前权限
              const query_txb = new TransactionBlock(); //@ new session before object.From
              const p = Permission.From(query_txb, data.permission);
              const pid = generateRandomString(8);
              const perm = PermissionIndex.machine;
              const tips = <div style={{display:'flex', alignItems:'center'}}>
                <Address address={data.permission} />
                <span>: No Permission for Launching Machine</span>
                </div>;
              p.QueryPermissions(permission, wallet.address??'', (answer:PermissionAnswer) => {
                const i = Permission.HasPermission(answer, perm, true);
                if (!i?.has) {
                  enqueueSnackbar(tips, { variant: "error" });
                  return;
                }
                //用原有的txb继续提交
                props.guardcheck({id:pid, txb:txb, answer:answer, guards:i.guard ? [i.guard] : [], handler:
                  (id:string, txb:TransactionBlock, passport?:WowokPassport) => {
                    try {
                      if (id === pid) {
                        let d = Machine.New(txb, data.permission, data.description, data.endpoint, passport?.get_object());
                        passport?.destroy(); // destroy passport
                        add_resource_launched(account, txb, [d.launch()]);
                        props.exe(generateRandomString(8), txb, 'machine::Machine');
                      }
                    } catch (e) {
                      console.log(e)
                      enqueueSnackbar( 'Launch Failed', { variant: "error" });
                    }
                  }
                });
              });   
            }          
          }
        } catch (e) {
            console.log(e);
            enqueueSnackbar('Launch failed', { variant: "error" });
        }
    }
    
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
          <SettingTitle title='Description' perm={make_permIconProp(answer, PermissionIndex.machine_description)}/>
          <SettingInputText placeholder='Enter the name or description of the Machine' maxlength={MAX_DESCRIPTION_LENGTH} multiline maxRows={6} autoFocus
            value={data.description} noPerm={Permission.HasPermission(answer, PermissionIndex.machine_description)?.has === false}
            event={(type, value, id)=>{
              data.description = value;
              setData({...data});
            }}/>
  
          <SettingTitle title='Permission' tips='Manage your objects and personnel. Achieve separation of operations and management.' required={!id} checkbox='Create a New Permission for me' 
            id='permission' noPerm={(!answer || answer.owner)  ? false:true}  perm={data.new_permission ? undefined : make_permOwnerIconProp(answer) }
            event={(type, value, id) => {
              data.new_permission = type === 'check' ? value : false
              setData({...data});
            }}/> 
            {data.new_permission && (<SettingInputText  disabled fullWidth value='Create a new Permission, and as the creator, you hold all permission indexes'
                placeholder='Create a new Permission, and as the creator, you hold all permission indexes' maxlength={66} 
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: '#A6008B',
                        },
                      }}/>) }
            {!data.new_permission &&  <SettingInputText placeholder='Enter Permission object address' err_empty='Please enter Permission object address' 
                validator={ADDRESS_VALIDATOR} maxlength={66} value={data.permission} noPerm={answer && !answer.owner}
                  id='permission' event={(type, value, id) => {
                    data.permission = value;
                    setData({...data})
                }}/>}
            <SettingTitle title='Endpoint' perm={make_permIconProp(answer, PermissionIndex.machine_endpoint)}
              tips='To provide unified and customized operation pages, improving operational efficiency and experience.'/>
            <SettingInputText placeholder='Enter "ipfs://" or "https://" about item details'  maxlength={MAX_ENDPOINT_LENGTH} multiline maxRows={4}
                validator={ENDPOINT_VALIDATOR}  value={data.endpoint} noPerm={Permission.HasPermission(answer, PermissionIndex.machine_endpoint)?.has === false}
                id='endpoint' event={(type, value, id) => {
                    data.endpoint = value;
                    data.endpoint_err = type === 'value' ? '' : 'err';
                    setData({...data});
                }}/>
   
            <SettingLaunch text='Launch' event={(t, v, _) => {
            if (t === 'click') {
              if (!data.new_permission && !IsValidAddress(data.permission)) {
                enqueueSnackbar('Permission not specified', { variant: "error" });
                document.getElementById('permission')?.focus();
                return;
              }
        
              if (data.endpoint_err) {
                enqueueSnackbar('Endpoint invalid', { variant: "error" });
                document.getElementById('endpoint')?.focus();
                return;
              }
        
              if (!wallet.connected) {
                enqueueSnackbar('Please login wallet', { variant: "error" });
                document.getElementById('header-wallet-cmd')?.click();
                return 
              }

              if (id) {
                const txb = new TransactionBlock(); // new session
                const obj = Machine.From(txb, permission, id);
                const pid = generateRandomString(8);
                const perms: PermissionIndexType[] = [];
                if (data.description !== props?.contents?.fields?.description) {
                  perms.push(PermissionIndex.machine_description);
                };
                if (data.endpoint !== props?.contents?.fields?.endpoint && (data.endpoint || props?.contents?.fields?.endpoint)) {
                  perms.push(PermissionIndex.machine_endpoint);
                };
                props.permissioncheck({id:pid, txb:txb, answer:answer, index:perms, handler:
                  (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:WowokPassport) => {
                    try {
                      if (id === pid) {
                        if (index.includes(PermissionIndex.machine_description)) {
                          obj.set_description(data.description, passport?.get_object());
                        };  
                        if (index.includes(PermissionIndex.machine_endpoint)) {
                          obj.set_endpoint(data.endpoint, passport?.get_object());
                        }     
                        passport?.destroy(); // destroy passport
                        launch(txb, obj);          
                      }
                    } catch (e) {
                      console.log(e)
                      enqueueSnackbar( 'Launch Failed', { variant: "error" });
                    }
                  }
                });
              } else {
                launch(new TransactionBlock());
              }
            }
          }}/>
        </Box>
    );  
  }