
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box, Typography, Tooltip, Button } from '@mui/material';
import { PermissionAnswer, Machine, Permission, PermissionIndexType, PermissionIndex, Passport, MachineAddress} from 'wowok';
import { SettingSwitch, SettingTips } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { grey } from '@mui/material/colors';
import { TransactionBlock, } from 'wowok';
import { generateRandomString } from '../../../util';
import { make_permIconProp } from '../PermCheck';
import { PermIcon } from '../../util/Icons';
import { useLocation } from 'react-router-dom';
import { useAccount, add_resource_launched } from '../../util/Account';

export default function Status (props:any) {
    const path = useLocation();
    const id = props?.contents?.fields?.id?.id ?? '';
    const permission = props?.contents?.fields?.permission;
    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const answer: PermissionAnswer | undefined = props?.perms;
    const account = useAccount();

    const launch = async (perm:PermissionIndexType, value:any) => {      
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }

      const txb = new TransactionBlock();
      const obj = Machine.From(txb, permission, id);
      const pid = generateRandomString(8);
      props.permissioncheck({id:pid, txb:txb, answer:answer, index:[perm], handler:
        (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
          try {
            if (id === pid) {
              if (index.includes(perm)) {
                if (perm === PermissionIndex.machine_clone) {
                    add_resource_launched(account, txb, [obj.clone(true, passport?.get_object()) as MachineAddress]);              
                } else if (perm === PermissionIndex.machine_publish) {
                    obj.publish(passport?.get_object());
                } else if (perm === PermissionIndex.machine_pause) {
                    obj.pause(value, passport?.get_object());
                }
              };    
              passport?.destroy(); // destroy passport
              if (perm === PermissionIndex.machine_clone) {
                props.exe(pid, txb, 'machine::Machine');
              } else {
                props.exe(pid, txb);
              }
            }
          } catch (e) {
            console.log(e)
            enqueueSnackbar( 'Launch Failed', { variant: "error" });
          }
        }
      });
    }
    
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
            {props?.contents?.fields?.bPublished === true && path.search.toLowerCase().includes('setting') && 
                <SettingTips text={<>
                <span>The machine has been released, its Nodes will not be changed. You can modify and form a new Machine through the Clone method blow.</span>
                </>} />
            }
            {props?.contents?.fields?.bPublished === false && <SettingSwitch  title='Allow running Progress' checked={props!.contents!.fields!.bPublished} 
                tips='The status is irreversible. If true, machine nodes would not be modified.' disabled={props!.contents!.fields!.bPublished}
                noPerm={Permission.HasPermission(answer, PermissionIndex.machine_publish)?.has === false}
                perm={make_permIconProp(answer, PermissionIndex.machine_publish)}
                event={(type, value, id) => {
                if (type === 'check') {
                    launch(PermissionIndex.machine_publish, value);
                }
            }}/>}
            {props?.contents?.fields?.bPublished === true && <SettingSwitch  title='Allow Progress creation'  checked={!props!.contents!.fields!.bPaused} 
                noPerm={Permission.HasPermission(answer, PermissionIndex.machine_pause, true)?.has === false}
                perm={make_permIconProp(answer, PermissionIndex.machine_pause)}
                event={(t, v, d) => {
                    if (t === 'check') {
                        launch(PermissionIndex.machine_pause, !(v as boolean)); 
                    }}}
                    tips='If machine paused, new progress would not be generated.'
            />}
            <div style={{margin:'1.4em 0 .4em 0'}}>
                <Typography display='inline' sx={{fontSize:'1.1em', fontWeight:500}}>Clone Machine</Typography>
                <Tooltip title={'After cloning a machine, you can edit the cloned machine, such as adding some nodes.'} arrow placement='right'>
                    <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                </Tooltip>
                <Button variant='contained' sx={{ml:'1.6em'}} size='small' 
                    disabled={Permission.HasPermission(answer, PermissionIndex.machine_clone, true)?.has === false}
                    onClick={()=>launch(PermissionIndex.machine_clone, null)}>
                    {<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                    <span>Clone</span> 
                    <PermIcon {...make_permIconProp(answer, PermissionIndex.machine_clone)} />
                    </div>}
                </Button>
            </div>
        </Box>
    );  
  }