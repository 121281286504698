import React, { useEffect, useState } from 'react';
import { Box, Button, Tooltip, Dialog, DialogContent, DialogTitle, IconButton, Typography, Skeleton, } from "@mui/material"
import { Treasury as WowokTreasury, Permission, CoinTypeInfo, Protocol, ResolveBalance, PermissionIndex, PermissionAnswer, Treasury_Operation,
    MAX_DESCRIPTION_LENGTH, IsValidU64, IsValidDesription, IsValidAddress, PermissionIndexType, Passport, array_unique, Treasury_WithdrawMode,
} from "wowok";
import MaterialTable, {MTableToolbar} from "material-table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { SettingTitle, SettingInputText, ADDRESS_VALIDATOR, SettingCoinInput, U64_LENGTH, U64_VALIDATOR, SettingLaunch, 
} from '../launch/Settings';
import { Transition } from '../util/Common';
import { TransactionBlock, } from 'wowok';
import { Address } from '../util/Address';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { grey } from '@mui/material/colors';
import { PermIcon } from '../util/Icons';
import { make_permIconProp } from '../launch/PermCheck';
import CloseIcon from '@mui/icons-material/Close';
import { generateRandomString } from '../../util';
import { useTheme, } from '@mui/material/styles';
import { Guard } from '../launch/treasury/WithdrawGuard';
import LinkIcon from '@mui/icons-material/Link';
import { useAccount, add_resource_payment } from '../util/Account';
import { SettingWithdrawProp, SettingWithdraw, WithdrawInfo, GuardInfo } from '../launch/Withdraw';

interface Flows {
    amount: BigInt,
    signer: string,
    deposit: boolean,
    for?: string,
    tips: string,
    index?: BigInt,
    time: string,
    to?: string,
    from?: string,
    for_guard?: string,
    payment: string,
}
interface Inflow {
    amount: string,
    tips: string,
    for?: string,
    index?: string,
    from?: string,
    for_guard?: string,
}
interface Outflow {
    amount: string,
    tips: string,
    for?: string,
    index: string,
    to: string,
    for_guard?: string,
    bSelfAddress: boolean,
    withdraw_guard?: string,
}

interface Receiveflow {
  //coin: string;
  balance: string;
  payment: string;
  object: string;
}

interface Balance {
  object: string;
  balance: bigint;
}

export default function Data(props:any) {
    //console.log(props)
    //console.log(props?.contents?.fields?.free_deposit)
    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const account = useAccount();

    const id = props?.contents?.fields?.id?.id ?? '';
    const permission = props?.contents?.fields?.permission;
    const answer: PermissionAnswer | undefined = props?.perms;
    const type = WowokTreasury.parseObjectType(props?.type);
    const coin_type = '0x2::coin::Coin<'+type+'>';
    const wrapper_type = Protocol.Instance().package('wowok')+'::payment::CoinWrapper<'+type+'>';
    const deposit_guard = props?.contents?.fields?.deposit_guard ?? '';
    const withdraw_mode = parseInt(props?.contents?.fields?.withdraw_mode);
    const withdraw_guards:Guard[] = props?.contents?.fields?.withdraw_guard?.fields?.contents?.map((v:any, index:number) => {
      return {guard:v?.fields?.key, amount: v?.fields?.value, index:index}
    });

    const [coinType, setCoinType] = useState<CoinTypeInfo | 'loading'>(Protocol.Instance().coinTypeInfo(type, (info) => {
        setCoinType(info);
      }))
    const [depositData, setDepositData] = useState<Inflow>({amount:'', tips:'', index:''});
    const [showDeposit, setShowDeposit] = useState(false);
    const [showWithdraw, setShowWithdraw] = useState<undefined | 'perm' | 'guard'>(undefined);
    const [showReceive, setShowReceive] = useState(false);
    const [balance, setBalance] = useState<Balance[]>([]); // 自己账户对象
    const [balanceTotal, setBalanceTotal] = useState(BigInt(0)); // 自己账户余额，用于deposit
    const [data, setData] = useState<Flows[]>([]); 
    const [receive, setReceive] = useState<Receiveflow[]>([]);
    const [receiveTotal, setReceiveTotal] = useState(BigInt(0));  // 收到的款项

    useEffect( () => {
      Protocol.Client().multiGetObjects({ids:array_unique(props.fields.map((i:any) => i?.data?.content?.fields?.value?.fields?.payment)), 
        options:{showContent:true}}).then((res:any) => {
        //console.log(res)
        const flows: Flows[] = [];
        for (let i = 0; i < props.fields.length; ++i) {
          const f = props.fields[i]?.data?.content?.fields?.value?.fields;
          const payment = res.find((j:any)=>j?.data?.objectId===f?.payment); // 找到对应的payment
          const payment_fields = payment?.data?.content?.fields;
          if (!payment) {
            continue;
          }

          if (f?.op === Treasury_Operation.DEPOSIT || f?.op === Treasury_Operation.RECEIVE) { //@ 合并金额
            let amount = BigInt(0);

            payment_fields?.record?.forEach((record:any) => {
              if (record?.fields?.recipient === id) {
                var a = BigInt(record?.fields?.amount);
                amount = amount + a;
              }
            });
            flows.push({amount:amount, deposit:true, for:payment_fields?.for_object, from:payment_fields?.from, index:BigInt(payment_fields?.index),
              signer: payment_fields?.signer, time:payment_fields?.time, tips:payment_fields?.remark, payment:f?.payment});
          } else if (f?.op === Treasury_Operation.WITHDRAW) { // 按TO取拆分多条
            payment_fields?.record?.forEach((record:any) => {
              flows.push({amount:BigInt(record?.fields?.amount), deposit:false, for:payment_fields?.for_object, to:record?.fields?.recipient, index:BigInt(payment_fields?.index),
                signer: payment_fields?.signer, time:payment_fields?.time, tips:payment_fields?.remark, payment:f?.payment});
            });
          }
        }
        //console.log(flows)
        setData([...flows])
      }).catch((e) => {
        console.log(e)
      })
    }, [props.fields, id])
    
    const tips = <div>{type} <br/> {'Token decimals: ' + (coinType !== 'loading' ? coinType.decimals.toString() : 'unknown' )}</div>;
    const balance_user = coinType !== 'loading' ? ResolveBalance(balanceTotal.toString(), coinType.decimals) : '0';
    const balance_receive = coinType !== 'loading' ? ResolveBalance(receiveTotal.toString(), coinType.decimals) : '0';
    let len = data.length;
    len = len >= 20 ? 20 : 10;

    useEffect( () => {
      if (wallet.address) {
        Protocol.Client().getOwnedObjects({owner:wallet.address, filter:{StructType: coin_type}, options:{showContent:true, showType:true}}).then((res) => {
          let total = BigInt(0);
            try {
              setBalance(res.data.map((i:any) => {
                total += BigInt(i?.data?.content?.fields?.balance);
                return {balance:BigInt(i?.data?.content?.fields?.balance), object:i?.data?.objectId}
              }));            
              setBalanceTotal(total);  
              return ;
            } catch (e) {
              console.log(e)
            }
        }).catch((e) => {
            console.log(e);
        })              
      }

      Protocol.Client().getOwnedObjects({owner:id, filter:{StructType: wrapper_type}, options:{showContent:true, showType:true}}).then((res) => {
        //console.log(res)
        try {
          let receive = BigInt(0);
          setReceive(res.data.map((v:any) => {
            const i = v?.data?.content?.fields;
            receive += BigInt(i?.coin?.fields?.balance);
            return {payment:i?.payment, balance:i?.coin?.fields?.balance, /*coin:i?.coin?.fields?.id?.id*/ object:v?.data?.objectId}
          }));
          setReceiveTotal(receive);
        } catch (e) {
          console.log(e)
        }
    }).catch((e) => {
        console.log(e);
    })     
  }, [coin_type, id, wallet.address, wrapper_type]);

    const onDeposit = (param:'perm' | 'guard') => {
      const do_deposit = (obj:WowokTreasury, txb:TransactionBlock, need:bigint, pid:string, passport?:Passport) => {
        try {
            //@ SUI 从GAS获得
            if (type === '0x2::sui::SUI' || type === '0x0000000000000000000000000000000000000000000000000000000000000002::sui::SUI') {
              add_resource_payment(account, txb, [obj.deposit({coin:txb.splitCoins(txb.gas, [need]), index:BigInt(depositData.index ? depositData.index: 0), 
                remark:depositData.tips, for_object:depositData.for, for_guard:depositData.for_guard}, passport?.get_object())]);                      
            } else {
              const coins = []; let current = BigInt(0); 
              for (let i = 0; i < balance.length; ++ i) {
                if (current >= need) { 
                  break;
                }
                current = current + balance[i].balance;
                coins.push(balance[i].object);
              };
              //console.log(depositData)
              if (coins.length > 1) {
                const c = coins.pop()!;
                txb.mergeCoins(c, coins);
                add_resource_payment(account, txb, [obj.deposit({coin:txb.splitCoins(txb.object(c), [need]), index:BigInt(depositData.index ? depositData.index: 0), 
                  remark:depositData.tips, for_object:depositData.for, for_guard:depositData.for_guard}, passport?.get_object())]);
              } else if (coins.length === 1){
                add_resource_payment(account, txb, [obj.deposit({coin:txb.splitCoins(coins[0], [need]), index:BigInt(depositData.index ? depositData.index: 0), 
                  remark:depositData.tips, for_object:depositData.for, for_guard:depositData.for_guard}, passport?.get_object())]);
              }   
            }
            passport?.destroy(); // destroy passport
            props.exe(pid, txb);            
        } catch (e) {
          console.log(e)
          enqueueSnackbar( 'Launch Failed', { variant: "error" });
        }
      }
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }
      if (!IsValidU64(depositData.amount, 1)) {
          enqueueSnackbar('Amount invalid', { variant: "error" });
          document.getElementById('treasury-deposit-amount')?.focus();
          return
      }
      const need = BigInt(depositData.amount);
      if (need > balanceTotal) {
          enqueueSnackbar('Amount exceeds the currently available value', { variant: "error" });
          document.getElementById('treasury-deposit-amount')?.focus();
          return
      }
      if (depositData.tips && !IsValidDesription(depositData.tips)) {
          enqueueSnackbar('Remark invalid', { variant: "error" });
          document.getElementById('treasury-deposit-remark')?.focus();
          return
      }
      if (depositData.for && !IsValidAddress(depositData.for)) {
          enqueueSnackbar('For Object invalid', { variant: "error" });
          document.getElementById('treasury-deposit-for')?.focus();
          return
      }
      if (depositData.index && !IsValidU64(depositData.index)) {
          enqueueSnackbar('Biz-ID invalid', { variant: "error" });
          document.getElementById('treasury-deposit-index')?.focus();
          return
      }
      if (depositData.for_guard && !IsValidAddress(depositData.for_guard)) {
          enqueueSnackbar('For Guard invalid', { variant: "error" });
          document.getElementById('treasury-deposit-guard')?.focus();
          return
      }

      const txb = new TransactionBlock();
      const obj = WowokTreasury.From(txb, type, permission, id);
      const pid = generateRandomString(8);
      if (param === 'guard' && deposit_guard) { // guard
        props.guardcheck({id:pid, txb:txb, guards:[deposit_guard], 
          handler:(id:string, txb:TransactionBlock, passport?:Passport)=> {
            do_deposit(obj, txb, need, pid, passport);
        }})
      } else { // permission 
        props.permissioncheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.treasury_deposit], handler:
          (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
              if (id === pid) {
                if (index.includes(PermissionIndex.treasury_deposit)) {
                  do_deposit(obj, txb, need, pid, passport);
                }
              }
          }
        });
      }
    }

    const onWithdraw = (obj:WowokTreasury, txb:TransactionBlock, amount:bigint, param:WithdrawInfo, pid:string, passport?:Passport) => {
      try {
        const res = obj.withdraw({for_object:param.for_object, for_guard:param.for_guard, remark:param.remark,
            index: param.index ? BigInt(param.index) : BigInt(0), withdraw_guard:param.guard,
            items:[{amount:amount, address:param.bSelf ? wallet.address! : param.to}]
          }, passport?.get_object());
        if(res) add_resource_payment(account, txb, [res]);
        passport?.destroy(); // destroy passport
        props.exe(pid, txb);     
      } catch (e) {
        console.log(e)
        enqueueSnackbar( 'Launch Failed', { variant: "error" });
      }
    }
/*    var extern_tips:string | undefined = undefined;
    if (withdraw_mode === Treasury_WithdrawMode.PERMISSION) {
      extern_tips = "Withdraw Mode is the not 'Compound' or 'External'.";
    } else if (withdraw_guards.length === 0) {
      extern_tips = 'Withdraw Guard not set yet.';
    }*/
    return ( <Box className='noboxshadow' sx={{padding:'0 2em'}}>
        <MaterialTable title=''
        columns={[
        { title: 'Amount', field: 'amount', cellStyle:{paddingRight:0,paddingLeft:0}, render:(data, type) => {
            const p = coinType !=='loading' ? (ResolveBalance(data.amount.toString(), coinType.decimals)) : data.amount.toString();
            return (<div style={{display:'flex'}}>
                {data.deposit && <Tooltip title='Deposit' arrow>
                    <ArrowUpwardIcon sx={{height:'.8em!important', width:'.8em!important', color:"primary.main"}} />
                    </Tooltip>}
                {!data.deposit && <Tooltip title='Withdraw' arrow>
                <ArrowDownwardIcon sx={{color:"primary.main", height:'.8em!important', width:'.8em!important'}}/>
                </Tooltip>}
            <div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'8em'}}>{p}</div>
            </div>)
        }},
        { title: 'Details', field: 'payment', cellStyle:{paddingRight:0},render:(data, type) => {
          return ( <>
          {<Address address={data.payment} showType={'Payment'}/>}
          </>)
        }}, 
        { title: 'Remark', field: 'tips', cellStyle:{paddingRight:0},render:(data, type) => {
            return (<div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'12em'}}>{data.tips}</div>)
        }}, 
        { title: 'Biz-ID', field: 'index', cellStyle:{paddingRight:4, paddingLeft:4},render:(data, type) => {
            return (<div style={{textAlign:'center', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'6em'}}>{data.index?.toString()}</div>)
        }}, 
        { title: 'For', field: 'for', cellStyle:{paddingLeft:4, paddingRight:4},render:(data, type) => {
          return (<Address address={data.for} showType/>)
        }}, 
        { title: 'Signer', field: 'signer', cellStyle:{paddingRight:0,paddingLeft:4}, render:(data, type) => {
          return (<div>
              <Address address={data.signer}/>
          </div>)
        }}, 
        { title: 'Time', field: 'time', cellStyle:{paddingLeft:0, paddingRight:0}, render:(data, type) => {
            const ts = new Date(parseInt(data.time ?? ''));
            return (<div style={{textAlign:'center'}}> {ts.toLocaleString()} </div>)
        }},
        ]}
        data={data.sort((a, b)=>parseInt(b.time)-parseInt(a.time))}   
        options={{
            padding: "dense", 
            pageSize: len, 
            pageSizeOptions: [5, 10, 20],
        }}
        components={{
            Header: (props) => {
                const title = coinType !== 'loading' ? coinType.symbol + ' ' + props.columns[0].title : props.columns[1].title;
              return (<TableHead {...props} style={{}}>
                <TableRow>
                  <TableCell variant='head' align='left' style={{paddingRight:0, minWidth:'8em', paddingLeft:0}}>
                    <div style={{display:'flex', justifyContent:'center'}}>
                      <div>{title}</div>
                      <div><Tooltip title={tips} arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head' align='left'>
                    <div style={{display:'flex', justifyContent:'start', marginRight:'1em', paddingLeft:0}}>
                      <div>{props.columns[1].title}</div>
                      <div><Tooltip title='Payment details of the flow.' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head'  align='center'>
                    <div style={{display:'flex', justifyContent:'start', marginRight:'1em', paddingLeft:0}}>
                      <div>{props.columns[2].title}</div>
                      <div><Tooltip title='Remark information of the flow.' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head' style={{paddingLeft:0, paddingRight:0, textAlign:'center'}} align='center'>
                    <div style={{display:'flex', justifyContent:'center'}}>
                      <div>{props.columns[3].title}</div>
                      <div><Tooltip title='Business ID number related to transfer flow, If any.' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head'  align='center'>
                    <div style={{display:'flex', justifyContent:'center', marginRight:'1em', paddingLeft:0}}>
                      <div>{props.columns[4].title}</div>
                      <div><Tooltip title='The Object related to transfer flow, If any.' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head'  align='center'>
                    <div style={{display:'flex', justifyContent:'start', marginRight:'1em', paddingLeft:0}}>
                      <div>{props.columns[5].title}</div>
                      <div><Tooltip title='Transaction signer.' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head' align='center'>{props.columns[6].title}</TableCell>
                </TableRow>
              </TableHead>)
            },
            Toolbar: tprops => (
                <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    <div style={{display:'flex'}}>
                    <Button variant='contained' disabled={Permission.HasPermission(answer, PermissionIndex.treasury_deposit, true)?.has === false && !deposit_guard}
                        sx={{textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
                        onClick={ () => {
                            setShowDeposit(true)
                        }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                        <span>Deposit</span> 
                        {!deposit_guard && <PermIcon {...make_permIconProp(answer, PermissionIndex.treasury_deposit)} />}
                    </div>}</Button>
                    <Button variant='contained' disabled={Permission.HasPermission(answer, PermissionIndex.treasury_receive, true)?.has === false}
                        sx={{ml:'2em', textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
                        onClick={ () => {
                            setShowReceive(true)
                        }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                        <span>Receive</span> 
                        <PermIcon {...make_permIconProp(answer, PermissionIndex.treasury_receive)} />
                    </div>}</Button>    
                    {(withdraw_mode === Treasury_WithdrawMode.PERMISSION || withdraw_mode === Treasury_WithdrawMode.BOTH_PERMISSION_AND_GUARD) && 
                      <Button variant='contained' 
                        disabled={Permission.HasPermission(answer, PermissionIndex.treasury_withdraw, true)?.has === false}
                          sx={{ml:'2em', textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
                          onClick={ () => {
                              setShowWithdraw('perm')
                          }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                          <span>{(withdraw_mode !== Treasury_WithdrawMode.PERMISSION && withdraw_guards.length > 0) ? 'Internal Withdraw' : 'Withdraw'}</span> 
                          <PermIcon {...make_permIconProp(answer, PermissionIndex.treasury_withdraw)} />
                      </div>}</Button>}  
                    {withdraw_mode !== Treasury_WithdrawMode.PERMISSION && withdraw_guards.length > 0 && <Button variant='contained'
                          sx={{ml:'2em', textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
                          onClick={ () => {
                              setShowWithdraw('guard')
                          }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                          <span>External Withdraw</span> 
                      </div>}</Button>}          
                    </div>
                    <MTableToolbar {...tprops} />
                </div>
            ),
          }}/>  
        <Dialog disableRestoreFocus  open={showDeposit}  fullWidth maxWidth='md' TransitionComponent={Transition}
            keepMounted  onClose={()=> { setShowDeposit(false) }}>
            <DialogTitle sx={{textAlign:'center'}} > Deposit 
                <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{ setShowDeposit(false)}}> <CloseIcon /> </IconButton>   
            </DialogTitle>
            <DialogContent>
            { coinType === 'loading' && <>
                    <Skeleton animation='pulse' sx={{height:'4em'}} />
                    <Typography variant='body2' >Fetching Token information...</Typography>
                </>}
            { coinType !== 'loading'  && <><div style={{display:'flex', alignItems:'center', }}>
                <SettingTitle title={'Amount'} required tips={tips}/>
                <div style={{marginLeft:'auto', marginTop:'1em', color:grey[700], fontSize:'.9em'}}>
                  <span style={{color:grey[500], }}>Available:</span>
                  <span style={{marginLeft:'.2em'}}>{balance_user} </span>
                  <span style={{marginLeft:'1px'}}>{coinType.symbol}</span>  
                  </div>             
              </div>
                <SettingCoinInput placeholder='Enter the amount to deposit' fullWidth value={depositData.amount} 
                symbol={coinType.symbol} decimals={coinType.decimals} min='1' id='treasury-deposit-amount' event={(t,v,i) => {
                    depositData.amount = v;
                    setDepositData({...depositData});
                }}/>
                <SettingTitle title='Remark' tips='Remark information of the flow.'/>
                <SettingInputText placeholder='Enter the remark information for the deposit' maxlength={MAX_DESCRIPTION_LENGTH} multiline maxRows={6}
                    id='treasury-deposit-remark' value={depositData.tips} event={(t, v, d)=>{
                    depositData.tips = v;
                    setDepositData({...depositData});
                    }}/>
                <SettingTitle title='Biz-ID' tips='Business ID number related to transfer flow, If any. Default value: 0.'/>
                <SettingInputText value={depositData.index ?? ''}  type='number' maxlength={U64_LENGTH} validator={U64_VALIDATOR} 
                id='treasury-deposit-index' placeholder='Enter the business ID number related to transfer flow' event={(t,v,d) => {
                    depositData.index = v;
                    setDepositData({...depositData});
                }}/>
                <SettingTitle title='For Object' tips='The Object related to transfer flow, If any.'/>
                <SettingInputText value={depositData.for ?? ''} maxlength={66} validator={ADDRESS_VALIDATOR} 
                id='treasury-deposit-for' placeholder='Enter the Object address related to transfer flow'event={(t,v,d) => {
                    depositData.for = v;
                    setDepositData({...depositData});
                }}/>

                <SettingTitle title='For Guard' tips='The Guard related to deposit source, If any.'/>
                <SettingInputText value={depositData.for_guard ?? ''} maxlength={66} validator={ADDRESS_VALIDATOR} 
                id='treasury-deposit-guard' placeholder='Enter the Guard address related to transfer flow' event={(t,v,d) => {
                    depositData.for_guard = v;
                    setDepositData({...depositData});
                }}/>
                <div style={{display:'flex'}}>
                  { Permission.HasPermission(answer, PermissionIndex.treasury_deposit, true)?.has && <div style={{marginRight:'1em'}}>
                    <SettingLaunch text={deposit_guard ? 'Deposit with Permission' : 'Deposit'} event={(t,v,d) => {
                      onDeposit('perm');
                    }}
                  /></div>}
                  { deposit_guard && <SettingLaunch text={Permission.HasPermission(answer, PermissionIndex.treasury_deposit, true)?.has ? 'Deposit with Guard': 'Deposit'} event={(t,v,d) => {
                      onDeposit('guard');
                  }}
                  />}
                </div>
             </>}
            </DialogContent>
        </Dialog>
        <SettingWithdraw open={showWithdraw !== undefined} coinType={coinType} withdrawGuards={showWithdraw==='guard' ? withdraw_guards.map(v => {
            return {guard:v.guard, info:coinType !== 'loading' ? (ResolveBalance(v.amount, coinType.decimals) + ' ' + coinType.symbol) : v.amount}
          }): []} balance={props.contents.fields?.balance} event={(t,v,d) => {
            if (t === 'close') setShowWithdraw(undefined)
            else if (t === 'value') {
              const param = v as WithdrawInfo;
              const txb = new TransactionBlock();
              const obj = WowokTreasury.From(txb, type, permission, id);
              const pid = generateRandomString(8);
              const g = withdraw_guards.find((v) => v.guard === param.guard);
              if (g?.amount) {
                props.guardcheck({id:pid, txb:txb, guards:[param.guard], 
                  handler:(id:string, txb:TransactionBlock, passport?:Passport)=> {
                    onWithdraw(obj, txb, BigInt(g.amount), param, pid, passport)
                }})
              } else {
                props.permissioncheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.treasury_withdraw], handler:
                  (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
                    if (id === pid) {
                      if (index.includes(PermissionIndex.treasury_withdraw)) {
                        onWithdraw(obj, txb, BigInt(param.amount), param, pid, passport)
                      };  
                    }
                  }
                });
              }
          }
          }}/>
        <Dialog disableRestoreFocus  open={showReceive}  fullWidth maxWidth='sm' TransitionComponent={Transition}
            keepMounted  onClose={(event, reason)=> { setShowReceive(false) }}>
            <DialogTitle sx={{textAlign:'center'}} > Receiving Payments 
                <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{ setShowReceive(false)}}> <CloseIcon /> </IconButton>   
            </DialogTitle>
            <DialogContent sx={{mb:'1em'}}>
                {receive.length === 0 && <><Typography  variant='body1' sx={{fontSize:'1.2em', }} >No unrecorded payments have been received.</Typography>
                <Button variant='outlined' sx={{mt:'2em', fontSize:'1.1em', float:'right'}} onClick={()=>setShowReceive(false)}>Close</Button></>}
                {receive.length > 0 && coinType !== 'loading' &&  <><Typography variant='body1' sx={{fontSize:'1.2em', }} >Now 
                  <span style={{fontSize:'1.1em', fontWeight:500, fontStyle:'italic', color:theme.palette.primary.main, paddingRight:'.4em', paddingLeft:'.4em'}}>{receive.length}</span> 
                    unrecorded payments have been received. <br/>Totaling 
                    <span style={{fontSize:'1.1em',fontWeight:500, fontStyle:'italic', color:theme.palette.primary.main,  paddingRight:'.4em', paddingLeft:'.4em'}}>{balance_receive} {coinType.symbol}</span> 
                    </Typography>
                    <Button variant='outlined' sx={{mt:'2em', fontSize:'1.1em', float:'right'}} onClick={() => {
                      const txb = new TransactionBlock();
                      const obj = WowokTreasury.From(txb, type, permission, id);
                      const pid = generateRandomString(8);
                      props.permissioncheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.treasury_receive], handler:
                        (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
                          try {
                            if (id === pid) {
                              if (index.includes(PermissionIndex.treasury_receive)) {
                                receive.forEach((v) => {
                                  obj.receive(v.payment, v.object, passport?.get_object());
                                })
                              };  
                              passport?.destroy(); // destroy passport
                              props.exe(id, txb);            
                            }
                          } catch (e) {
                            console.log(e)
                            enqueueSnackbar( 'Launch Failed', { variant: "error" });
                          }
                        }
                      });
                    }}>Entry</Button>
                </>} 
            
            </DialogContent>
        </Dialog>
    </Box>
)}