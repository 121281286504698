
import React, { useState, useEffect } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useTheme} from '@mui/material/styles';
import { Box, Select, MenuItem,  } from '@mui/material';
import { PermissionAnswer, PermissionIndex, Permission, Progress, PermissionIndexType, IsValidAddress, Passport} from 'wowok';
import { SettingTitle,  SettingLaunch, ADDRESS_VALIDATOR, SettingInputText, ENDPOINT_VALIDATOR,
    } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { TransactionBlock, } from 'wowok';
import { generateRandomString } from '../../../util';
import { make_permIconProp } from '../PermCheck';
export interface BasicData {
  context_rep?: string;
  task?: string;
}
  
export default function Basic (props:any) {
    //console.log(props)
    const id = props?.contents?.fields?.id?.id ?? '';
    const machine = props?.contents?.fields?.machine;
    const answer: PermissionAnswer | undefined = props?.perms;
    const [data, setData] = useState<BasicData>({
      context_rep:props?.contents?.fields?.context_repository, 
      task:props?.contents?.fields?.task});

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();

    const launch = async () => {      
      if (data.context_rep && !IsValidAddress(data.context_rep)) {
        enqueueSnackbar('Context Repository invalid', { variant: "error" });
        document.getElementById('repository')?.focus();
        return ;
      }
      if (data.task && !IsValidAddress(data.task)) {
        enqueueSnackbar('Task address invalid', { variant: "error" });
        document.getElementById('task')?.focus();
        return ;
      }

      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }
      if (!answer) {
        enqueueSnackbar('Permission error', { variant: "error" });
        return 
      }
      const txb = new TransactionBlock(); // new session
      const obj = Progress.From(txb, machine, answer?.object!, id);
      const pid = generateRandomString(8);
      const perms: PermissionIndexType[] = [];
      if (data.context_rep !== props?.contents?.fields?.context_repository && (data.context_rep || props?.contents?.fields?.context_repository)) {
        perms.push(PermissionIndex.progress_context_repository);
      };
      if (data.task && !props?.contents?.fields?.task) {
        perms.push(PermissionIndex.machine_endpoint);
      };
      props.permissioncheck({id:pid, txb:txb, answer:answer, index:perms, handler:
        (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
          try {
            if (id === pid) {
              if (index.includes(PermissionIndex.progress_context_repository)) {
                obj.set_context_repository(data.context_rep, passport?.get_object());
              };  
              if (index.includes(PermissionIndex.progress_bind_task)) {
                if (data.task) obj.bind_task(data.task, passport?.get_object());
              }     
              passport?.destroy(); // destroy passport
              props.exe(pid, txb);
            }
          } catch (e) {
            console.log(e)
            enqueueSnackbar( 'Launch Failed', { variant: "error" });
          }
        }
      });
    }
    
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
          <SettingTitle title='Progress context Repository' tips='Store the consensus data for this Progress.'
            perm={make_permIconProp(answer, PermissionIndex.progress_context_repository)}/>
          <SettingInputText placeholder='Enter Progress context Repository address' 
                validator={ADDRESS_VALIDATOR} maxlength={66} value={data.context_rep} 
                noPerm={Permission.HasPermission(answer, PermissionIndex.progress_context_repository, true)?.has === false}
                  id='repository' event={(type, value, id) => {
                    data.context_rep = value;
                    setData({...data})
          }}/>
                    
          <SettingTitle title='Progress task id' tips='Task, eg. Order-id that Progress works for.'
            perm={make_permIconProp(answer, PermissionIndex.progress_bind_task)}/>
          <SettingInputText placeholder='Enter task address'  disabled={Boolean(data.task)}
            noPerm={Permission.HasPermission(answer, PermissionIndex.progress_bind_task, true)?.has === false}
              validator={ADDRESS_VALIDATOR} maxlength={66} value={data.task} 
                id='task' event={(type, value, id) => {
                  data.task = value;
                  setData({...data})
          }}/>
   
             <SettingLaunch text='Launch' event={(t) => {
              if (t === 'click') launch();
          }}/>
        </Box>
    );  
  }