
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box } from '@mui/material';
import { PermissionAnswer, Arbitration as WowokArbitration, Permission, PermissionIndex, PermissionIndexType, Passport} from 'wowok';
import { SettingSwitch, } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { TransactionBlock, } from 'wowok';
import { generateRandomString } from '../../../util';
import { make_permIconProp } from '../PermCheck';


export default function Status (props:any) {
    const id = props?.contents?.fields?.id?.id ?? '';
    const permission = props?.contents?.fields?.permission;
    const answer: PermissionAnswer | undefined = props?.perms;
    const type = WowokArbitration.parseObjectType(props?.type);

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();

    const launch = async (perm:PermissionIndexType , param:any) => {      
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }
           
        const txb = new TransactionBlock();
        const obj = WowokArbitration.From(txb, type, permission, id);
        const pid = generateRandomString(8);
        props.permissioncheck({id:pid, txb:txb, answer:answer, index:[perm], handler:
            (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
            try {
                if (id === pid) {
                    if (index.includes(perm)) {
                        if (perm === PermissionIndex.arbitration_pause) { //@ use new token type
                            obj.pause(param, passport?.get_object())
                        } 
                    };    
                    passport?.destroy(); // destroy passport
                    props.exe(pid, txb);
                }
            } catch (e) {
                console.log(e)
                enqueueSnackbar( 'Launch Failed', { variant: "error" });
            }}
        });
    }
    
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>

            {<SettingSwitch  title='Allowing the creation of Arb'  checked={!props?.contents?.fields?.bPaused} 
                noPerm={Permission.HasPermission(answer, PermissionIndex.arbitration_pause, true)?.has === false}
                perm={make_permIconProp(answer, PermissionIndex.arbitration_pause)}
                event={(t, v, d) => {
                if (t === 'check') {
                    launch(PermissionIndex.arbitration_pause, !(v as boolean)); 
                }}}
                tips='Allow or prohibit the creation of arbitration orders.'
            />}
        </Box>
    );  
  }