import React, { useState, useEffect } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { PermissionAnswer, Protocol, Service as WowokService, CoinTypeInfo, ResolveBalance, IsValidEndpoint, 
  PermissionIndex, PermissionIndexType, Permission, IsValidU64, MAX_ENDPOINT_LENGTH, Passport,
} from 'wowok';
import { Box, IconButton, Tooltip, Button, Dialog, DialogTitle, DialogContent, Tabs, Tab, } from "@mui/material";
import MaterialTable, {MTableToolbar} from "material-table";
import { grey } from '@mui/material/colors';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { SettingInputText, ENDPOINT_VALIDATOR, Validator,  SettingTitle, U64_LENGTH, SettingTitle2,
  U64_VALIDATOR, SettingCoinInput, SettingLaunch} from '../Settings';
  import { TransactionBlock, } from 'wowok';
  import { generateRandomString } from '../../../util';
import { make_permIconProp } from '../PermCheck';
import { PermIcon } from '../../util/Icons';
import { Transition } from '../../util/Common';
import { a11yProps, Pannel } from '../../util/Common';

export default function Item (props:any) {
  //console.log(props)
  const id = props?.contents?.fields?.id?.id ?? '';
  const type = WowokService.parseObjectType(props?.type);
  const permission = props?.contents?.fields?.permission;
  const answer: PermissionAnswer | undefined = props?.perms;

  const wallet = useWallet(); 
  const { enqueueSnackbar } = useSnackbar();
  
  let len = props.dynamic.length;
  len = len >= 20 ? 20 : (len <= 5 ? 5 : 10);
  
  const t = Protocol.Instance().coinTypeInfo(type, (res) => {
    if (res.type === type) {
      setCoinType(coinType);      
    }
  });
  
  const IsItemNameValid = (name:string) : boolean => {
    return props?.dynamic?.find((v:any)=>v.name.value === name) === undefined
  }
  const [coinType, setCoinType] = useState<CoinTypeInfo | undefined>( t === 'loading' ? undefined : t);
  const [edit, setEdit] = useState<EditProp>({data:undefined, price:'', inventory:'', inventory_add:'', inventory_reduce:'', tab:0});
  const [edPoint, setEdPoint] = useState<EndpointProp>({data:undefined, bItemValue:true, itemValue:'', serviceValue:''});
  const [newItem, setNewItem] = useState<NewProp>({price:'', inventory:'', name:'', endpoint:'', open:false});
  const tips = coinType ? (Math.pow(10, coinType.decimals)  + ' = 1 ' + coinType.symbol) : '';
  const price_placeholder = (coinType && coinType?.decimals > -1 && coinType?.symbol)? ((Math.pow(10, coinType.decimals).toString()) +' = 1 '+coinType.symbol):'Enter price';
  const price_tips = (coinType && coinType.decimals > -1 && coinType.symbol)? (coinType.symbol + ' decimals: ' + coinType.decimals) : '';
  const endpoint_placeholder = 'Enter ipfs://... or https://... about item details';
  const nameValidator:Validator = {err:'Item already existed', validator:IsItemNameValid};

  const launch = async(op:'new' | 'delete' | 'price' | 'inventory' | 'add inventory' | 'reduce inventory' | 'endpoint', value:any) => {
    if (!wallet.connected) {
      enqueueSnackbar('Please login wallet', { variant: "error" });      
      document.getElementById('header-wallet-cmd')?.click();
      return ;
    }

    const txb = new TransactionBlock(); // new session
    const obj = WowokService.From(txb, type, permission, id);
    const pid = generateRandomString(8);
    var perm:PermissionIndexType = PermissionIndex.service_sales;

    if (perm === undefined) { return ; }
    props.permissioncheck({id:pid, txb:txb, answer:answer, index:[perm], handler:
      (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
        try {
          if (id === pid && index.includes(PermissionIndex.service_sales)) {
            if (op === 'new') {
              const v = value as NewProp;
              obj.add_sales([{item:v.name, price:v.price, stock:v.inventory, endpoint:v.endpoint}], false, passport?.get_object());
            } else if (op === 'delete') {
              obj.remove_sales([value], passport?.get_object());
            } else if (op === 'endpoint') {
              obj.set_sale_endpoint(value?.name, value?.value, true, passport?.get_object());
            } else if (op === 'price') {
              const v = value as EditProp;
              obj.set_price(v?.data.name,  BigInt(v.price), true, passport?.get_object());
            } else {
              const v = value as EditProp;
              if (op === 'add inventory') {
                obj.add_stock(v?.data.name,  BigInt(v.inventory_add), true, passport?.get_object());
              } else if (op === 'reduce inventory') {
                obj.reduce_stock(v?.data.name,  BigInt(v.inventory_reduce), true, passport?.get_object());
              } else if (op === 'inventory') {
                obj.set_stock(v?.data.name,  BigInt(v.inventory), true, passport?.get_object());
              }
            } 
            passport?.destroy(); // destroy passport
            props.exe(pid, txb);
          }
        } catch (e) {
          console.log(e)
          enqueueSnackbar( 'Launch Failed', { variant: "error" });
        }
      }
    });
  }

    const handleEndpoint = (data:any, serviceValue:string, itemValue:string) => {
      setEdPoint({data:data, serviceValue:serviceValue, itemValue:itemValue, bItemValue:!serviceValue ? true: false})
    }

    return (
      <Box className='noboxshadow' sx={{padding:'0 2em'}}>
          <MaterialTable title=''
          columns={[
            { title: '', field: 'index', type: 'numeric', align:'left', width:'1%', cellStyle: {paddingRight: 0}, render:(data, type) => {
                return <div style={{fontStyle:'italic', color:grey[400], fontWeight:200}}>{data.index}</div>
            }},

            { title: 'Sale Item', field: 'name', render:(data, type) => {
                //console.log(data.endpoint);
                const url = WowokService.endpoint(props?.contents?.fields?.endpoint, data.endpoint, data.name);
                if (url) {
                  return (<div className="cmdText" style={{display:'flex', alignItems:'center', cursor:'pointer'}}>
                        <a color='black' className="cmdText" target="_blank" href={url} rel="noreferrer">{data.name} </a>
                        <Tooltip title={<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                          <span>Click Setting Endpoint</span> 
                          <PermIcon {...make_permIconProp(answer, PermissionIndex.service_sales)} />
                          </div>}  placement='right' arrow> 
                          <span>
                          <IconButton 
                            disabled={Permission.HasPermission(answer, PermissionIndex.service_sales, true)?.has === false}
                            onClick={()=> {
                              handleEndpoint(data, props?.contents?.fields?.endpoint? url : '', data.endpoint??'');
                          }}>
                            <LinkIcon sx={{color:grey[400], height:'.6em!important', width:'.6em!important', transform:'rotateZ(-20deg)'}}/>
                          </IconButton></span>
                        </Tooltip>
                      </div>)
                } else {
                  return (<div style={{display:'flex', alignItems:'center', cursor:'pointer'}}>{data.name}
                      <Tooltip title={<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                          <span>Click Setting Endpoint</span> 
                          <PermIcon {...make_permIconProp(answer, PermissionIndex.service_sales)} />
                          </div>} placement='right' arrow>
                          <span>
                        <IconButton onClick={()=>{
                            handleEndpoint(data, props?.contents?.fields?.endpoint??'', data.endpoint??'');
                        }}>
                          <LinkOffIcon sx={{color:grey[400], height:'.6em!important', width:'.6em!important', transform:'rotateZ(-40deg)'}}/>
                        </IconButton></span>
                      </Tooltip>
                  </div>)
                }
            }},
            { title: 'Price', field: 'price', cellStyle: {minWidth:'10em'}, render:(data, t) => {
              const p = coinType ? (ResolveBalance(data.price, coinType.decimals) + ' ' + coinType.symbol) : data.price;
              return (
                <div style={{display:'flex', alignItems:'center',justifyContent:'flex-end'}}>
                  <div>{p}</div>
                </div>
              )
            }},
            { title: 'Inventory', field: 'inventory', cellStyle: {paddingRight: 2, paddingLeft:2}, render:(data, type) => {
              return (<div style={{textAlign:'center'}}>{data.inventory}</div>)
            }},
            { title:'', field:'operation', cellStyle: {paddingRight: 8, paddingLeft:2}, render:(data, t) => {
                return (<div style={{display:'flex', justifyContent:'flex-end'}}>
                  <Tooltip title='Edit' arrow>  
                    <IconButton sx={{ml:'.4em', minHeight: 0, minWidth: 0, padding: '4px'}}>
                      <EditIcon  className='cmdText' sx={{color:grey[500], height:'.7em!important', width:'.7em!important'}} onClick={() => {
                        edit.data = data;
                        edit.price = data.price;
                        edit.inventory = data.inventory;
                        setEdit({...edit});
                  }}/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                    <span>Delete</span> 
                    <PermIcon {...make_permIconProp(answer, PermissionIndex.service_sales)} />
                    </div>} arrow>
                    <span>
                    <IconButton sx={{ml:'.2em', minHeight: 0, minWidth: 0, padding: '4px'}}  
                      disabled={Permission.HasPermission(answer, PermissionIndex.service_sales, true)?.has === false}
                      onClick={() => {
                        launch('delete', data.name);
                    }}>
                      <DeleteIcon  className='cmdText' sx={{color:grey[500], height:'.7em!important', width:'.7em!important'}} />
                    </IconButton></span>
                  </Tooltip> 
                </div>)
            }},
            { title: '', field: 'endpoint',  align:'left', width:'1%', hidden:true
            },
          ]}
          data={props?.fields?.map((v:any, i:number) => {
            return {index:i+1, name:v.data.content.fields.name, endpoint:v.data.content.fields.value.fields.endpoint, 
              price:v.data.content.fields.value.fields.price, inventory:v.data.content.fields.value.fields.stock}
          })}   
          options={{padding: "dense", 
              pageSize: len, 
              pageSizeOptions: [5, 10, 20],}}
          actions={[]}
          components={{
            Header: (props) => {
              const tips = <div>{type} <br/> {'token decimals: ' + (coinType ? coinType.decimals.toString() : 'unknown' )}</div>;
              return (<TableHead {...props} style={{}}>
                <TableRow>
                  <TableCell variant='head'>{props.columns[0].title}</TableCell>
                  <TableCell variant='head'>
                    <div style={{display:'flex'}}>
                      <div>{props.columns[1].title}</div>
                      <div><Tooltip title='Service Endpoint for item details.' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em!important', height:'0.6em!important', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head' >
                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                      <div>{props.columns[2].title}</div>
                      <div><Tooltip title={tips} arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em!important', height:'0.6em!important', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head' align='right'>
                    <div style={{}}> {props.columns[3].title}</div>
                    </TableCell>
                  <TableCell variant='head' align='right'>{props.columns[4].title}</TableCell>
                </TableRow>
              </TableHead>)
            }, Toolbar: props => (
              <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Button variant='contained' disabled={Permission.HasPermission(answer, PermissionIndex.service_sales, true)?.has === false}
                sx={{ml:'1em', textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
                onClick={ () => {
                  setNewItem({open:true, name:'', price:'', inventory:'', endpoint:''});
                }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                  <span>+ Add Item</span> 
                  <PermIcon {...make_permIconProp(answer, PermissionIndex.service_sales)} />
                  </div>}</Button>
                  <MTableToolbar {...props} />
              </div>
          ),
          }}
        />     
        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus 
            open={edit.data ? true:false} id='service-item-edit'
            style={{}}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>{edit.data = undefined; setEdit({...edit})}}
            >
            <DialogTitle sx={{textAlign:'center'}} > {'Edit Item: ' + edit?.data?.name}
                <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{edit.data = undefined; setEdit({...edit})}}> <CloseIcon /> </IconButton>   
            </DialogTitle>
                <DialogContent >
                    <Tabs value={edit.tab} onChange={(event: React.SyntheticEvent, newValue: number)=>{edit.tab=newValue; setEdit({...edit})}} sx={{mb:'1em'}}>
                        <Tab label="Set Price"  {...a11yProps(0)} sx={{textTransform:'none'}}/>
                        <Tab label="Set Inventory"   {...a11yProps(1)} sx={{textTransform:'none'}}/>
                        <Tab label="Add Inventory"   {...a11yProps(2)} sx={{textTransform:'none'}}/>
                        <Tab label="Reduce Inventory"   {...a11yProps(3)} sx={{textTransform:'none'}}/>
                    </Tabs>
                <Pannel value={edit.tab} index={0}>
                  <div style={{marginTop:'2em'}}>
                    <SettingTitle required title='Set Price' perm={make_permIconProp(answer, PermissionIndex.service_sales)}/>
                    <SettingCoinInput autoFocus placeholder={tips} decimals={coinType?.decimals ?? -1} id='service-edit-price'
                      noPerm={Permission.HasPermission(answer, PermissionIndex.service_sales, true)?.has === false}
                        symbol={coinType?.symbol ?? ''} value={edit.price} min='0' fullWidth event={(type, value, id) => {
                            edit.price = value;
                            setEdit({...edit});
                        }}/> 
                    <SettingLaunch text='Launch' event={(t,v,_) => {
                        if (!IsValidU64(edit.price)) {
                          enqueueSnackbar('Price invalid', { variant: "error" }); 
                          document?.getElementById('service-edit-price')?.focus();
                          return
                        }
                        launch('price', edit)
                    }} />
                  </div>
                </Pannel>
                <Pannel value={edit.tab} index={1}>
                  <div style={{marginTop:'2em'}}>
                    <SettingTitle required title='Set Inventory' perm={make_permIconProp(answer, PermissionIndex.service_sales)}/>
                    <SettingInputText autoFocus placeholder='Enter inventories' maxlength={U64_LENGTH} validator={U64_VALIDATOR}
                    noPerm={Permission.HasPermission(answer, PermissionIndex.service_sales, true)?.has === false} value={edit.inventory}
                    id='service-edit-inventory' type="number" event={(type, value, id) => {
                        edit.inventory = value;
                        setEdit({...edit});
                    }}/>
                    <SettingLaunch text='Launch' event={(t,v,_) => {
                        if (!IsValidU64(edit.inventory)) {
                          enqueueSnackbar('Inventories invalid', { variant: "error" }); 
                          document?.getElementById('service-edit-price')?.focus();
                          return
                        }
                        launch('inventory', edit)
                    }} />
                  </div>
                </Pannel>
                <Pannel value={edit.tab} index={2}>
                  <div style={{marginTop:'2em'}}>
                    <SettingTitle required title='Add Inventory' perm={make_permIconProp(answer, PermissionIndex.service_sales)}/>
                    <SettingInputText autoFocus placeholder='Enter inventories to add' maxlength={U64_LENGTH} validator={U64_VALIDATOR}
                    noPerm={Permission.HasPermission(answer, PermissionIndex.service_sales, true)?.has === false}
                    id='service-edit-inventory-add' value={edit.inventory_add} type="number" event={(type, value, id) => {
                        edit.inventory_add = value;
                        setEdit({...edit});
                    }}/>
                    <SettingLaunch text='Launch' event={(t,v,_) => {
                        if (!IsValidU64(edit.inventory_add)) {
                          enqueueSnackbar('Inentories added invalid', { variant: "error" }); 
                          document?.getElementById('service-edit-price')?.focus();
                          return
                        }
                        launch('add inventory', edit)
                    }} />
                  </div>
                </Pannel>
                <Pannel value={edit.tab} index={3}>
                  <div style={{marginTop:'2em'}}>
                    <SettingTitle required title='Reduce Inventory' perm={make_permIconProp(answer, PermissionIndex.service_sales)}/>
                    <SettingInputText autoFocus placeholder='Enter inventories to reduce' maxlength={U64_LENGTH} validator={U64_VALIDATOR}
                    noPerm={Permission.HasPermission(answer, PermissionIndex.service_sales, true)?.has === false}
                    id='service-edit-inventory-reduce' type="number" value={edit.inventory_reduce} event={(type, value, id) => {
                        edit.inventory_reduce = value;
                        setEdit({...edit});
                    }}/>
                    <SettingLaunch text='Launch' event={(t,v,_) => {
                        if (!IsValidU64(edit.inventory_reduce)) {
                          enqueueSnackbar('Inentories reduced invalid', { variant: "error" }); 
                          document?.getElementById('service-edit-price')?.focus();
                          return
                        }
                        launch('reduce inventory', edit)
                    }} />
                  </div>
                </Pannel>
                </DialogContent> 
      </Dialog> 
      <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus 
            open={edPoint.data ? true:false} id='service-item-endpoint'
            style={{}}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>{edPoint.data = undefined; setEdPoint({...edPoint})}}
            >
            <DialogTitle sx={{textAlign:'center'}} > {'Edit Endpoint: ' + edPoint?.data?.name}
                <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{edPoint.data = undefined; setEdPoint({...edPoint})}}> <CloseIcon /> </IconButton>   
            </DialogTitle>
                <DialogContent >
                <SettingTitle2 required title={'Endpoint'} checkbox='Individual Endpoint for Item' 
                    checked={edPoint.bItemValue} event={(type, value, id) => {
                    edPoint.bItemValue = value; setEdPoint({...edPoint});
                  }}/>
                  {edPoint.bItemValue && (<SettingInputText autoFocus placeholder={endpoint_placeholder} maxlength={MAX_ENDPOINT_LENGTH} 
                    maxRows={4} multiline
                    validator={ENDPOINT_VALIDATOR}  id='service-item-edpoint-item' value={edPoint.itemValue} event={(type, value, id) => {
                          edPoint.itemValue = value; setEdPoint({...edPoint})
                      }}/>)}
                  {!edPoint.bItemValue && edPoint.serviceValue && (<SettingInputText maxlength={MAX_ENDPOINT_LENGTH} 
                    disabled sx={{
                      color:grey[400],
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: '#A6008B',
                          },
                        }}
                      value={edPoint.serviceValue} multiline maxRows={4}/>)}
                  <div>
                      {!edPoint.bItemValue && <p className='cmdText' style={{fontSize:'0.9em', fontWeight:300, color:grey[500]}} onClick={() => {
                        window.open('/'+id+'?setting#1', '_blank')?.focus()
                      }}>{edPoint.serviceValue ? '*Use Serice Endpoint currently.' : 'Service Endpoint not set yet.'}</p>}
                  </div>
                  <button className='cmdButton' style={{marginTop:'1.5em'}} onClick={()=>{
                    if (edPoint.bItemValue && edPoint.itemValue && !IsValidEndpoint(edPoint.itemValue)) {
                      enqueueSnackbar('Endpoint invalid', { variant: "error" });
                      document.getElementById('service-item-edpoint-item')?.focus();
                      return ;
                    }

                    if (edPoint.bItemValue) {
                      launch('endpoint', {name: edPoint.data?.name, value: edPoint.itemValue});
                    } else {
                      launch('endpoint', {name: edPoint.data?.name, value:''});
                    }
                  }}>Launch</button>                    
              </DialogContent> 
      </Dialog> 
      <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus 
            open={newItem.open} id='service-item-new'
            style={{}}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>{newItem.open = false; setNewItem({...newItem})}}
            >
            <DialogTitle sx={{textAlign:'center'}} >New Sale Item
                <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{newItem.open = false; setNewItem({...newItem})}}> <CloseIcon /> </IconButton>   
            </DialogTitle>
                <DialogContent >
                <SettingTitle required title='Item name'/>
              <SettingInputText autoFocus placeholder='Enter new item name' maxlength={WowokService.MAX_ITEM_NAME_LENGTH/2} validator={nameValidator} 
                  id='ServiceNewItem-name' value={newItem.name} event={(type, value, id) => {
                      newItem.name = value;
                      setNewItem({...newItem});
                  }}/>
              <SettingTitle required title='Price' tips={price_tips}/>
              <SettingCoinInput placeholder={price_placeholder}  fullWidth decimals={props.decimals} symbol={props.token}
                  id='ServiceNewItem-price' type='number' value={newItem.price} event={(type, value, id) => {
                      newItem.price = value;
                      setNewItem({...newItem});
                  }}/>
              <SettingTitle required title='Inventory'/>
              <SettingInputText  placeholder='Enter inventory' maxlength={U64_LENGTH} validator={U64_VALIDATOR}
                  id='ServiceNewItem-inventory' type='number' value={newItem.inventory} event={(type, value, id) => {
                      newItem.inventory = value.toString();
                      setNewItem({...newItem});
                  }}/>
              <SettingTitle title='Endpoint'/>
                <SettingInputText placeholder={endpoint_placeholder}  maxlength={MAX_ENDPOINT_LENGTH}
                  validator={ENDPOINT_VALIDATOR}  value={newItem.endpoint} maxRows={4} multiline
                  id='ServiceNewItem-endpoint' event={(type, value, id) => {
                      newItem.endpoint = value;
                      setNewItem({...newItem});
                  }}/>
                <button className='cmdButton' style={{marginTop:'1.5em'}} onClick={()=>{
                  if (newItem.name === '' || !IsItemNameValid(newItem.name)) {
                      enqueueSnackbar('Item name invalid', { variant: "error" });   
                      document.getElementById('ServiceNewItem-name')?.focus();
                  } else if (newItem.price === '' || !IsValidU64(newItem.price)) {
                      enqueueSnackbar('Price invalid', { variant: "error" }); 
                      document.getElementById('ServiceNewItem-price')?.focus();
                  } else if (newItem.inventory === '' || !IsValidU64(newItem.inventory)) {
                      enqueueSnackbar('Inventory invalid', { variant: "error" }); 
                      document.getElementById('ServiceNewItem-iventory')?.focus();
                  } else if (newItem.endpoint?.length > 0 && !IsValidEndpoint(newItem.endpoint)) {
                      enqueueSnackbar('Endpoint invalid', { variant: "error" }); 
                      document.getElementById('ServiceNewItem-endpoint')?.focus();
                  } else {
                      launch('new', newItem);
                      newItem.open = false
                      setNewItem({...newItem});
                  }
                }}>Launch Item</button>          
              </DialogContent> 
      </Dialog> 
      </Box>
    );  
}
export interface NewProp {
  open: boolean;
  name: string;
  price: string;
  inventory : string;
  endpoint: string;
}
interface EditProp {
  data?: any;
  price: string;
  inventory: string;
  inventory_add: string;
  inventory_reduce: string;
  tab: number;
}

interface EndpointProp {
  data?: any;
  itemValue:string; // used to init old value (readonly)
  serviceValue: string;
  bItemValue:boolean;
}
