
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useTheme} from '@mui/material/styles';
import { Box } from '@mui/material';
import { PermissionAnswer, Machine, Permission, PermissionIndex, PermissionIndexType, Passport} from 'wowok';
import { SettingTitle,  SettingLaunch, ADDRESS_VALIDATOR, SettingTips, SettingAddressChips,
    } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { TransactionBlock, } from 'wowok';
import { generateRandomString } from '../../../util';
import { make_permIconProp } from '../PermCheck';

export default function Repository (props:any) {
    //console.log(props)
    const id = props?.contents?.fields?.id?.id ?? '';
  //const type = Repository.parseObjectType(props?.type);
    const [data, setData] = useState<string[]>(props?.contents?.fields?.consensus_repositories ?? []);
    const permission = props?.contents?.fields?.permission;
    const answer: PermissionAnswer | undefined = props?.perms;
    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    const launch = async (add:string[], remove:[]) => {      
      const txb = new TransactionBlock()
      const obj = Machine.From(txb, permission, id);
      const pid = generateRandomString(8);
      
      props.permissioncheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.machine_repository], handler:
        (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
          try {
            if (id === pid) {
              if (index.includes(PermissionIndex.machine_repository)) {
                obj.remove_repository(remove, undefined, passport?.get_object());
                add.forEach((v) => {
                  obj.add_repository(v, passport?.get_object());
                })
              };  
              passport?.destroy(); // destroy passport
              props.exe(id, txb);            
            }
          } catch (e) {
            console.log(e)
            enqueueSnackbar( 'Launch Failed', { variant: "error" });
          }
        }
      });
    }
    
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
          <SettingTips text='If there are no relevant repository, you can&nbsp;' command=' Build New Repository' to='/repository'/>
            <SettingTitle title='Repository' perm={make_permIconProp(answer, PermissionIndex.machine_repository)}
              tips='Storing and sharing machine data. Support multiple repositories.'/>
          <SettingAddressChips placeholder='Input address and press Enter'  autoFocus={true} 
            id='machine-repository' maxTags={100} maxTags_err='Max Repository count' noPerm={Permission.HasPermission(answer, PermissionIndex.machine_repository)?.has === false}
            initValue={data}    event={(type, value, id) => {
                if (type === 'value')  {
                    setData([...value])
                }        
            }}/>
            <SettingLaunch text='Launch' event={(t) => {
            if (t === 'click') {
              if (!wallet.connected) {
                enqueueSnackbar('Please login wallet', { variant: "error" });
                document.getElementById('header-wallet-cmd')?.click();
                return 
              }

              const add = data.filter((v)=>props?.contents?.fields?.consensus_repositories.find((i:string)=>i===v) === undefined);
              const remove = props?.contents?.fields?.consensus_repositories.filter((v:string)=>data.find((i)=>i===v) === undefined);

              if (add.length === 0 && remove.length === 0) {
                enqueueSnackbar('No Repository changed to launch', { variant: "success" });
                return 
              }

              launch(add, remove);
            }
            }}/>
        </Box>
    );  
  }