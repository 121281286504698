

import React, { useState, useEffect } from "react";
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { CoinTypeInfo, Protocol, ResolveBalance, Service as WowokService, Service_Discount_Type, Passport,
  MAX_U64, Customer_RequiredInfo, } from 'wowok';
import { Box, IconButton, Dialog, Tooltip, DialogTitle, DialogContent, Select, MenuItem} from "@mui/material";
import MaterialTable, {MTableToolbar} from "material-table";
import { grey } from '@mui/material/colors';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { SuiObjectData } from '@mysten/sui/client';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Address } from "../util/Address";
import { Transition, toLocaleStringWithMinutes } from '../util/Common';
import CloseIcon from '@material-ui/icons/Close';
import { SettingCoinInput, SettingEvent, SettingInputText, SettingLaunch, SettingTitle, U64_LENGTH, U64_VALIDATOR } from "../launch/Settings";
import { TransactionBlock, } from 'wowok';
import { generateRandomString } from '../../util';
import JSEncrypt from 'jsencrypt';
import { useTheme } from '@mui/material/styles';
import { useAccount, add_resource_launched } from "../util/Account";

interface BuyInfo {
  data: any;
  discount: string;
  inventory: string;
}

interface Require  {
  name: string;
  value: string;
}

const JSE = new JSEncrypt();

export default function ServiceSales(props:any) {
  //console.log(props)
  const id = props?.contents?.fields?.id?.id ?? '';
  const type = WowokService.parseObjectType(props?.type);
  const permission = props?.contents?.fields?.permission;
  const machine = props?.contents?.fields?.machine;
  const wallet = useWallet(); 
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const account = useAccount();

  const pubkey:string = props?.contents?.fields?.customer_required?.fields?.service_pubkey ?? '';
  let len = props.dynamic.length;
  len = len >= 20 ? 20 : (len <= 5 ? 5 : 10);

  const t = Protocol.Instance().coinTypeInfo(type, (res) => {
    if (res.type === type) {
      setCoinType(coinType);      
    }
  })
  const [coinType, setCoinType] = useState<CoinTypeInfo | undefined>(t==='loading'?undefined:t);
  const [buy, setBuy] = useState<BuyInfo | undefined>(undefined);
  const [requires, setRequires] = useState<Require[]>([]);
  useEffect(() => {
    setRequires(props?.contents?.fields?.customer_required?.fields?.customer_required_info.map((v:any) =>{
      return  {name:v, value:''}
    }) ?? []);
  }, [props?.contents?.fields?.customer_required?.fields?.customer_required_info])

  const requires_tips = 'The requires providing this information, which will be encrypted with Secret-Key and can only be seen by the service party.';
  const couldBuy = props?.contents?.fields?.bPublished === true && props?.contents?.fields?.bPaused === false;
  const buyTips = couldBuy ? (props?.contents?.fields?.buy_guard ? 
      (<div style={{display:'flex', alignItems:'center'}}>
          <span style={{marginRight:'.6em'}}>Buy through: </span>
          <Address address={props?.contents?.fields?.buy_guard} showType='Guard'/>
      </div>) : 'Buy now!') : 'Service Pending'

  const launch = async () => {
    if (!wallet.connected) {
      enqueueSnackbar('Please login wallet', { variant: "error" });
      document.getElementById('header-wallet-cmd')?.click();
      return ;
    }

    if (!buy) {
      enqueueSnackbar('Buy info invalid', { variant: "error" });
      return
    }

    const amount = BigInt(buy.data.price) * BigInt(buy.inventory) ;
    if (amount > MAX_U64) {
      enqueueSnackbar('Overflow MAX: ' + MAX_U64.toString(), { variant: "error" });
      return
    }

    JSE.setPublicKey(pubkey);
    var req : any = JSE.encrypt(JSON.stringify(requires.map((v=>v.name+' : '+v.value))));
    if (!req) {
      //enqueueSnackbar('Overflow MAX: ' + MAX_U64.toString(), { variant: "error" });
      req = '';
    }

    const txb = new TransactionBlock(); // new session
    const obj = WowokService.From(txb, type, permission, id);
    const pid = generateRandomString(8);

    props.guardcheck({id:pid, txb:txb, guards:props?.contents?.fields?.buy_guard ? [props?.contents?.fields?.buy_guard] : [], 
      handler:(id:string, txb:TransactionBlock, passport?:Passport) => {
        try {
          if (id === pid) {
            JSE.setPublicKey(pubkey);
            var req : any = JSE.encrypt(JSON.stringify(requires.map(v=>v.name+' : '+v.value)));
            if (req === false) req = '';

            const info: Customer_RequiredInfo = {customer_pubkey:pubkey, customer_info_crypt:req};
            const res = obj.buy([{item:buy.data.name, max_price:buy.data.price, count:buy.inventory}], 
              txb.splitCoins(txb.gas, [txb.pure.u64(amount)]), buy.discount?txb.object(buy.discount):undefined, 
              machine?txb.object(machine):undefined, info, passport?.get_object());   
            const added = [res.order]; 
            if (res.progress)   added.push(res.progress)
            add_resource_launched(account, txb, added); 
            passport?.destroy(); // destroy passport
            props.exe(pid, txb, 'order::Order');
          }
        } catch (e) {
          console.log(e)
          enqueueSnackbar( 'Launch Failed', { variant: "error" });
        }
      }
    });
  }

    return (
      <Box className='noboxshadow' >
          <MaterialTable title=''
          columns={[
            { title: '', field: 'index', type: 'numeric', align:'left', width:'1%', cellStyle: {paddingRight: 0}, render:(data, type) => {
                return <div style={{fontStyle:'italic', color:grey[400], fontWeight:200}}>{data.index}</div>
            }},

            { title: 'Item', field: 'name', render:(data, type) => {
                const url = WowokService.endpoint(props?.contents?.fields?.endpoint, data.endpoint, data.name);
                if (url) {
                  return (<div className="cmdText" style={{display:'flex', alignItems:'center', cursor:'pointer'}}>
                        <a color='black' className="cmdText" target="_blank" href={url} rel="noreferrer" >{data.name} </a>
                        <Tooltip title={url}  placement='right' arrow>
                            <IconButton className="cmdText" sx={{padding:'3px'}} onClick={()=>window.open(url)?.focus()}>
                              <LinkIcon sx={{color:grey[400], height:'.6em!important', width:'.6em!important', transform:'rotateZ(-20deg)', ml:'.2em'}} />
                            </IconButton>
                        </Tooltip>
                      </div>)
                } else {
                  return (<div style={{display:'flex', alignItems:'center', cursor:'pointer'}}>{data.name}
                      <Tooltip title='Service endpoint not set yet.' placement='right' arrow>
                          <LinkOffIcon sx={{color:grey[400], height:'.6em!important', width:'.6em!important', transform:'rotateZ(-40deg)', ml:'.2em'}}/>
                      </Tooltip>
                  </div>)
                }
            }},
            { title: 'Price', field: 'price', cellStyle: {minWidth:'10em'},  render:(data, t) => {
              const p = coinType ? (ResolveBalance(data.price, coinType.decimals)+' ' + coinType.symbol) : data.price;
              return (
                <div style={{display:'flex', alignItems:'center',justifyContent:'flex-end'}}>
                  <div>{p}</div>
                </div>
              )
            }},
            { title: 'Inventory', field: 'inventory', cellStyle: {paddingRight: 2, paddingLeft:2, textAlign:'center'}, render:(data, type) => {
              return (<div style={{display:'flex', alignItems:'center',justifyContent:'center'}}>
                <div>{data.inventory}</div> 
              </div>)
            }},
            { title:'', field:'operation', render:(data, t) => {
              const color = couldBuy ? {color:theme.palette.primary.main} : {color:grey[400]} 
                return (<div style={{display:'flex', justifyContent:'flex-end'}}>
                  <Tooltip title={buyTips} arrow placement="right">  
                    <span>
                    <IconButton sx={{ml:'.4em', minHeight: 0, minWidth: 0, }} disabled={!couldBuy}>
                        <ShoppingCartIcon   className={couldBuy ? 'cmdText' : undefined} sx={{...color, height:'0.8em!important', width:'0.8em!important'}} onClick={() => {
                          if (!wallet.connected) {
                            enqueueSnackbar('Please login wallet', { variant: "error" });
                            document.getElementById('header-wallet-cmd')?.click();
                            return ;
                          }
                          setBuy({data:data, inventory:'1', discount:''});
                        }}/>
                      </IconButton></span>
                  </Tooltip>
                </div>)
            }},
            { title: '', field: 'endpoint',  align:'left', width:'1%', hidden:true
            },
          ]}
          data={props?.fields?.map((v:any, i:number) => {
            return {index:i+1, name:v.data.content.fields.name, endpoint:v.data.content.fields.value.fields.endpoint, 
              price:v.data.content.fields.value.fields.price, inventory:v.data.content.fields.value.fields.stock}
          })}   
          options={{padding: "dense", 
              pageSize: len, 
              pageSizeOptions: [5, 10, 20],}}
          actions={[]}
          components={{
            Header: (props) => {
              const tips = <div>{type} <br/> {'token decimals: ' + (coinType?coinType.decimals.toString():'unknown')}</div>;
              return (<TableHead {...props} >
                <TableRow>
                  <TableCell variant='head'>{props.columns[0].title}</TableCell>
                  <TableCell variant='head'>
                    <div style={{display:'flex'}}>
                      <div>{props.columns[1].title}</div>
                      <div><Tooltip title='Service endpoint for item details.' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em!important', height:'0.6em!important', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head' >
                    <div style={{display:'flex', justifyContent:'center'}}>
                      <div>{props.columns[2].title}</div>
                      <div><Tooltip title={tips} arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em!important', height:'0.6em!important', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head' align='right'>
                    <div style={{marginRight:'1em'}}> {props.columns[3].title}</div>
                    </TableCell>
                  <TableCell variant='head' align='right'>{props.columns[4].title}</TableCell>
                </TableRow>
              </TableHead>)
            },
          }}
        />     
        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus 
          open={buy ? true:false}
          style={{}}
          TransitionComponent={Transition}
          keepMounted
          onClose={()=>{setBuy(undefined)}}
          >
          <DialogTitle sx={{textAlign:'center', display:'flex'}} > <div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', width:'90%'}}> {'Buy: ' + buy?.data?.name}</div>
              <IconButton sx={{ml:'auto', marginTop:'-.2em'}} onMouseDown={()=>{setBuy(undefined)}}> <CloseIcon /> </IconButton>   
          </DialogTitle>
              <DialogContent >
                <SettingTitle title="Current Price" />
                <SettingCoinInput fullWidth value={buy?.data?.price ?? ''} decimals={coinType?.decimals ?? -1} symbol={coinType?.symbol ?? ''} disabled event={()=>{}}/>
                <SettingTitle width={'100%'} titleWidth="100%" title={<div style={{display:'flex', alignItems:'center'}}>
                  <div style={{}}>Inventories to Buy</div>
                  <div style={{marginLeft:'auto', color:grey[400], fontWeight:400, paddingRight:'.4em'}}>{"Current Inventory: " + (buy?.data?.inventory ?? '0')}</div>
                </div>} required/>
                <SettingInputText  placeholder='Inventories to buy' maxlength={U64_LENGTH} validator={U64_VALIDATOR}
                  id='ServiceNewItem-inventory' type='number' value={buy?.inventory ?? '1'} event={(type, value, id) => {
                    if (buy) {
                      buy.inventory = value;
                      setBuy({...buy});                      
                    }
                  }}/>
                <SettingTitle title="Discount" />
                <Discounts {...props} event={(t:any,v:any,_:any) => {
                  if (t === 'value' && buy) {
                    buy.discount = v;
                    setBuy({...buy})
                  }
                }}/>
                { requires.map((v) => {
                  return (<>
                    <SettingTitle title={v.name.toUpperCase()} tips={requires_tips} required/>
                    <SettingInputText maxlength={128} value={v.value} err_empty={"Please enter " + v.name.toUpperCase()} 
                      event={(t, value, _) => { v.value = value; setRequires([...requires])}}/>
                  </>)
                })
                }
                <SettingLaunch text="Buy" event={(t,v,_) => {
                  for(let i = 0; i <  requires.length; ++i) {
                    if (!requires[i].value) {
                      enqueueSnackbar(requires[i].name.toUpperCase() + ' empty.', { variant: "error" });
                      return 
                    }
                  }
                  launch();
                }}/>
              </DialogContent>
        </Dialog>
      </Box>
    );  
}

const EMPTY_DISCOUNT = "I don't want to use the discount now";
type DiscountsProf = {
  event: SettingEvent;
  id?: string;
} & any;

const Discounts = (props:DiscountsProf) => {
  const id = props?.contents?.fields?.id?.id ?? '';
  const wallet = useWallet();
  const [discounts, setDiscounts] = useState<(SuiObjectData|undefined|null)[]>([]);

  useEffect(() => {
      // 获取折扣券
      if (wallet?.address) {
        WowokService.DiscountObjects(wallet.address, (owner:string, objects:(SuiObjectData | null | undefined)[]) => {
          setDiscounts(objects.filter((v)=>(v?.content as any)?.fields?.service === (props?.contents?.fields?.id?.id ?? '')));
        })
      }
    }, [wallet.address, props?.contents?.fields?.id?.id])

  if (discounts.length === 0) { 
    return (
      <Select fullWidth defaultValue={'No Discount objects owned yet'}>
        <MenuItem value={'No Discount objects owned yet'}>No Discount objects owned yet</MenuItem>
      </Select>
  )}
   return (
    <Select fullWidth defaultValue={EMPTY_DISCOUNT} onChange={(e) => {
      props.event('value', e.target.value, props?.id);
    }} renderValue={(value)=> {
      if (value === EMPTY_DISCOUNT) return (<div>{EMPTY_DISCOUNT}</div>);
      const v = discounts?.find((i) => i?.objectId === value);
      if (!v) return null;
      const d = ((v.content as any).fields.type === Service_Discount_Type.minus ? ((v.content as any).fields.off) : ((v.content as any).fields.off+'%'));
      const term = (v.content as any).fields.price_greater;
      const time_end = toLocaleStringWithMinutes(new Date(parseInt((v.content as any).fields.time_end)));

      return (<div style={{display:'flex'}}>
            <Address address={v?.objectId} showType={'discount'} />
            <div style={{marginLeft:'auto', color:grey[600]}}>
              <span>Off:&nbsp; </span><span style={{fontWeight:500, marginRight:'2em'}}>{d}</span>
              <span>Terms:&nbsp;</span><span style={{fontWeight:500, marginRight:'2em'}}>{term}</span>
              <span>Expires:&nbsp;</span><span style={{fontWeight:500}}>{time_end}</span>
            </div>
      </div>)
    }}>
        <MenuItem value={EMPTY_DISCOUNT}>{EMPTY_DISCOUNT}</MenuItem>
        {
        discounts?.map((v) => {
          if (!v || !v?.objectId) return null;

          const d = ((v.content as any).fields.type === Service_Discount_Type.minus ? ((v.content as any).fields.off) : ((v.content as any).fields.off+'%'));
          const term = (v.content as any).fields.price_greater;
          const date = new Date(); date.setTime(parseInt((v.content as any).fields.time_end));
          const time_end = toLocaleStringWithMinutes(new Date(parseInt((v.content as any).fields.time_end)));

          return (<MenuItem key={v?.objectId} value={v?.objectId} sx={{display:'flex'}}>
            <Address address={v?.objectId} showType={'discount'} />
            <div style={{marginLeft:'auto', color:grey[600]}}>
              <span style={{fontWeight:300}}>Off:&nbsp; </span><span style={{fontWeight:500, marginRight:'2em'}}>{d}</span>
              <span style={{fontWeight:300}}>Terms:&nbsp;</span><span style={{fontWeight:500, marginRight:'2em'}}>{term}</span>
              <span style={{fontWeight:300}}>Expires:&nbsp;</span><span style={{fontWeight:500}}>{time_end}</span>
            </div>
          </MenuItem>)
        })
      }
    </Select>
  )
}

