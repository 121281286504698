import React, { useEffect, useState } from 'react';
import '../../css/Common.css';
import { Paper,  Tooltip,  } from '@mui/material';
import Typography from '@mui/material/Typography';
import { PermissionIndex, PermissionAnswer, Demand as WowokDemand, Protocol, PermissionIndexType, Passport as WowokPassport} from 'wowok';
import { green, grey } from '@mui/material/colors';
import MaterialTable, {MTableToolbar} from "material-table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { PaginatedEvents, SuiEvent } from '@mysten/sui/client';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { Address } from '../util/Address';
import { TransactionBlock } from 'wowok';
import { generateRandomString } from '../../util';

export default function Events(props:any) {
    const id = props?.contents?.fields?.id?.id ?? '';
    const type = WowokDemand.parseObjectType(props?.type);
    const permission = props?.contents?.fields?.permission;
    const { enqueueSnackbar } = useSnackbar();
    const answer: PermissionAnswer | undefined = props?.perms;
    const [got, setGot] = useState(-1);
    const wallet = useWallet();
    const events = props.events as PaginatedEvents | null;

    const launch = async(service:string) => {
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }

      const txb = new TransactionBlock();
      const obj = WowokDemand.From(txb, type, permission, id);
      const pid = generateRandomString(8);
      props.permissioncheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.demand_yes], handler:
        (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:WowokPassport) => {
          try {
            if (id === pid) {
              if (index.includes(PermissionIndex.demand_yes)) {
                obj.yes(service, passport?.get_object());
              };  
              passport?.destroy(); // destroy passport
              props.exe(id, txb);            
            }
          } catch (e) {
            console.log(e)
            enqueueSnackbar( 'Launch Failed', { variant: "error" });
          }
        }
      });
    }

    const Yes2Front = () : SuiEvent[]  => {
        const i = events?.data?.findIndex((v) => (v.parsedJson as any).service === props?.contents?.fields?.yes);
        if (i !== -1) {
            const f = events!.data!.splice(i!, 1); // 移除元素
            events!.data!.unshift(f[0]);  // 添加到首位
        }
        return events!.data;
    }

    let len = events ? events.data.length : 0;
    len = len >= 20 ? 20 : (len <= 5 ? 5 : 10);
    
    return (
        <Paper className='noboxshadow' sx={{mt:'0'}} >
            <MaterialTable  title=''
            columns={[
              { title: '', field: 'index', type: 'numeric', align:'left', width:'1%', render:(data, type) => {
                  return <div style={{fontStyle:'italic', color:grey[400], fontWeight:200}}>{data.index}</div>
              }},
              { title: 'Service', field: 'service', cellStyle:{paddingLeft:0}, render:(data, t) => {
                const bounty = props?.contents?.fields?.yes === data.service;
                if (bounty && got===-1) setGot(data.index);
                return (<div style={{display:'flex', alignItems:'center'}}>
                    <Address address={data.service} showType={true}/>
                    { !props?.contents?.fields?.yes &&
                        <Tooltip arrow placement='right' title={ data.index === got ? 'GOT the bounty by Service presenter!' : 'Pick this service, By your Demand permission.'}>
                            <span onClick={()=> {
                                launch(data.service);
                            }}>{ <MilitaryTechIcon sx={{width:'.8em', height:'.8em', ml:'.2em',  color:grey[400]}} className='cmdText'/>}</span>
                        </Tooltip>
                    }
                    { props?.contents?.fields?.yes && data.index === got &&
                        <Tooltip arrow placement='right' title={'Presenter GOT the bounty!'}>
                            <span>{ <MilitaryTechIcon sx={{width:'.8em', height:'.8em', ml:'.2em', color:'#A6008B'}} />}</span>
                        </Tooltip>
                    }
                  </div>)
              }},
              { title: 'Presenter', field: 'presenter', render:(data, type) => {
                  return (<div >
                    <Address address={data.presenter}/>
                  </div>)
              }},
              { title: 'Recommendation', field: 'tips', width:'6em',render:(data, type) => {
                return (<div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                    <span >{data.tips} </span>
                </div>)
              }},
              { title: 'Time', field: 'time', render:(data, type) => {
                const ts = new Date(parseInt(data.time ?? ''));
                
                return (<div>
                    {ts.toLocaleString()}
                </div>)
              }},
              { title: 'Tx', field: 'tx',  align:'left', width:'6em', render:(data, type) => {
                return (<div>
                    <Tooltip title={data.tx}>
                       <span className='cmdText' onClick={()=>window.open(Protocol.Instance().explorerUrl(data.tx, 'txblock'), '_blank')?.focus()}>
                        {data.tx.slice(0, 6)} 
                        </span>
                    </Tooltip>
                </div>)
              }},
            ]}
            data={events ? Yes2Front()?.map((v, i) => {
              return {index:i+1, service:(v.parsedJson as any).service, presenter:v.sender, 
                tips:(v.parsedJson as any).tips, tx:v.id.txDigest, time:v.timestampMs}
            }) : []}   
            options={{padding: "dense", tableLayout:'fixed',
                pageSize: len, 
                pageSizeOptions: [5, 10, 20],}}
            actions={[]}
            components={{
              Header: (props) => {
                return (<TableHead {...props} >
                  <TableRow>
                    <TableCell variant='head' style={{width:'8px'}}>{props.columns[0].title}</TableCell>
                    <TableCell variant='head' align='center' style={{width:'8em'}}>
                      <div style={{display:'flex'}}>
                        <div>{props.columns[1].title}</div>                  
                      </div>
                    </TableCell>
                    <TableCell variant='head' align='left' style={{width:'8em'}}>
                        <div>{props.columns[2].title}</div>
                    </TableCell>
                    <TableCell variant='head' align='left' style={{}}>
                      <div> {props.columns[3].title}</div>
                      </TableCell>
                    <TableCell variant='head' style={{width:'11em'}}>{props.columns[4].title}</TableCell>
                    <TableCell variant='head' align='center' style={{width:'4em'}}>{props.columns[5].title}</TableCell>
                  </TableRow>
                </TableHead>)
              },
                Toolbar: props => (
                    <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                        <Typography variant='subtitle1' sx={{ml:'16px', mt:'4px', fontSize:'1.1em'}}></Typography>
                        <MTableToolbar {...props} />
                    </div>
                ),
            }}
          />     
        </Paper>
      );  
}