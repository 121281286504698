
import React, { useState } from 'react';
import '../../css/Launch.css';
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box } from '@mui/material';
import {  SettingLaunch, SettingShowValue, SettingTitle} from '../launch/Settings';
import { Address } from '../util/Address';
import { CoinTypeInfo, Protocol, Treasury as WowokTreasury, Treasury_WithdrawMode, PermissionAnswer, ResolveBalance } from 'wowok';
import { grey } from '@mui/material/colors';
import { NotSetYet } from '../util/Common';

export default function Details (props:any) {
  const type = WowokTreasury.parseObjectType(props?.type);
  const answer: PermissionAnswer | undefined = props?.perms;
  const [coinType, setCoinType] = useState<CoinTypeInfo | 'loading'>(Protocol.Instance().coinTypeInfo(type, (info) => {
    setCoinType(info);
  }));

  const withdraw_mode = parseInt(props?.contents?.fields?.withdraw_mode);
  var mode_txt = '';
  if  (withdraw_mode === Treasury_WithdrawMode.PERMISSION) {
    mode_txt = 'Internal: Only according to the Permission Object';
  } else if (withdraw_mode === Treasury_WithdrawMode.GUARD_ONLY_AND_IMMUTABLE) {
    mode_txt = 'External: Only according to the Withdraw Guards blow, and not reversible after setting'
  }  else if (withdraw_mode === Treasury_WithdrawMode.BOTH_PERMISSION_AND_GUARD) {
    mode_txt = 'Compound: The Permission Object and Withdraw Guard allow withdrawals, and they can be edited at any time'
  }

  return (
      <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em', mt:'2em'}}>
        <SettingShowValue title='Permission' tips='Manage objects and personnel. Achieve separation of operations and management.'
          value={<Address address={props?.contents?.fields?.permission ?? ""} showType={'Permission'}  maxWidth='20em'/> }/>
         <SettingShowValue title='Deposit Guard' tips='Deposits from addresses other than the Permission user are allowed. For example, by setting up Everyone Guard, anyone will be allowed to deposit money with the Treasury.'
          value={props?.contents?.fields?.deposit_guard ? <Address address={props?.contents?.fields?.deposit_guard} showType="Guard"/>:<NotSetYet/>} />
        <SettingShowValue title='Withdraw Mode' tips='The permissions for a withdrawal operation will follow the following modes.'
          value={<span style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'38em'}}>{mode_txt}</span>}/>
        { props?.contents?.fields?.withdraw_guard?.fields?.contents?.length > 0 && <>
          <SettingTitle title='Withdrawal Guard' tips='When the Guard condition is met, the amount set by the corresponding Guard will be withdrawn.'/>
          <div style={{backgroundColor:'#e8a7d53b', marginTop:'.4em', padding:'.6em 1em', maxWidth:'28em', }}>
              {  coinType !== 'loading' && 
                  props?.contents?.fields?.withdraw_guard?.fields?.contents?.map((v:any, index:number)=> {
                      return (<div style={{display:'flex', alignItems:'center', marginBottom:'.6em', marginTop:'.6em'}}>
                          <span style={{color:grey[500], fontStyle:'italic', paddingRight:'1em'}}>{index+1}</span>
                          <Address address={v?.fields?.key} showType={'Guard'} /> 
                          {v.amount !== '' && <span style={{paddingLeft:'4em'}}>{ResolveBalance(v?.fields?.value, coinType.decimals) + ' ' + coinType.symbol}</span>}
                      </div>)
                  })
              }
          </div>
        </>}
        <SettingLaunch text='More...' event={(type, value, id) => {
          if (type === 'click') {
              window.location.href = '/' + (props?.contents?.fields?.id?.id ?? '') + '?setting';
          }
        }}/>
      </Box>
  );  
}