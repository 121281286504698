import React, { useEffect, useState,  } from 'react';
import { sliceAddress } from '../../util';
import '../../css/Common.css';
import { grey, red } from '@mui/material/colors';
import { IconButton, Dialog, DialogContent, DialogTitle, Tooltip, MenuItem, Select} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GuardParser, Passport as WowokPassport, Guard, Protocol, PassportQuery,  WitnessFill, SER_VALUE, FirstLetterUppercase,
    ValueType, MAX_DESCRIPTION_LENGTH, } from 'wowok';
import { ADDRESS_VALIDATOR, SettingInputText, SettingLaunch, SettingShowValue, U128_VALIDATOR, U256_VALIDATOR, U64_VALIDATOR, U8_VALIDATOR } from '../launch/Settings';
import { useSnackbar } from 'notistack';
import { Transition } from './Common';
import Loading from '../app/Loading';
import { Address } from './Address';
import { TransactionBlock, } from 'wowok';
import { WITNESS_ID_TIPS } from '../launch/guard/Label';

export type onPassportResult = (bSuccess:boolean, data?:any, passport?:WowokPassport) => void;
export interface PassportProp {
    id: string;
    guards: string[];
    data?: any;
    onPassportResult?: onPassportResult;
    txb: TransactionBlock;
}

interface GuardFuture {
    guard: string;
    future: WitnessFill[];
}

interface Parser {
    parser: GuardParser | undefined;
    status: 'loading' | 'done';
    futures: GuardFuture[];
}   

const ID = (guard:string, index:number) => { return 'passport-witness-' + guard + index};
var PASSPORT_ID = '';

export const Passport = (props:PassportProp) => {
    const protocol = Protocol.Instance();
    const { enqueueSnackbar } = useSnackbar();
    const [parser, setParser] = useState<Parser>({parser:undefined, status:'loading', futures:[]});
    const [open, setOpen] = useState(false);
    const pack = protocol.package('wowok');
    
    useEffect(() => {
        if (!props?.onPassportResult || props.guards.length === 0 ) {
            setOpen(false);
            return
        }

        if (props.id === PASSPORT_ID) {
            return;
        }
        PASSPORT_ID = props.id;
        try {
            GuardParser.Create(props.guards, async (p:GuardParser|undefined) => {
                const futures = p ? p.future_fills() : [];
                if (futures.length === 0) {
                    if (p) {
                        let passport : any;
                        try {
                            const query = await p.done();
                            if (query) {
                                passport = new WowokPassport(props?.txb ?? protocol.sessionCurrent(), query!);      
                            }
                        } catch (e) {
                            console.log('excute error: ', e);
                        }
    
                        if (!passport) {
                            enqueueSnackbar('Passport verify failed', { variant: "error" });
                            props.onPassportResult!(false, props?.data);
                        } else {
                            props.onPassportResult!(true, props?.data, passport);
                        }
                    }
                    setParser({parser:p, status:'done', futures:[]})
                } else {
                    const guards : GuardFuture[] = [];
                    futures.forEach((v) => { // all guards
                        const g = guards.find((i) => i.guard === v.guard);
                        if (g) { // 加入旧的
                            g.future.push(v);
                        } else { // 按guard建立新的
                            guards.push({guard:v.guard, future:[v]})
                        }
                    })
                    // 填充原来parser的值
                    guards.forEach((i) => {
                        const g = parser.futures.find((k)=>k.guard = i.guard);
                        if (!g) return 
                        i.future.forEach((v) => {
                            const f = g.future.find((k)=>k.identifier === v.identifier);
                            v.witness = f?.witness;
                        })
                    })
                    setParser({parser:p, status:'done', futures:guards})
                    setOpen(true);
                }
            })     
        } catch (e) {
            console.log(e);
            enqueueSnackbar('Generate Passport error.', { variant: "error" });
        }
    }, [props.id, pack, props.txb]);
 

    return (<>
    { 
        parser.status === 'loading' && <Loading/>
    }
    {
        parser.status === 'done' && parser.futures.length > 0 && 
        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus sx={{zIndex:'999!important'}}
        open={open}
        style={{}}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>setOpen(false)}
        >
        <DialogTitle sx={{textAlign:'center', }} > Fill Guard Requires
            <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setOpen(false)}}> <CloseIcon /> </IconButton>   
        </DialogTitle>
            <DialogContent >
                {
                    parser.futures.map((v, index) => {
                        return (
                            <>
                            <div style={{display:'flex', alignItems:'center', }}>
                                <SettingShowValue title={<div style={{marginRight:'0',}}>Guard</div>} 
                                    value={<Address address={v.guard} showType='Guard' fontWeight='500' fontsize='.9em' color={grey[600]}/>} />
                            </div>
                            <div>
                                {
                                    v.future.sort((a,b)=>(a?.identifier??0)-(b?.identifier??0)).map((i) => {
                                        return (<FutureObject future={i} key={i.identifier} event={(f) => {
                                            i.witness = f.witness;
                                            setParser({...parser});
                                        }}/>)
                                    })
                                }
                            </div>
                            </>
                        )
                    })
                }
                <SettingLaunch text='Launch' event={ () => {
                    var bValid = true;
                    parser.futures.forEach((v) => {
                        v.future.forEach((f) => {
                            //console.log(f)
                            if (!f.type || !Validator(f.type)?.validator(f.witness)) {
                                enqueueSnackbar( 'Guard '+ sliceAddress(v.guard) + ' witness ' + f.identifier + ' invalid', { variant: "error" });
                                document.getElementById(ID(f.guard, f.identifier??-1))?.focus();
                                bValid = false;
                                return
                            }
                        })
                    })
                    if (!bValid) {
                        enqueueSnackbar('witness invalid', { variant: "error" });
                        return ;
                    }
                    var f:WitnessFill[] = [];
                    parser.futures.forEach((v) => {
                        f = f.concat(v.future);
                    })
                    try {
                        parser.parser?.done(f, (query:PassportQuery | undefined) => {
                            //console.log(query)
                            if (query) {
                                let passport : any;
                                try {
                                    passport = new WowokPassport(props?.txb ?? protocol.sessionCurrent(), query!);      
                                } catch (e) {
                                    console.log('excute error: ', e);
                                }
            
                                if (!passport) {
                                    enqueueSnackbar('Passport verify failed', { variant: "error" });
                                    props.onPassportResult!(false, props?.data);
                                } else {
                                    props.onPassportResult!(true, props?.data, passport);
                                }
                            } else {
                                enqueueSnackbar('Passport verify failed', { variant: "error" });
                                props.onPassportResult!(false, props?.data);
                            }
                            setOpen(false);
                        });
                    } catch(e) {
                        console.log(e)
                    }
                }}/>
            </DialogContent> 
        </Dialog> 
    }
    </>
    )
}

interface FutureObjectProp {
    future: WitnessFill;
    event: (future:WitnessFill) => void;
}

const Validator = (type?:ValueType)  => {
    var validator = undefined; // RepositoryValueType.String
    if (type === ValueType.TYPE_ADDRESS) {
        validator = ADDRESS_VALIDATOR;
    } else if (type === ValueType.TYPE_U8) {
        validator = U8_VALIDATOR;
    } else if (type === ValueType.TYPE_U64) {
        validator = U64_VALIDATOR;
    } else if (type === ValueType.TYPE_U128) {
        validator = U128_VALIDATOR;
    } else if (type === ValueType.TYPE_U256) {
        validator = U256_VALIDATOR;
    } else if (type === ValueType.TYPE_STRING) {
        validator = {
            validator:(value:any) : boolean => { return typeof(value) === 'string' && value?.length <= MAX_DESCRIPTION_LENGTH/2}, 
            err:'Length <= ' + MAX_DESCRIPTION_LENGTH/2
        }
    } else if (type === ValueType.TYPE_BOOL) {
        validator = {
            validator:(value:any) : boolean => { return typeof(value) === 'boolean'}, 
            err:'Select True or False'
        }
    }
    return validator
}

const FutureObject = (props: FutureObjectProp) => {
    //const [value, setValue] = useState('');
    //const theme = useTheme();
    const cmds = props.future.cmd.map((v) => {
        const c = Guard.GetCmd(v);
        if (c) {
            return <span style={{display:'block'}}>{FirstLetterUppercase(c.module) + '.' + c.query_name}</span>
        } else {
            return null
        }
    })
    const tips = <><span>{'Query cited: '}</span>{cmds}</>;
    const type = SER_VALUE.find((v)=>v.type === props.future.type);
    const witness = type ? FirstLetterUppercase(type.name) : '';

    return (<div style={{padding:'.6em 0'}}>
        <div style={{display:'flex', alignItems:'center', fontSize:'.9em', marginLeft:'.1em'}}>
            <span style={{marginLeft:2}}>{witness}</span>
            <Tooltip title={WITNESS_ID_TIPS} arrow>
            <span style={{color:red[400], fontWeight:1000, marginLeft:'.1em', fontStyle:'italic'}}>{props.future.identifier}</span>
            </Tooltip>
            <Tooltip title={props.future.cmd.length > 0 ? tips : undefined} arrow placement='right'>
                <span style={{marginLeft:'1em', color:grey[500]}}>{'(Cited ' + props.future.cited +' times)'}</span>
            </Tooltip>
        </div>
        {type?.type === ValueType.TYPE_BOOL &&
        <Select placeholder='Selete True or False'  value={props.future.witness} 
            fullWidth onChange={(e) => {
                props.future.witness = Boolean(e.target.value);
                props.event(props.future);
            }}>
            <MenuItem value={true as any}>True</MenuItem>
            <MenuItem value={false as any}>False</MenuItem>
        </Select>
        }
        {type?.type !== ValueType.TYPE_BOOL &&
        <SettingInputText placeholder={'Enter '+type?.name}  maxlength={MAX_DESCRIPTION_LENGTH/2} size='small' sx={{mt:'.2em'}}
            value={props.future.witness} validator={Validator(props.future.type)} id={ID(props.future.guard, props.future.identifier??-1)}
            event={(t, v, id) => {
                props.future.witness = v;
                props.event(props.future);                             
            }}/>
        }
    </div>)
}
