import React, { useEffect, useCallback } from 'react';
import { generateRandomString, load_object_type_string, sliceAddress } from '../../util';
import '../../css/Common.css';
import { grey, blue } from '@mui/material/colors';
import { IconButton, Paper, Box, Tooltip, Autocomplete, TextField, Chip, Avatar,} from '@mui/material';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup';
import CloseIcon from '@mui/icons-material/Close';
import { Resource, MAX_NAME_LENGTH, Protocol, Entity, Tags } from 'wowok';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SettingInputText, SettingLaunch } from '../launch/Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { Transition } from '../util/Common';
import { useAccount } from './Account';
import { simpleAddress } from '../../util';
import SendIcon from '@mui/icons-material/Send';
import { Icons } from './Icons';
import { TransactionBlock, } from 'wowok';

export type AddressProp = {
    address: string;
    name?: string;
    text_fontSize?: string|number;
    icon_fontSize?: string|number;
    showType?:boolean | string;
    disableLink?:boolean;
    maxWidth?: string | number;
    explorer?:'object' | 'txblock' | 'account'
} & any;

interface Alias {
  name: string;
  err: string;
}

export function Address(props:AddressProp) {
    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();

    
    //const [forceRefresh, setForceRefresh] = React.useState(false);
    const account =  useAccount();
    //setForceRefresh(false);

    var tags  = account?.tags?.find((v)=>v.address === props.address);
    var all_tags = Resource.TagData(account?.tags).sort((a, b) => a.address.length-b.address.length).map(v=>v.tag);

    useEffect(() => {
      tags = account?.tags?.find((v)=>v.address === props.address);
      all_tags = Resource.TagData(account?.tags).sort((a, b) => a.address.length-b.address.length).map(v=>v.tag);

      if (tags) {
        if (!alias.name && tags?.name) {
          setAlias({name:tags.name, err:''});
        }
        if (tips.length === 0 && tags?.tags?.length > 0) {
          setTips(tags.tags);
        }        
      }
    }, [account.account])

    const typeCallback = useCallback((id:string, t:string) => {
      if (id===props.address) setType(Protocol.Instance().object_name_from_type_repr(t));
    }, [props.address])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [input, setInput] = React.useState('');
    const [error, setError] = React.useState('');
    const [alias, setAlias] = React.useState<Alias>({name:tags?.name ?? '', err:''});
    const [tips, setTips] = React.useState<string[]>(tags?.tags ?? []);
    const t = load_object_type_string(props.address, typeCallback);
    const [type, setType] = React.useState(Protocol.Instance().object_name_from_type_repr(t));
    const fontSize = props?.text_fontSize ? {fontSize:props.text_fontSize} : {};


    const launch = async () => {
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }
      
      if (!account.account?.resource && account.account.status !== 'notExist') {
        enqueueSnackbar('Personal resource load failed', { variant: "error" });
        return 
      }

      if (alias.err) {
        enqueueSnackbar('Alias invalid', { variant: "error" });
        document.getElementById('address-nick-input')?.focus();
        return
      }

      let ret : any; 
      
      try {
        const txb = new TransactionBlock();
        let obj : Resource | undefined;
        if (account.account.resource) {
          obj = Resource.From(txb, account.account.resource);
        } else {
          obj = Resource.From(txb, Entity.From(txb).create_resource2());
        }
        obj?.add(props.address, tips, alias.name);
        if (!account.account.resource) {
          obj.launch();
        }
        ret = await wallet.signAndExecuteTransaction({transaction:txb});
      } catch (e) {
        console.log('excute error: ', e);
      }

      if (!ret ) {
        enqueueSnackbar('Launch failed', { variant: "error" });
      } else {
        enqueueSnackbar('Launch success', { variant: "success" });
        window.location.reload();
      }
    }

    return (<div style={{display:'flex', alignItems:'center'}} onMouseLeave={() => {setAnchorEl(null);}}>
    { typeof(props.showType)==='boolean' &&  props.showType && type  && <Tooltip title={type} arrow>
      <Avatar style={{ width:props?.icon_fontSize ?? '1em', height:props?.icon_fontSize??'1em', marginRight:2, marginLeft:-2, backgroundColor:'transparent'}}>
                        <Icons type={type} defaultIcon={true}/>
                    </Avatar></Tooltip>}
    { typeof(props.showType)==='string' && props.address && <Tooltip title={props.showType} arrow>
      <Avatar style={{ width:props?.icon_fontSize ?? '1em', height:props?.icon_fontSize??'1em' , marginRight:2, marginLeft:-2, backgroundColor:'transparent'}}>
        <Icons type={props.showType} defaultIcon={true}/>
    </Avatar></Tooltip>}
    {
        tags?.name && <div className='cmdText' style={{...props, whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',
            maxWidth:props?.maxWidth ?? '8em', ...fontSize
        }} onMouseEnter={(e:any)=>{setAnchorEl(e.currentTarget);}} 
        onClick={() => {
          if (props.disableLink) return ;
          window.open('/'+props.address, '_blank')?.focus();
        }}>
            {tags?.name}
        </div>
    }
    {
        !tags?.name && <div className='cmdText' style={{...props, ...fontSize}} 
        onMouseEnter={(e:any)=>{setAnchorEl(e.currentTarget);}}  onClick={() => {
          if (props.disableLink) return ;
          window.open('/'+props.address, '_blank')?.focus();
        }}>
            {props?.name ?? sliceAddress(props.address)}
        </div>
    }
      <Popup onClick={(e) => e.stopPropagation()} onMouseLeave={()=> {setAnchorEl(null); }}
        id={generateRandomString(16)} className='popAddress' style={{fontSize:'.8em'}} 
        open={anchorEl && !open ? true:false}
        anchor={anchorEl}
      >
        <Box boxShadow='none' border='1px solid #E8A7D5' >
        <Paper sx={{maxWidth:'24em'}}>
            <div style={{display:'flex', alignItems:'center', backgroundColor:'rgba(232,167,213,0.24)', }}>
                <div style={{ padding:'.6em 1em .6em 1em', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', 
                    letterSpacing:'.08em', textAlign:tags?.name ? 'center':'left', maxWidth:'14em'
                }}>{simpleAddress(props.address)}      
                </div>
                <div style={{marginLeft:'auto'}}>             
                  <Tooltip title='Click coping address' arrow placement='top' sx={{zIndex:'99999!important'}}>
                  <IconButton sx={{padding:'4px'}} onClick={(event:any) => navigator.clipboard.writeText(props.address).catch(e=>console.error(e))}>
                      <CopyAllIcon sx={{width:'.6em!important', height:'.6em!important', color:'rgba(232,167,213,0.72)'}}/></IconButton>
                  </Tooltip>
                  <Tooltip title='Sui expolorer' arrow placement='top' sx={{zIndex:99999}}>
                  <IconButton sx={{padding:'4px'}} onClick={(event:any) => window.open(Protocol.Instance().explorerUrl(props.address, props?.explorer), '_blank')?.focus()}>
                      <SendIcon sx={{width:'.6em!important', height:'.6em!important', color:'rgba(232,167,213,0.72)'}}/></IconButton>
                  </Tooltip>
                </div> 
            </div>

            <div style={{padding:'.6em 1em', alignItems:'center', backgroundColor:'rgba(232,167,213,0.12)', fontSize:'.8em'}}>
                <div style={{ display:'inline-block', color:'rgba(232,167,213,0.72)',
                        border:'1px solid', borderColor:'rgba(232,167,213,0.24)', borderRadius:'1em', padding:'.4em 1em',
                        marginRight:'.6em', marginBottom:'.3em', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',
                        maxWidth:'11em', 
                    }} className='cmdText' onClick={()=> { 
                      if (!wallet.connected) {
                        enqueueSnackbar('Please login wallet', { variant: "error" });
                        document.getElementById('header-wallet-cmd')?.click();
                        return 
                      }
                        setAnchorEl(null); 
                        setOpen(true);
                    }}>+ tag</div>
                {tags?.tags?.map((v)=> {
                    return (<div style={{ display:'inline-block', color:grey[600],
                        border:'1px solid', borderColor:grey[200], borderRadius:'1em', padding:'.4em 1em',
                        marginRight:'.6em', marginBottom:'.3em', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',
                        maxWidth:'11em'
                    }}>{v}</div>)
                })}                    
            </div>
        </Paper>
        </Box>
      </Popup>
     { <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth='sm' disableRestoreFocus
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={()=>setOpen(false)}
    >
      <DialogTitle sx={{textAlign:'center'}} > Add Tag 
        <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setOpen(false)}}>
                        <CloseIcon />
        </IconButton>   
      </DialogTitle>
      <DialogContent>
      {<SettingInputText maxlength={MAX_NAME_LENGTH} placeholder={'Enter display name for ' + sliceAddress(props.address)} fullWidth
        id='address-nick-input' label='Display name' value={alias.name} autoFocus event={(type, value, id) => {
          setAlias({name:value, err:type==='value'?'':'err'})
        }}/>}
      <Autocomplete  sx={{mt:'2.2em'}} clearIcon={false}  options={[...all_tags]} freeSolo multiple
            value={tips} onChange={(event, value) => {
                setTips([...value]);
            }}
            limitTags={-1}
            onBlur={()=>{}}
            renderTags={(value, props) => {
                return (<>
                {
                    value.map((option, index) => {
                        return (/*<div style={{ display:'inline-block', color:grey[600],
                            border:'1px solid', borderColor:grey[200], borderRadius:'1em', padding:'.4em 1em',
                            marginRight:'.6em', marginBottom:'.3em', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',
                            maxWidth:'11em'
                        }}>{option}</div>*/
                    <Chip label={option} sx={{mr:'.6em'}} color="primary" onDelete={(e)=>{ 
                        const t = tips.filter((v)=>v !== option);
                        setTips([...t]);
                    }}/>)
                    })                   
                }
                </>)
            }}
            renderInput={(params) => <TextField {...params} error={error?true:false} helperText={error} autoFocus
                placeholder={'Input tag and press Enter for ' + sliceAddress(props.address)}
                value={input}
                onChange={(e) => {
                    const v = e.target.value.slice(0, MAX_NAME_LENGTH);
                    setInput(v);
                    setError('');
                    e.stopPropagation();
                }}
                inputProps={{ ...params.inputProps, maxLength:MAX_NAME_LENGTH}}
                onKeyDown={(event) => {
                    if (tips.length >= Resource.MAX_TAG_COUNT_FOR_ADDRESS) {
                        setError('Max tags count');
                        event.stopPropagation();
                    }
                }}
            />}
        />
          <SettingLaunch text='Launch' event={(t) => {
            if (t === 'click') launch();
          }}/>
      </DialogContent>
    </Dialog>  }      
    </div>)
}