import React, { useCallback, useMemo, } from 'react';
import ReactFlow, { MarkerType, Position,  Node, Edge, useNodesState, useEdgesState, Controls, Panel} from 'reactflow';
import 'reactflow/dist/style.css';
import MachineNode, { MachineInitNode}  from './MachineNode';
import MachineEdge from './MachineEdge';
import { Machine_Node, Machine_Node_Pair, Machine, PermissionIndex, PermissionIndexType, Passport, PermissionAnswer, } from 'wowok';
import { generateRandomString, store_key_graph} from '../../../util'
import dagre from '@dagrejs/dagre';
import { Box, Button } from "@mui/material";
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { TransactionBlock, } from 'wowok';
import { useLocation } from 'react-router-dom';

const nodeWidth = 100;
const nodeHeight = 40;
//export var INIT_NODE = generateRandomString(16);

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes:Node[], edges:Edge[]) => {
    dagreGraph.setGraph({ rankdir:  'TB', ranker: "network-simplex", align: "UL", edgesep:200, nodesep:50, ranksep:30});
    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });
    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target, {width:100, height:100});
    });
    dagre.layout(dagreGraph);
    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
        x: nodeWithPosition.x ,
        y: nodeWithPosition.y ,
        };
        return node;
    });
    return { nodes, edges };
};


let ID_COUNT = 0;
const getid = () => {  return (ID_COUNT++).toString(); }


const MachineGraph = (props:any) => {
    console.log(props)
    const path = useLocation();
    const id = props?.contents?.fields?.id?.id ?? '';
    const permission = props?.contents?.fields?.permission;
    const answer:PermissionAnswer | undefined = props?.perms;
    const wallet = useWallet();
    const { enqueueSnackbar } = useSnackbar();

    const INIT_NODE = generateRandomString(16);
    const store_key = store_key_graph(id, 'machine graph');
    const snapGrid:[number, number] = [10, 10];
    const defaultViewport = { x: 0, y: 0, zoom: 1 };
    const [rfInstance, setRfInstance] = React.useState<any>(null);

    const launch = React.useCallback((op:'rename' | 'next' | 'delete' | 'forward add' | 'forward delete', param:any) => {
        if (!wallet.connected) {
            enqueueSnackbar('Please login wallet', { variant: "error" });
            document.getElementById('header-wallet-cmd')?.click();
            return 
        }
        if (props?.contents?.fields?.bPublished) {
            enqueueSnackbar('Graph immutable while machine had published', { variant: "error" });
            return 
        }

        const txb = new TransactionBlock()
        const obj = Machine.From(txb, permission, id);
        const pid = generateRandomString(8);
        props.permissioncheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.machine_node], handler:
          (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
            try {
              if (id === pid) {
                if (index.includes(PermissionIndex.machine_node)) {
                    if (op === 'rename') {
                        obj.rename_node(param.old_name, param.name, passport?.get_object());
                    } else if (op === 'next') { // new or edit
                        obj.add_node([{name:param.name, pairs:[{prior_node:param.prior_name, forwards:[]}]}], passport?.get_object());
                    } else if (op === 'delete') {
                        obj.remove_node([param], true, passport?.get_object())
                    } else if (op === 'forward add') {
                        obj.add_forward(param.prior_name, param.name, param.forward, param.threshold, param.old_forward_name, passport?.get_object());
                    } else if (op === 'forward delete') {
                        obj.remove_forward(param.prior_name, param.name, param.forward_name, passport?.get_object());
                    } else if (op === 'unnext') {
                        obj.remove_pair(param.prior_name, param.name, passport?.get_object())
                    }
                };  
                passport?.destroy(); // destroy passport
                props.exe(id, txb);            
              }
            } catch (e) {
              console.log(e)
              enqueueSnackbar( 'Launch Failed', { variant: "error" });
            }
          }
        });
    }, [answer, id, permission, wallet.connected, props, enqueueSnackbar]);

    const nodeTypes = useMemo(() => ({ 
        CustomNode: (com_props:any) => 
            <MachineNode launch={launch} answer={answer} permission={permission} {...com_props}/>, 
        InitNode:(com_props:any) => 
            <MachineInitNode launch={launch} answer={answer} permission={permission} {...com_props}/> }), [launch, answer, permission]);
    const edgeTypes = useMemo(() => ({ CustomEdge: (com_props:any) => 
            <MachineEdge launch={launch} answer={answer} permission={permission} {...com_props}/>}), [launch,answer, permission]);

    const machine_nodes:Machine_Node[] = Machine.rpc_de_nodes(props?.fields);

    const ops:string[] = [Machine.OPERATOR_ORDER_PAYER];
    machine_nodes.forEach((n) => {
        n.pairs.forEach((p) => {
            p.forwards.forEach((f) => {
                if (f.namedOperator && !ops.includes(f.namedOperator)) {
                    ops.push(f.namedOperator);
                }
            })
        })
    })

    const pos = {sourcePosition:Position.Bottom, targetPosition:Position.Top};
    let initNodes: Node[] = [{id:INIT_NODE, position:{x:0, y:0}, data:{id:id, permission:permission, name:''}, type:"InitNode", draggable:true, ...pos}];
    let initEdges: Edge[] = [];
    machine_nodes.forEach((n:Machine_Node, index:number) => {
        initNodes.push({id:n.name, position:{x:index*100+100, y:index*100+100}, data:{...n}, type:"CustomNode", ...pos});
        n.pairs.forEach((p:Machine_Node_Pair) => {
            let e = {id:getid().toString(), source:p.prior_node || INIT_NODE, target:n.name || INIT_NODE, animated: true,
                markerEnd: { type: MarkerType.ArrowClosed, width:26, height:26, color:'#E8A7D5' }, type:"CustomEdge", data:{init_node:INIT_NODE, ops:ops, ...props}};
            initEdges.push(e);
        })
    })
    
    let { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(initNodes, initEdges);
    const old = localStorage.getItem(store_key);
    if (old) {
        const flow = JSON.parse(old);
        if (flow) {
            layoutedNodes = layoutedNodes.map((v) => {
                // 注意初始节点名字不一样（随机），需要按类型判断
                const node = flow?.nodes?.find((i:any) => i.id === v.id || (i.type==='InitNode' && v.type === 'InitNode'));
                if (node) {
                    v.position = node.position;
                    v.positionAbsolute = node.positionAbsolute;
                    v.height = node.height;
                    v.width = node.width; 
                } 
                return {...v}
            })      
            const { x = 0, y = 0, zoom = 1 } = flow.viewport;
            if (rfInstance) {
                rfInstance.setViewport({ x, y, zoom });      
            }
        }
    } 

    const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

    const fitViewOptions = { padding: 0.5, minZoom:0.01, maxZoom:10 };
    
    const onNodeDragStop = () => {
        if (rfInstance) {
            const flow = rfInstance.toObject();
            localStorage.setItem(store_key, JSON.stringify(flow));
        }
    }

    return ( 
        <Box height={path.search.toLowerCase().includes('setting') ? '46em' : '40em'}>
            <ReactFlow 
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                snapToGrid={true}
                snapGrid={snapGrid}
                fitViewOptions={fitViewOptions}
                fitView
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                defaultViewport={defaultViewport}
                onNodeDragStop={ onNodeDragStop }
                onMoveEnd={ onNodeDragStop }
                isValidConnection={()=>false}
                deleteKeyCode = { null }
                onInit={(rf) => setRfInstance(rf) }
            >
                <Controls />
                <Panel position="top-right">
                    <Button variant='outlined' className='cmdButton' onClick={() => { 
                        localStorage.removeItem(store_key);
                        window.location.reload();
                    }} style={{padding:'.2em .4em', fontSize:'.9em', textTransform:'none'}}>Reset</Button>
                </Panel>
            </ReactFlow>
        </Box>);
}

export default MachineGraph;