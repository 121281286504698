
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box } from '@mui/material';
import { PermissionIndex, PermissionAnswer, Arbitration as WowokArbitration, Permission as WowokPermission, IsValidAddress, PermissionIndexType,
  MAX_DESCRIPTION_LENGTH,  Passport, CoinTypeInfo, Protocol,
  IsValidU64,
  IsValidTokenType} from 'wowok';
import { SettingTitle, SettingLaunch, SettingCoinType, ADDRESS_VALIDATOR, SettingInputText, SettingCoinInput, U64_LENGTH, U64_VALIDATOR, } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { TransactionBlock } from 'wowok';
import { generateRandomString } from '../../../util';
import { make_permIconProp, make_permOwnerIconProp } from '../PermCheck';
import { Address } from '../../util/Address';
import { useAccount,add_resource_launched } from '../../util/Account';
export interface BasicData {
    description: string;
    new_permission: boolean;
    permission: string;
    token: string;
    treasury: string;
    fee: string;
}
  
export default function Basic (props:any) {
  const id = props?.contents?.fields?.id?.id ?? '';
  const permission = props?.contents?.fields?.permission;
  const answer: PermissionAnswer | undefined = props?.perms;
  const type = WowokArbitration.parseObjectType(props?.type);
    const [data, setData] = useState<BasicData>({
      description:props?.contents?.fields?.description ?? "", 
      permission:props?.contents?.fields?.permission ?? "", 
      token:type, 
      treasury: props?.contents?.fields?.fee_treasury ?? '',
      fee:props?.contents?.fields?.fee ?? '0', 
      new_permission:false});

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const account = useAccount();

    const launch = async (txb:TransactionBlock, obj?:WowokArbitration) => {
      try {
        if (obj) { // modify old
          if (data.new_permission) {
              let permission = WowokPermission.New(txb, '');
              obj.change_permission(permission.get_object());
              permission.launch();            
          } else if (data.permission !== props?.contents?.fields?.permission) {
            obj.change_permission(data.permission);
          }     
          props.exe(generateRandomString(8), txb);
        } else { // new
            if (data.new_permission) {
                let permission = WowokPermission.New(txb, ''); 
                let d = WowokArbitration.New(txb, data.token, permission.get_object(), data.description, BigInt(data.fee), txb.object(data.treasury));
                add_resource_launched(account, txb, [d.launch(), permission.launch()]);
                props.exe(generateRandomString(8), txb, 'arbitration::Arbitration');           
            } else {
              const query_txb = new TransactionBlock(); //@ new session before object.From
              const p = WowokPermission.From(query_txb, data.permission);
              const pid = generateRandomString(8);
              const perm = PermissionIndex.arbitration;
              const tips = <div style={{display:'flex', alignItems:'center'}}>
                <Address address={data.permission} />
                <span>: No Permission for Launching Arbitration</span>
                </div>;
              p.QueryPermissions(permission, wallet.address??'', (answer:PermissionAnswer) => {
                const i = WowokPermission.HasPermission(answer, perm, true);
                if (!i?.has) {
                  enqueueSnackbar(tips, { variant: "error" });
                  return;
                }
                //用原有的txb继续提交
                props.guardcheck({id:pid, txb:txb, answer:answer, guards:i.guard ? [i.guard] : [], handler:
                  (id:string, txb:TransactionBlock, passport?:Passport) => {
                    try {
                      if (id === pid) {
                        let d = WowokArbitration.New(txb, data.token, data.permission, data.description, 
                          BigInt(data.fee), txb.object(data.treasury), passport?.get_object());
                        passport?.destroy(); // destroy passport
                        add_resource_launched(account, txb, [d.launch()]);
                        props.exe(generateRandomString(8), txb, 'arbitration::Arbitration');
                      }
                    } catch (e) {
                      console.log(e)
                      enqueueSnackbar( 'Launch Failed', { variant: "error" });
                    }
                  }
                });
              });  
            }          
        }
      } catch (e) {
        console.error('excute error: ', e);
        enqueueSnackbar('Launch failed', { variant: "error" });
      }
    }
  
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
          <SettingTitle title='Description' perm={make_permIconProp(answer, PermissionIndex.arbitration_description)}/>
          <SettingInputText placeholder='Enter the name or description of the arbitration' maxlength={MAX_DESCRIPTION_LENGTH} multiline maxRows={6}
            value={data.description} noPerm={WowokPermission.HasPermission(answer, PermissionIndex.arbitration_description)?.has === false} autoFocus
            event={(type, value, id)=>{
              data.description = value;
              setData({...data});
            }}/>
  
          <SettingTitle title='Permission' tips='Manage your objects and personnel. Achieve separation of operations and management.' required={!id} checkbox='Create a New Permission for me' 
            id='permission' noPerm={(!answer || answer.owner)  ? false:true} 
            perm={data.new_permission ? undefined : make_permOwnerIconProp(answer) } event={(type, value, id) => {
              data.new_permission = type === 'check' ? value : false
              setData({...data});
            }}/> 
            {data.new_permission && (<SettingInputText  disabled fullWidth value='Create a new Permission, and as the creator, you hold all permission indexes'
                placeholder='Create a new Permission, and as the creator, you hold all permission indexes' maxlength={66} 
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: '#A6008B',
                        },
                      }}/>) }
            {!data.new_permission &&  <SettingInputText placeholder='Enter Permission object address' err_empty='Please enter Permission object address' 
                validator={ADDRESS_VALIDATOR} maxlength={66} value={data.permission} noPerm={answer && !answer.owner}
                  id='permission' event={(type, value, id) => {
                    data.permission = value;
                    setData({...data})
                }}/>}
          <SettingTitle title='Arbitration Fee Token' required={!id} tips='The payer uses this token to pay for the arbitration cost. Once the Arbitration is launched, the token cannot be changed.'/>
          <SettingCoinType err_empty='Please enter token type that payed by payer'  placeholder='Enter token type that payed by payer' 
            disabled={props?.type ? true:false} disablePortal={false}
            id='token' initValue={data.token} event={(t, v, d) => {
              data.token = t === 'value' ? v : ''; 
              setData({...data})
              
            }}/>         
          <SettingTitle title='Arbitration Fee Amount' required={!id} tips='A fee for each arbitration, which may be withdrawn after the conclusion of the arbitration.' 
            perm={make_permIconProp(answer, PermissionIndex.arbitration_fee)} />
          <SettingInputText placeholder='Enter the arbitration amount'  value={data.fee}  maxlength={U64_LENGTH} validator={U64_VALIDATOR}
                noPerm={WowokPermission.HasPermission(answer, PermissionIndex.arbitration_fee)?.has === false} fullWidth type='number'
                id='arbitration-basic-fee' event={(type, value, id) => {
                  data.fee = value ;
                  setData({...data})
                }}/>
          <SettingTitle title='Fee Treasury' required={!id} tips='The Treasury that fees was received at the time of withdrawal.'/>
          <SettingInputText placeholder='Enter fee Treasury address'  err_empty='Please enter fee Treasury address' validator={ADDRESS_VALIDATOR}
            noPerm={WowokPermission.HasPermission(answer, PermissionIndex.arbitration_treasury)?.has === false} fullWidth
            id='arbitration-basic-treasury' maxlength={66} value={data.treasury} event={(t, v, d) => {
              data.treasury = v; setData({...data})
          }} />
          <SettingLaunch text='Launch' event={(t) => {
            if (t === 'click') {
              if (!data.new_permission && !IsValidAddress(data.permission)) {
                enqueueSnackbar('Permission not specified', { variant: "error" });
                document.getElementById('permission')?.focus();
                return;
              }
          
              if (!IsValidTokenType(data.token)) {
                enqueueSnackbar('Arbitration Fee Token invalid', { variant: "error" });
                document.getElementById('token')?.focus();
                return
              }

              if (data.fee && !IsValidU64(data.fee)) {
                enqueueSnackbar('Arbitration Fee Amount invalid', { variant: "error" });
                document.getElementById('arbitration-basic-fee')?.focus();
                return
              }
              
              if (!IsValidAddress(data.treasury)) {
                enqueueSnackbar('Treasury invalid', { variant: "error" });
                document.getElementById('arbitration-basic-treasury')?.focus();
                return                
              }

              if (!wallet.connected) {
                enqueueSnackbar('Please login wallet', { variant: "error" });
                document.getElementById('header-wallet-cmd')?.click();
                return 
              }
              
              if (id) {
                const txb = new TransactionBlock(); // new session
                const obj = WowokArbitration.From(txb, type, permission, id);
                const pid = generateRandomString(8);
                const perms: PermissionIndexType[] = [];
                if (data.description !== props?.contents?.fields?.description) {
                  perms.push(PermissionIndex.arbitration_description);
                };
                if (data.fee !== props?.contents?.fields?.fee && (props?.contents?.fields?.fee || data.fee)) { //@ 不用data里的payee
                  perms.push(PermissionIndex.arbitration_fee);
                }
                if (data.treasury !== props?.contents?.fields?.fee_treasury) {
                  perms.push(PermissionIndex.treasury);
                }
                props.permissioncheck({id:pid, txb:txb, answer:answer, index:perms, handler:
                  (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
                    try {
                      if (id === pid) {
                        if (index.includes(PermissionIndex.arbitration_description)) {
                          obj.set_description(data.description, passport?.get_object());
                        };  
                        if (index.includes(PermissionIndex.arbitration_fee)) {
                          obj.set_fee(BigInt(data.fee), passport?.get_object()); //@ payee
                        }     
                        if (index.includes(PermissionIndex.arbitration_treasury)) {
                          obj.set_withdrawTreasury(txb.object(data.treasury), passport?.get_object());
                        }
                        passport?.destroy(); // destroy passport
                        launch(txb, obj);          
                      }
                    } catch (e) {
                      console.log(e)
                      enqueueSnackbar( 'Launch Failed', { variant: "error" });
                    }
                  }
                });
              } else {
                launch(new TransactionBlock());
              }
            }
          }}/>
        </Box>
    );  
  }