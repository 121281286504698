
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box, Chip } from '@mui/material';
import { Protocol, PermissionAnswer, PermissionIndex, Service as WowokService, Permission as WowokPermission,
    PermissionIndexType, Passport, Treasury as WowokTreasury,
} from 'wowok';
import { SettingTitle, SettingTips, SettingLaunch, SettingInputText, ADDRESS_VALIDATOR, SettingAddressChips, 
} from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { TransactionBlock, } from 'wowok';
import { generateRandomString, sliceAddress } from '../../../util';
import { make_permIconProp } from '../PermCheck';

export default function Treasury (props:any) {
    //console.log(props)
    const id = props?.contents?.fields?.id?.id ?? '';
    const type = WowokService.parseObjectType(props?.type);
    const permission = props?.contents?.fields?.permission;
    const answer: PermissionAnswer | undefined = props?.perms;

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();

    const [data, setData] = useState<string[]>(props?.contents?.fields?.extern_withdraw_treasuries);

    const launch = async () => {
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return ;
      }

      if (!answer) {
        enqueueSnackbar('Permission error', { variant: "error" });
        return 
      }
      
      var res:any[] = [];
      if (data.length > 0) {
        res = await Protocol.Client().multiGetObjects({ids:[...data], options:{showType:true}});
      } 

      for (let i = 0; i < res.length; ++ i) {
        if (res[i]?.error) {
            enqueueSnackbar(sliceAddress(res[i]?.error?.object_id) + ' is not a Treasury object', { variant: "error" });   
            return 
        } else {
            if (Protocol.Instance().object_name_from_type_repr(res[i]?.data?.type) !== 'Treasury' ||
                WowokTreasury.parseObjectType(res[i]?.data?.type) === '') {
                enqueueSnackbar(sliceAddress(res[i]?.data?.objectId) + ' is not a Treasury object', { variant: "error" });   
                return 
            }            
        }
      }

      const txb = new TransactionBlock(); // new session
      const obj = WowokService.From(txb, type, permission, id);
      const pid = generateRandomString(8);
      const perms:PermissionIndexType[] = [PermissionIndex.service_treasury]; 
      
      props.permissioncheck({id:pid, txb:txb, answer:answer, index:perms, handler:
        (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
          try {
            if (id === pid) {
              if (index.includes(PermissionIndex.service_treasury)) {
                obj.remove_treasury([], true, passport?.get_object())
                data.forEach((v)=> {
                    res.forEach((i:any) => {
                        obj.add_treasury(WowokTreasury.parseObjectType(i?.data?.type ?? ''), i?.data?.objectId, passport?.get_object());
                    })
                })
              };  

              passport?.destroy(); // destroy passport
              props.exe(pid, txb);
            }
          } catch (e) {
            console.log(e)
            enqueueSnackbar('Launch Failed', { variant: "error" });
          }
        }
      });
    }

    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
            <SettingTips text="'Withdraw Mode' setting of the Treasury Object to be added must be set to 'External'. &nbsp;" command=' Build New Treasury' to='/treasury'/>
            <SettingTitle title='Treasury for External Withdrawals' tips='ONLY persons other than the Permission may withdraw funds based on meeting the immutable Guard condition.' 
                perm={make_permIconProp(answer, PermissionIndex.service_treasury)}/> 
            <SettingAddressChips placeholder='Input Treasury address and press Enter'  noPerm={WowokPermission.HasPermission(answer, PermissionIndex.service_treasury)?.has === false}
            id='service-treasury' maxTags={WowokService.MAX_TREASURY_COUNT} maxTags_err='Max Treasury count' 
                initValue={data} event={(t, v, _) => {
              if (t === 'value') {
                setData([...v])
              } else {
                setData([])
              }
            }}/>

            <SettingLaunch text='Launch' event={(t) => {
                if (t === 'click') launch();
            }}/>
        </Box>
    );  
}