
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box, } from '@mui/material';
import { Bcs, Entity_Info, MAX_ENDPOINT_LENGTH, Entity, IsValidEndpoint, } from 'wowok';
import { SettingTitle,  SettingLaunch, SettingInputText, ENDPOINT_VALIDATOR, 
} from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { TransactionBlock, } from 'wowok';
import { generateRandomString } from '../../../util';

export default function Basic (props:any) {
    var info: any = props?.info?.info;
    if (info) {
        info = Bcs.getInstance().de_entInfo(Uint8Array.from(info));
    }

    const [data, setData] = useState<Entity_Info>({name: info.name ?? '',
        description:info?.description ?? '',
        avatar: info?.avatar ?? '',
        twitter: info?.twitter ?? '',
        discord: info?.discord ?? '',
        homepage: info?.homepage ?? '',
    });

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();

    const launch = async () => {      
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }
          
      try {
        const txb = new TransactionBlock();
        const entity = Entity.From(txb);
        entity.update(data);
        props.exe(generateRandomString(8), txb)
      } catch (e) {
        console.log('excute error: ', e);
        enqueueSnackbar('Launch failed', { variant: "error" });
      }
    }
    
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
            <SettingTitle title='Twitter' />
           <SettingInputText placeholder='Your twitter' maxlength={MAX_ENDPOINT_LENGTH}  autoFocus
            value={data.twitter} 
            event={(type, value, id)=>{
              data.twitter = value ;
              setData({...data});
            }}/>
            <SettingTitle title='Discord' />
           <SettingInputText placeholder='Your discord' maxlength={MAX_ENDPOINT_LENGTH}  
            value={data.discord} 
            event={(type, value, id)=> {
              data.discord =  value ;
              setData({...data});
            }}/>

            <SettingTitle title='Homepage' />
           <SettingInputText placeholder='Your homepage' maxlength={MAX_ENDPOINT_LENGTH} 
            value={data.homepage} validator={ENDPOINT_VALIDATOR} id='personal-homepage'
            event={(type, value, id)=>{
              data.homepage = value ;
              setData({...data});
            }}/>


             <SettingLaunch text='Launch' event={(t) => {
              if (t === 'click') {
                if (data.homepage && !IsValidEndpoint(data.homepage)) {
                  enqueueSnackbar('Homepage invalid', { variant: "error" });
                  document.getElementById('personal-homepage')?.click();
                  return 
                }
                launch();
              }
          }}/>
        </Box>
    );  
}