import '../../css/App.css';
import { Progress, Deliverable, MAX_DESCRIPTION_LENGTH, Holder, } from 'wowok'
import { IconButton, DialogContent, Dialog, DialogTitle } from '@mui/material';
import { SettingInputText, SettingTitle, SettingShowValue, SettingAddressChips } from "../launch/Settings";
import { NotSetYet, Transition } from '../util/Common';
import CloseIcon from '@mui/icons-material/Close';

export interface DeliverableShowProp {
    deliverable?: Holder;
    onClose: () => void;
}

export default function DeliverableShow(props:DeliverableShowProp) {
    var time = '';
    if (props?.deliverable?.time) {
        const d = new Date(parseInt(props?.deliverable?.time));
        time = d.toLocaleString();
    }
    return (<Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth='sm' disableRestoreFocus id='progress-hold'
            open={Boolean(props?.deliverable)}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>{props?.onClose()}}
            >
            <DialogTitle sx={{textAlign:'center'}} > Forward Infomation
                <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{props?.onClose()}}> <CloseIcon /> </IconButton>   
            </DialogTitle>
            <DialogContent >
                {!props?.deliverable?.deliverable?.msg && <SettingShowValue title={'Message'} tips='Some information about completing this Forward.' value={<NotSetYet/>}/>}
                {props?.deliverable?.deliverable?.msg && <>
                <SettingTitle title={'Message'} tips='Some information about completing this Forward.' />
                <SettingInputText value={props?.deliverable?.deliverable?.msg} maxlength={MAX_DESCRIPTION_LENGTH} multiline maxRows={6} readOnly 
                    noneBorder={true} noneScroll={true} sx={{p:0}}/>
                </>}
                {props?.deliverable && props?.deliverable?.deliverable?.orders?.length === 0 && <SettingShowValue title={'Orders'} tips='Some order commitments regarding the completion of this Forward.' value={<NotSetYet/>}/>}
                {props?.deliverable && props?.deliverable?.deliverable?.orders?.length > 0 && <>
                    <SettingTitle title={'Orders'} tips='Some order commitments regarding the completion of this Forward.' />
                    <SettingAddressChips readonly={true} maxTags={Progress.MAX_DELEVERABLE_ORDER_COUNT} 
                        initValue={props?.deliverable.deliverable?.orders.map(v=>v.toString())}/>
                </>}
                {time && <SettingShowValue title='Operation Time' tips='The last time that the operation submitted.' value={time}/>}
            </DialogContent> 
        </Dialog>
  );
}