
import '../../css/App.css';
import { Session, PermissionIndex, Permission, PermissionAnswer, PermissionIndexType, Progress, Passport, Holder} from 'wowok'
import { Tooltip, Paper, IconButton, Typography } from '@mui/material';
import MaterialTable, {MTableToolbar} from "material-table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Address } from "../util/Address";
import { green, grey } from '@mui/material/colors';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { OriginNode } from "../util/Common";
import Actions from "./Actions";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { useTheme} from '@mui/material/styles';
import { PermIcon } from '../util/Icons';
import { make_permIconProp } from '../launch/PermCheck';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { TransactionBlock, } from 'wowok';
import { generateRandomString } from '../../util';
import { useState } from 'react';
import DeliverableShow from './Deliverable'; 

export default function ProgressCurrent(props:any) {
  const id = props?.contents?.fields?.id?.id ?? '';
  const current = props?.contents?.fields?.current;
  const machine = props?.contents?.fields?.machine;
  const answer: PermissionAnswer | undefined = props?.perms;
  const cur_sessions: Session[] = props.sessions.sort((a:Session,b:Session) => {return a.next_node > b.next_node});
  const sid = props?.contents?.fields?.history?.fields?.contents?.fields?.size;
  const theme = useTheme();
  const wallet = useWallet();
  const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState<Holder|undefined>(undefined);
  const bComplete:boolean = !props.waiting && props.nextNodes.length === 0 && props.machineNodes !== 0;

  const history  = Progress.DeHistories(props?.fields).sort((a, b) => {return a.id - b.id!});
  let sessions : Session[] = [];
  history.forEach((h) => {
    sessions = sessions.concat(h.sessions.map((v)=> {
      return {id:h.id, node:h?.node, ...v} as Session;
    }));
  })
  const len = sessions.length >= 20 ? 20 : (sessions.length <= 5 ? 5 : 10);
  //console.log(cur_sessions)

  const launch = async (who:string, next_node:string, next_forward:string) => {      
    if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
    }
    if (!answer) {
      enqueueSnackbar('Permission error', { variant: "error" });
      return 
    }

    const txb = new TransactionBlock();
    const obj = Progress.From(txb, machine, answer?.object!, id);
    const pid = generateRandomString(8);

    props.permissioncheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.progress_unhold], handler:
      (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
        try {
          if (id === pid) {
            //@ notice: 没有权限，自己也可以取消
            if (index.includes(PermissionIndex.progress_unhold) || wallet.address === who) {
              obj.unhold({next_node_name:next_node, forward:next_forward})
            };  
            passport?.destroy(); // destroy passport
            props.exe(id, txb);            
          }
        } catch (e) {
          console.log(e)
          enqueueSnackbar( 'Launch Failed', { variant: "error" });
        }
      }
    });
  }
  console.log(sessions); 
  console.log(cur_sessions)
  return (
    <Paper className='noboxshadow' sx={{minHeight:(sessions.length > 0 || cur_sessions.length === 0) ? 'auto' : '32em'}}>
        {cur_sessions.length > 0 && !bComplete && <MaterialTable title='' style={{marginBottom:'2em'}}
        columns={[
          { title:'SID', field:'id', cellStyle: {paddingRight: 0, paddingLeft:'4px'}, render:(data,type) => {
            return (<div style={{fontStyle:'italic', color:grey[400], fontWeight:200}}>{data.id}</div>);
          }},
          { title: 'Next Node', field: 'next', cellStyle: {paddingLeft: 0},  render:(data, type) => {
              return (<div  className='cmdText' style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',width:'12em', }} onClick={()=>{
                window.open('/'+machine, '_blank')?.focus();
              }}>
                {data.next}
              </div>)
          }},
          { title: 'Loading', field: 'loading', render:(data, t) => {
            return (<div style={{textAlign:'right', color: grey[500]}}>
              {data.loading}
              </div>)
          }},
          { title: 'Operation', field: 'op', cellStyle: {paddingRight: 4}, render:(data, type) => {
            return (<div >
            {data.op.length > 0 && data.op.map((v) => {
              const enableUnhold:boolean = (v.who === wallet.address) || Boolean(Permission.HasPermission(props.answer, PermissionIndex.progress_unhold, true)?.has);
              return (<div style={{display:'flex', alignItems:'center', marginTop:'.2em', marginBottom:'.2em'}}>
                {v.accomplished && <div style={{backgroundColor:'rgba(112,182,3, 1)', borderRadius:'.6em', 
                  padding:'.2em .4em .1em .4em', marginRight:'1em', fontSize:'.8em'}}>Done</div>}
                {!v.accomplished && v.who && <div style={{backgroundColor:'rgba(95,0,170, 0.12)', borderRadius:'.6em', 
                  padding:'.2em .4em .1em .4em', marginRight:'1em', fontSize:'.8em'}}>Pending</div>}
                <div>{v.who && <div style={{display:'flex', color:grey[400]}}><Address address={v.who}/><span>:&nbsp;</span></div>}</div>
                {v.who && <div className='cmdText' style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',maxWidth:'26em',}} onClick={() => {
                  setShow(v);
                }}>{v.forward}</div>}
                { v.accomplished && <div style={{marginLeft:'auto', display:'flex', alignItems:'center'}}>
                    <Tooltip title={'Deliverable'}>
                      <IconButton style={{padding:'5px'}} className="cmdText" onClick={() => {
                          setShow(v);
                      }}><InsertChartIcon style={{width:'.82em', height:'.82em', color:'rgba(112,182,3, 1)'}}/></IconButton>
                    </Tooltip>
                </div>}
                {v.who && !v.accomplished && <div style={{marginLeft:'auto'}}>
                  <Tooltip title={<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                      <span>Cancel Hold</span> 
                      <PermIcon {...make_permIconProp(answer, PermissionIndex.progress_unhold, enableUnhold)} />
                      </div>}> 
                      <span><IconButton style={{padding:'3px'}} className="cmdText" disabled={!enableUnhold} onClick={() => {
                          launch(v.who??'', data.next, v.forward);
                      }}><SettingsBackupRestoreIcon style={{width:'.9em', height:'.9em', color:theme.palette.primary.main }}/>
                    </IconButton>
                    </span></Tooltip>
                  </div>}
              </div>)
            })}
            {
              data.op.length === 0 && <div></div>
            }
            </div>)
          }},
        ]}
        data={cur_sessions.map((v, i) => {
          return {id: sid, next:v.next_node, loading:(v.weights + '/' + v.threshold), op:v.holders}
        })}   
        options={{padding: "dense", tableLayout:'fixed', paging:false, search:false}}
        actions={[]}
        components={{
          Header: (props) => {
            return (<TableHead {...props} >
              <TableRow>
              <TableCell variant='head' align='left' style={{width:'2em',paddingLeft:'4px'}}>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <div>{props.columns[0].title}</div>
                      <div><Tooltip title='Session-id, used for parent progress setting.' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div> 
                  </div>    
                </TableCell>
                <TableCell variant='head' align='center' style={{width:'9em'}}>
                  <div style={{display:'flex'}}>
                    <div>{props.columns[1].title}</div>                  
                  </div>
                </TableCell>
                <TableCell variant='head' align='right' style={{width:'6em'}}>
                  <div> {props.columns[2].title}</div>
                  </TableCell>
                <TableCell variant='head' align='left' style={{paddingRight:'2em'}}>{props.columns[3].title}</TableCell>
              </TableRow>
            </TableHead>)
          },
            Toolbar: (toolbar_props) => (
                <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <PauseCircleIcon sx={{color:grey[400], mr:'2px'}}/>
                    <span style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'14em', fontSize:'1.2em', fontWeight:600, color:grey[600], marginRight:'2em'}}>
                      {current ? current : <OriginNode/>}</span>
                  </div>
                  <Actions {...props}/>
                    <MTableToolbar {...toolbar_props} />
                </div>
            ),
        }}
      />}     
      {(sessions.length > 0 || cur_sessions.length === 0) && <MaterialTable title=''
        columns={[
          { title:'SID', field:'id', cellStyle: {paddingRight: 0, paddingLeft:'4px'}, render:(data,type) => {
            return (<div style={{fontStyle:'italic', color:grey[400], fontWeight:200}}>{data.id}</div>);
          }},
          { title: 'Node', field: 'node', cellStyle: {paddingLeft: 0},  render:(data, type) => {
            return (<div  className='cmdText' style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',width:'10em', }} onClick={()=>{
              window.open('/'+machine, '_blank')?.focus();
            }}>
              {data.node ? data.node : <OriginNode />}
            </div>)
          }},
          { title: 'Next Node', field: 'next', render:(data, type) => {
              return (<div  className='cmdText' style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',width:'12em', }} onClick={()=>{
                window.open('/'+machine, '_blank')?.focus();
              }}>
                {data.next ? data.next : <OriginNode />}
              </div>)
          }},
          { title: 'Loading', field: 'loading', render:(data, t) => {
            const color = data.bComplete ? {color:grey[900]} : {color: grey[500]};
            return (<div style={{textAlign:'right', ...color}}>
              {data.loading}
              </div>)
          }},
          { title: 'Operation', field: 'op', cellStyle: {paddingRight: 4},  render:(data, type) => {
            return (<div >
            {data.op.length > 0 && data.op.map((v) => {
              return (<div style={{display:'flex', alignItems:'center', marginTop:'.2em', marginBottom:'.2em'}}>
                {v.accomplished && <div style={{backgroundColor:'rgba(112,182,3, 1)', borderRadius:'.6em', padding:'.2em .4em .1em .4em', 
                  marginRight:'1em', fontSize:'.8em'}}>Done</div>}
                {!v.accomplished && <div style={{backgroundColor:'rgba(95,0,170, 0.12)', borderRadius:'.6em', padding:'.2em .4em .1em .4em',
                  marginRight:'1em', fontSize:'.8em'}}>Pending</div>}
                {v.who && <div style={{display:'flex', color:grey[400]}}><Address address={v.who}/><span>:&nbsp;</span></div>}
                <div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',maxWidth:'26em'}} className='cmdText' onClick={()=>{
                  setShow(v);
                }}>{ v.forward}</div>
                { v.accomplished  && <div style={{marginLeft:'auto', display:'flex', alignItems:'center'}}>
                    <Tooltip title={'Deliverable'}>
                      <IconButton style={{padding:'5px'}} className= "cmdText" onClick={() => {
                          setShow(v);
                      }}><InsertChartIcon style={{width:'.82em', height:'.82em', color:'rgba(112,182,3, 1)'}}/></IconButton>
                    </Tooltip>
                </div>}
              </div>)
            })}
            </div>)
          }},
          { title: '', field: 'bComplete', render:(data, t) => {
            return (<div style={{textAlign:'right'}}>
              </div>)
          }},
        ]}
        data={sessions.reverse().map((v, i) => {
          return {id:v.id, node:v.node!, bComplete:v.bComplete, next:v.next_node, loading:(v.weights + '/' + v.threshold), op:v.holders}
        })}   
        options={{padding: "dense", tableLayout:'fixed', pageSize: len, pageSizeOptions: [5, 10, 20],}}
        actions={[]}
        components={{
          Header: (props) => {
            return (<TableHead {...props} >
              <TableRow>
                <TableCell variant='head' align='left' style={{width:'2em', paddingLeft:'4px'}}>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <div>{props.columns[0].title}</div>
                      <div><Tooltip title='Session-id, used for parent progress setting.' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div> 
                  </div>    
                </TableCell>
                <TableCell variant='head' align='center' style={{width:'9em'}}>
                  <div style={{display:'flex'}}>
                    <div>{props.columns[1].title}</div>                  
                  </div>
                </TableCell>
                <TableCell variant='head' align='center' style={{width:'9em'}}>
                  <div style={{display:'flex'}}>
                    <div>{props.columns[2].title}</div>                  
                  </div>
                </TableCell>
                <TableCell variant='head' align='right' style={{width:'6em'}}>
                  <div> {props.columns[3].title}</div>
                  </TableCell>
                <TableCell variant='head' align='left' width='100%' style={{paddingRight:'2em'}}>{props.columns[4].title}</TableCell>
                <TableCell variant='head' hidden>{props.columns[5].title}</TableCell>
              </TableRow>
            </TableHead>)
          },
          Toolbar: (toolbar_props) => (
            <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}} >
              {!bComplete && cur_sessions.length === 0 && <><div style={{display:'flex', alignItems:'center'}}>
                <PauseCircleIcon sx={{color:grey[400], mr:'2px'}}/>
                <span style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'14em', fontSize:'1.2em', fontWeight:600, color:grey[600], marginRight:'2em'}}>
                  {current ? current : <OriginNode/>}</span>
              </div>
              <Actions {...props}/></> }
              {bComplete && <Typography variant='h6' sx={{ mt:'4px', color:green[600]}} >{'Progress Completed!'}</Typography>}
              <MTableToolbar {...toolbar_props} sx={{mr:0, ml:'auto'}} />
            </div>
        ),
        }}
      /> }
      <DeliverableShow deliverable={show} onClose={()=>{setShow(undefined)}} />
    </Paper>
  );  
}