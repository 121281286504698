import React, { useState, useEffect } from 'react';
import '../../css/App.css';
import { Session, Progress as WowokProgress, Machine_Node, query_object, Machine } from 'wowok'
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ProgressCurrent from './ProgressCurrent';
import { Pannel, a11yProps} from '../util/Common';
import { useLocation } from 'react-router-dom';
import { store_key_machine_nodes } from '../../util';
import Details from './Details';

export default function Progress (props:any) {
  const current = props?.contents?.fields?.current;
  const machine = props?.contents?.fields?.machine;
  const [machineNodes, setMachineNodes] = useState<Machine_Node[]>([]);
  const [nextNodes, setNextNodes] = useState<Machine_Node[]>([]);
  const [waiting, setWaiting] = useState(true);

  const location = useLocation()
  var t = parseInt(location.hash.slice(1));
  if (t < 0 || t > (nextNodes.length > 0 ? 2 : 1) ||  isNaN(t))  { t = 0; }
  window.location.hash = '#' + t;

  const [value, setValue] = React.useState(t);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    window.location.hash = '#' + newValue;
    setValue(newValue);
  };


  let sessions : Session[] = WowokProgress.DeSessions(props?.contents?.fields?.session);
  
    useEffect(()=>{ // machine nodes 不会再改变，所以可以缓存
      const next_node = (nodes:Machine_Node[]) : Machine_Node[]=> {
        let ret: Machine_Node[] = [];
        nodes.forEach((v) => {
          v.pairs = v.pairs.filter((i) => i.prior_node === current);
          if (v.pairs.length > 0) {
            ret.push(v);
          }
        });
        return ret;
      }
      
      const store = localStorage.getItem(store_key_machine_nodes(machine));
      if (store) {
        const nodes = JSON.parse(store);
        const n = next_node(nodes);
        setMachineNodes(nodes); setNextNodes(n);
      } else {
        setWaiting(true)
        query_object({id:machine, onFieldsRes:(id, fieldsResult)=>{
          const nodes = Machine.rpc_de_nodes(fieldsResult);
          localStorage.setItem(store_key_machine_nodes(machine), JSON.stringify(nodes));
          const n = next_node(nodes);
          setMachineNodes(nodes); setNextNodes(n);
          setWaiting(false)
        }
      })} 
    }, [current, machine]);

    return ( 
      <Paper sx={{pt:'.4em', pl:'1em', pr:'1em',  mb:'2em'}}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Currently" {...a11yProps(0)} sx={{textTransform:'none', pb:'.2em'}} value={0}/>
          <Tab label="Promise" {...a11yProps(2)} sx={{textTransform:'none', pb:'.2em'}} value={1}/>
        </Tabs>
        <Pannel value={value} index={0}>
              <ProgressCurrent {...props} sessions={sessions} nextNodes={nextNodes} machineNodes={machineNodes}/>
        </Pannel>
        <Pannel value={value} index={1}>
            <Details {...props} />
        </Pannel>
    </Paper>
    );
}