import React, { useState, useEffect } from 'react';
import '../../css/App.css';
import { Service, Protocol, Passport, IsValidAddress,  MAX_DESCRIPTION_LENGTH, Arbitration, MAX_NAME_LENGTH } from 'wowok'
import { Tooltip, Box, Button, List, ListItemText, ListItemButton, Checkbox, ListItem} from '@mui/material';
import { SettingShowValue, SettingTitle, SettingTips, SettingInputText, SettingAddressChips } from '../launch/Settings';
import { Address } from '../util/Address';
import { grey, pink } from '@mui/material/colors';
import { generateRandomString, sliceAddress } from '../../util';
import { useSnackbar } from 'notistack';
import { TransactionBlock, } from 'wowok';
import { useWallet } from '@suiet/wallet-kit';
import { useTheme, } from '@mui/material/styles';
import { useAccount,add_resource_launched } from '../util/Account';

class Data {
    arbitration: string='';
    description: string='';
    proposition: string[] = ['', '', '', ''];
}

interface ArbitrationInfo {
    token: string;
    fee: string;
    bPaused: boolean;
    guard: string;
    permission: string;
    object: string;
}

interface Balance {
    object: string;
    balance: bigint;
}

export default function Arb (props:any) {
    //console.log(props)
    const wallet = useWallet();
    const { enqueueSnackbar } = useSnackbar();
    const type = Service.parseOrderObjectType(props?.type);
    const order_id = props?.contents?.fields?.id?.id ?? '';
    //const service_id = props?.service?.content?.fields?.id?.id;
    const arbitrations: string[] = props?.service?.content?.fields?.arbitrations ?? [];
    const theme = useTheme();
    const account = useAccount();

    const [data, setData] = useState<Data>(new Data());
    const [arbitration, setArbitration] = useState<ArbitrationInfo | undefined>(undefined);

    var payerPerm = true;
    if (wallet.address) {
        if (wallet.address !== props?.contents?.fields?.payer && !props?.contents?.fields?.agent?.find((v:string) => v===wallet.address)) {
            payerPerm = false;
        }
    }
    const payerTips = payerPerm ? undefined : 'Only the Order payer or his agents has the permission.';

    const launch = async() => {
        if (!wallet.connected || !wallet.address) {
            enqueueSnackbar('Please login wallet', { variant: "error" });
            document.getElementById('header-wallet-cmd')?.click();
            return ;
        }
        if (!payerPerm) {
            enqueueSnackbar(payerTips, { variant: "error" });
            return ;
        }

        var balance: Balance[] = []; var total = BigInt(0);
        const fee = arbitration?.fee ? BigInt(arbitration?.fee) : BigInt(0);

        if (fee > BigInt(0)) {
            try {
                const res = await Protocol.Client().getOwnedObjects({owner:wallet.address, filter:{StructType: '0x2::coin::Coin<'+arbitration!.token+'>'}, options:{showContent:true, showType:true}});
                balance = res?.data?.map((i:any) => {
                    total += BigInt(i?.data?.content?.fields?.balance);
                    return {balance:BigInt(i?.data?.content?.fields?.balance), object:i?.data?.objectId}
                })
                if (total < BigInt(arbitration!.fee)) {
                    enqueueSnackbar("NOT enough tokens to cover the cost of Arbitration", { variant: "error" });
                    return ;
                }
            } catch(e) {
                console.log(e)
                enqueueSnackbar("Launch failed", { variant: "error" });
                return ;
            }            
        }

        const txb = new TransactionBlock();
        const obj = Arbitration.From(txb, arbitration!.token, arbitration!.permission, data.arbitration);
        const pid = generateRandomString(8);
        var coin: any; 

        try {
            if (fee > BigInt(0)) {
                //@ SUI 从GAS获得
                if (arbitration?.token === '0x2::sui::SUI' || arbitration?.token === '0x0000000000000000000000000000000000000000000000000000000000000002::sui::SUI') {
                    coin = txb.splitCoins(txb.gas, [fee])                    
                } else {
                const coins = []; let current = BigInt(0); 
                for (let i = 0; i < balance.length; ++ i) {
                    if (current >= fee) { 
                    break;
                    }
                    current = current + balance[i].balance;
                    coins.push(balance[i].object);
                };
                //console.log(depositData)
                if (coins.length > 1) {
                    coin = coins.pop()!;
                    txb.mergeCoins(coin, coins);
                } else if (coins.length === 1){
                    coin = txb.splitCoins(coins[0], [fee]);
                }   
                }                
            }

            props.guardcheck({id:pid, txb:txb, guards:arbitration?.guard ? [arbitration?.guard] : [], handler:
                (id:string, txb:TransactionBlock, passport?:Passport) => {
                try {
                    if (id === pid) {
                        add_resource_launched(account, txb, [obj.dispute({order:txb.object(order_id), order_token_type:type, description:data.description, 
                            votable_proposition:data.proposition.filter((v)=>v!==''), fee: (fee > BigInt(0)) ? txb.object(coin) : undefined}, passport?.get_object())]);
                        passport?.destroy(); // destroy passport
                        props.exe(pid, txb, 'arb::Arb');
                    }
                } catch (e) {
                    console.log(e)
                    enqueueSnackbar( 'Launch Failed', { variant: "error" });
                }
                }
            });              
        } catch (e) {
          console.log(e)
          enqueueSnackbar( 'Launch Failed', { variant: "error" });
        }
    }

    return ( 
    <Box sx={{maxWidth:'100%', padding:'0 1em', pb:'2em', mt:'2em'}}>
        { props?.contents?.fields?.dispute?.length === 0 && <SettingShowValue mb='0' title='Arbs' tips='Arbitration list of the order seeks.' 
            value={<span style={{color:grey[500]}}>No Arbs yet.</span>}/>}
        { props?.contents?.fields?.dispute?.length > 0 && <>
            <SettingTitle title='Arbs' tips='Arbitration list of the order seeks.'/>
            <SettingAddressChips maxTags={20} readonly={true}  initValue={props?.contents?.fields?.dispute} type='Arb'/>
        </>}
        <div style={{borderTop:'2px dashed', borderColor:grey[300], width:'100%', height:'2px', marginTop:'1.4em'}}></div>
        {arbitrations.length === 0 && <>
           <SettingTips text={'Your current order service does not support any Arbitration refunds.'}/>
        </>}
        {arbitrations.length > 0 && <><SettingTips mt='1em' text="In case of any dispute over the order, you can apply for a refund of the order through Arb as a result of Arbitration."/>
        <SettingTitle title='Arbitration Supported by the Service' required tips='Address of the arbitration object. Pay attention to the support of its arbitration cost and Service for its arbitration compensation.' />
        <List sx={{border:'1px solid', borderColor:grey[200], mt:'.4em', p:0, width:'40%'}}>
                { arbitrations.map((v, index) => {
                   return ( <ListItem key={v} component="div" disablePadding 
                    sx={{display:'flex', alignItems:'center', backgroundColor:v === data.arbitration ? pink[50] : undefined}}  
                    secondaryAction={
                    <Checkbox
                      edge="end"
                      onChange={async (e) => {
                        if (v === data.arbitration) {
                            data.arbitration = '';
                        } else {
                            data.arbitration = v;
                            let arbitration_token = '';
                            try {
                                const res = await Protocol.Client().getObject({id:v, options:{showType:true, showContent:true}});
                                arbitration_token = Arbitration.parseObjectType(res?.data?.type);
                                if (arbitration_token) {
                                    const c: any = res?.data?.content;
                                    setArbitration({token:arbitration_token, fee:c?.fields?.fee??"", permission:c?.fields?.permission,
                                        guard:c?.fields?.usage_guard??"", bPaused:c?.fields?.bPaused ?? true, object:v});
                                }
                            } catch(e) {
                                console.log(e);
                            }
                        }
                        setData({...data});
                      }}
                      checked={v === data.arbitration}
                      inputProps={{ 'aria-labelledby': 'guard-list-checkbox' }}
                    />
                  }>
                        <ListItemButton sx={{pl:'.7em', pr:'.7em', color:grey[600], flex:'auto', pt:0, pb:0}}>
                            <ListItemText sx={{width:'6em', ml:0}}>
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <span style={{fontStyle:'italic', width:'1.6em', color:grey[400], marginRight:'.1em', fontSize:'.9em', paddingTop:'.1em'}}>{index+1+'.'}</span>
                                    <Address address={v} showType='Arbitration'/>
                                </div>
                            </ListItemText>
                        </ListItemButton>   
                    </ListItem>)                      
                    })
                }
            </List>  
        <SettingTitle title='Description' required tips={'Event description, information and points of divergence.'}/>
        <SettingInputText placeholder='Enter the event description, information and points of divergence' maxlength={MAX_DESCRIPTION_LENGTH} multiline maxRows={6}
            id='order-arb-description' value={data.description} err_empty='Please enter the event description, information and points of divergence.' event={(t, v, d)=>{
                data.description = v;
                setData({...data});
        }}/>
        <SettingTitle title='Proposition for Compensation 1' tips={'The members of the arbitration panel will vote on it and give a final opinion on the award.'}/>
        <SettingInputText placeholder='Enter your proposition for compensation' maxlength={MAX_NAME_LENGTH} 
            id='order-arb-p1'  value={data.proposition[0]} event={(t, v, d)=>{
                data.proposition[0] = v;
                setData({...data});
        }}/>  
        <SettingTitle title='Proposition for Compensation 2' tips={'The members of the arbitration panel will vote on it and give a final opinion on the award.'}/>
        <SettingInputText placeholder='Enter your proposition for compensation' maxlength={MAX_NAME_LENGTH} 
            id='order-arb-p2' value={data.proposition[1]} event={(t, v, d)=>{
                data.proposition[1] = v;
                setData({...data});
        }}/>        
                <SettingTitle title='Proposition for Compensation 3' tips={'The members of the arbitration panel will vote on it and give a final opinion on the award.'}/>
        <SettingInputText placeholder='Enter your proposition for compensation' maxlength={MAX_NAME_LENGTH} 
            id='order-arb-p3' value={data.proposition[2]} event={(t, v, d)=>{
                data.proposition[2] = v;
                setData({...data});
        }}/>        
                <SettingTitle title='Proposition for Compensation 4' tips={'The members of the arbitration panel will vote on it and give a final opinion on the award.'}/>
        <SettingInputText placeholder='Enter your proposition for compensation' maxlength={MAX_NAME_LENGTH} 
            id='order-arb-p4' value={data.proposition[3]} event={(t, v, d)=>{
                data.proposition[3] = v;
                setData({...data});
        }}/>              
        <Tooltip title={payerTips} arrow>
            <span><Button variant='outlined' className='cmdButton' sx={{mt:'1em', textTransform:'none', fontSize:'1.2em'}} 
                disabled={!payerPerm} onClick={async ()=> {
                    if (!IsValidAddress(data.arbitration)) {
                        enqueueSnackbar('Please Select an Arbitraion', { variant: "error" });
                        return ;
                    }
                    if (!data.description) {
                        enqueueSnackbar('Description invalid', { variant: "error" });
                        document.getElementById('order-arb-description')?.click();
                        return ;
                    }
                    if (!arbitration || arbitration?.object !== data.arbitration) {
                        enqueueSnackbar('Fetch Arbitration' + sliceAddress(data.arbitration) + ' info failed', { variant: "error" });
                        return 
                    }
                    if (arbitration.bPaused) {
                        enqueueSnackbar('The Arbitration is currently not open to arbitration applications', { variant: "error" });
                        return;
                    }
                    launch()
                }}>Seek New Arbitration
            </Button>   
        </span></Tooltip>
        </>}   
    </Box>
    );
}