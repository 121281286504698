
import React, { useState } from 'react';
import '../../css/Launch.css';
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box } from '@mui/material';
import {  SettingLaunch, SettingShowValue, SettingTitle} from '../launch/Settings';
import { Address } from '../util/Address';
import { CoinTypeInfo, Protocol, Arbitration as WowokArbitration, ResolveBalance,} from 'wowok';
import { grey } from '@mui/material/colors';
import { NotSetYet } from '../util/Common';
import Status from '../launch/arbitration/Status';

export default function Details (props:any) {
  const type = WowokArbitration.parseObjectType(props?.type);
  const [coinType, setCoinType] = useState<CoinTypeInfo | 'loading'>(Protocol.Instance().coinTypeInfo(type, (info) => {
    setCoinType(info);
  }));

  const fee = coinType !== 'loading' ? ResolveBalance(props?.contents?.fields?.fee, coinType.decimals) : undefined;
  var fee_txt = 'Free';
  if (fee !== '0' && coinType !== 'loading') {
    fee_txt = fee + ' ' + coinType.symbol;
  }

  return (
      <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em', mt:'2em'}}>
        <SettingShowValue title='Permission' tips='Manage objects and personnel. Achieve separation of operations and management.'
          value={<Address address={props?.contents?.fields?.permission ?? ""} showType={'Permission'}  maxWidth='20em'/> }/>
        <SettingShowValue title='Fee Treasury' tips='The Treasury that fees was received at the time of withdrawal.'
          value={<Address address={props?.contents?.fields?.fee_treasury ?? ""} showType={'Treasury'}  maxWidth='20em'/> }/>
        {props?.contents?.fields?.deposit_guard && 
        <SettingShowValue title='Customer Guard'  tips={'Only when the Guard conditions are met can a request for arbitration be submitted.'} 
          value={<Address address={props?.contents?.fields?.usage_guard} showType='Guard'/>} />}
        <SettingShowValue title='Endpoint' tips='Endpoint about the detailed interaction interface: url or ipfs.'
            value={props?.contents?.fields?.endpoint ? <span className='cmdText'  onClick={()=> {
                window.open(props?.contents?.fields?.endpoint, '_blank')?.focus()
            }}>{props?.contents?.fields?.endpoint}</span> : <NotSetYet/>}/>
        { coinType !== 'loading' && <SettingShowValue title='Arbitration Fee'  tips={'A fee for each arbitration, which may be withdrawn after the conclusion of the arbitration.'} 
          value={fee_txt} />}
        { props?.contents?.fields?.voting_guard?.fields?.contents?.length > 0 && <>
          <SettingTitle title='Voting Guard and Weight' tips='The vote weight when the guard condition is met.'/>
          <div style={{backgroundColor:'#e8a7d53b', marginTop:'.4em', padding:'.6em 1em', maxWidth:'28em', }}>
              { 
                  props?.contents?.fields?.voting_guard?.fields?.contents?.map((v:any, index:number)=> {
                      return (<div style={{display:'flex', alignItems:'center', marginBottom:'.6em', marginTop:'.6em'}}>
                          <span style={{color:grey[500], fontStyle:'italic', paddingRight:'1em'}}>{index+1}</span>
                          <Address address={v?.fields?.key} showType={'Guard'} /> 
                          <span style={{paddingLeft:'4em'}}>{v.fields.value}</span>
                      </div>)
                  })
              }
          </div>
        </>}
        <div style={{marginLeft:'-2em', marginBottom:'-2em'}}><Status {...props}/></div>
        <SettingLaunch text='More...' event={(type, value, id) => {
          if (type === 'click') {
              window.location.href = '/' + (props?.contents?.fields?.id?.id ?? '') + '?setting';
          }
        }}/>
      </Box>
  );  
}