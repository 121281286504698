
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box, } from '@mui/material';
import { Bcs, Entity_Info, MAX_NAME_LENGTH, MAX_DESCRIPTION_LENGTH, MAX_ENDPOINT_LENGTH, Entity, IsValidEndpoint} from 'wowok';
import { SettingTitle,  SettingLaunch , SettingInputText, ENDPOINT_VALIDATOR, } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { TransactionBlock, } from 'wowok';
import { generateRandomString } from '../../../util';

export default function Basic (props:any) {
    var info: any = props?.info?.info;
    if (info) {
        info = Bcs.getInstance().de_entInfo(Uint8Array.from(info));
    }

    const [data, setData] = useState<Entity_Info>({name: info?.name ?? '',
        description:info?.description ?? '',
        avatar: info?.avatar ?? '',
        twitter: info?.twitter ?? '',
        discord: info?.discord ?? '',
        homepage: info?.homepage ?? '',
    });

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();

    const launch = async () => {      
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }
        
      try {
        const txb = new TransactionBlock();
        const entity = Entity.From(txb);
        
        entity.update(data);

        props.exe(generateRandomString(8), txb, '', '/' + wallet.address);
      } catch (e) {
        console.log('excute error: ', e);
        enqueueSnackbar('Launch failed', { variant: "error" });
      }
    }
    
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
          <SettingTitle title='Name' required />
          <SettingInputText placeholder='Enter your name' maxlength={MAX_NAME_LENGTH}  autoFocus err_empty='Please enter your name'
            value={data.name} id='personal-name'event={(type, value, id)=>{
              data.name = value;
              setData({...data});
            }}/>
  
            <SettingTitle title='Description' />
            <SettingInputText placeholder='Enter some words to make known to the world' maxlength={MAX_DESCRIPTION_LENGTH} 
            multiline rows={3}  value={data.description} event={(type, value, id)=> {
              data.description = value ;
              setData({...data});
            }}/>

            <SettingTitle title='Avatar' />
            <SettingInputText placeholder='Enter your avartar url' maxlength={MAX_ENDPOINT_LENGTH}  multiline maxRows={4}
            value={data.avatar} validator={ENDPOINT_VALIDATOR} id='personal-avatar'
            event={(type, value, id)=>{
              data.avatar = value;
              setData({...data});
            }}/>

             <SettingLaunch text='Launch' event={(t) => {
            if (t === 'click') {
              if (!data.name) {
                enqueueSnackbar('Name invalid', { variant: "error" });
                document.getElementById('personal-name')?.focus();
                return;
              }

              if (data.avatar && !IsValidEndpoint(data.avatar)) {
                enqueueSnackbar('Avatar invalid', { variant: "error" });
                document.getElementById('personal-avatar')?.focus();
                return;
              }
              launch();
            }
          }}/>
        </Box>
    );  
}