
import React, { useState, useEffect } from 'react';
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Protocol, RepositoryValueType, PermissionIndexType } from 'wowok';
import { SuiTransactionBlockResponse } from '@mysten/sui/client';
import MaterialTable, {MTablePagination, MTableToolbar} from "material-table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Address } from '../util/Address';
import { Box, Dialog, Button, Tooltip, IconButton, Chip, Paper, Autocomplete, TextField, ClickAwayListener, Avatar } from '@mui/material';
import { useTheme} from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { time } from 'console';
import { SettingAddressChips } from '../launch/Settings';
import { Icons } from '../util/Icons';
import { Wallet } from '@mui/icons-material';
import Loading from '../app/Loading';

interface Data {
    objects: string[];
    sender: string;
    time: string;
    gas: number;
    tx: string;
}

export default function Realtime (props:any) {
    const protocol = Protocol.Instance();
    const [data, setData] = useState<Data[]>([]);
    const date = new Date(); 
    const [update, setUpdate] = useState(date.getTime());
    const [loading, setLoading] = useState(false);
    const pack = protocol.package('wowok_origin');

    if (date.getTime() - update > 60000) {
        setUpdate(date.getTime());
    }

    useEffect(()=> {
        const QueryTxns = (count:number=0, cursor?:any) => {
            count += 1;
            //@ 取4页
            Protocol.Client().queryTransactionBlocks({filter:{MoveFunction:{package:pack}}, cursor:cursor,
                options:{showEffects:true}}).then((res:any)=>{
                setLoading(false)
                const n: Data[] = [];
                //console.log(res)
                res.data.forEach((v:any) => {
                    const gas = parseInt(v.effects.gasUsed.storageCost) - parseInt(v.effects.gasUsed.storageRebate) + parseInt(v.effects.gasUsed.computationCost);
                    const obj : string[] = [];
                    v?.effects?.sharedObjects?.forEach((i:any) => {
                        if (i.objectId !== "0x0000000000000000000000000000000000000000000000000000000000000006" && i.objectId !== '0x6' &&
                            i.objectId !== protocol.objectEntity() && i.objectId !== protocol.objectWowok() && i.objectId !== protocol.objectTreasuryCap()
                        ) {
                            obj.push(i.objectId)
                        }
                    })
                    v?.effects?.created?.forEach((i:any) => {
                        //@ account, resource 等过滤掉了，以后完善了再加回来
                        if (i?.owner?.ObjectOwner || i?.owner?.AddressOwner) { return } 
                        obj.push(i.reference.objectId);
                    });
                    if (obj.length > 0) {
                        n.push({objects:obj, sender:v.effects.gasObject.owner.AddressOwner, tx:v.digest, time:v.timestampMs, gas:gas})
                    }
                });
                if (count === 0) {
                    setData([...n]);
                } else {
                    setData([...data, ...n]);
                }
                if (res.hasNextPage && count <= 4) {
                    QueryTxns(count, res.nextCursor);
                }
            }).catch((e) => {
                setLoading(false);
                console.log(e)
            })
        }
        // setData([]);
        setLoading(true);
        QueryTxns();
    }, [update, pack]);

    let len = 10;

    if (loading) {
        return(<div style={{padding:'1em'}}><Loading /></div>)
    } else {
        return (
            <Paper className='mtTableBug' sx={{padding:'0', ml:'1em', mr:'1em', mb:'2em'}} >
                <MaterialTable   title=''
                columns={[
                { title: 'Objects', field: 'objects', render:(data, type) => {
                    return (<Objects objects={data.objects}/>)
                    //return (null)
                }},
                { title: 'Singer', field: 'signer', render:(data, type) => {
                    return (<div>
                        <Address address={data.signer} explorer='account'/>
                    </div>)
                }}, 
                { title: 'Gas', field: 'gas', cellStyle:{paddingRight:0},render:(data, type) => {
                    return (data.gas)
                }}, 
                { title: 'Time', field: 'time',  render:(data, type) => {
                    const ts = new Date(parseInt(data.time ?? ''));
                    return (<div style={{display:'flex', alignItems:'center'}}>
                        {ts.toLocaleString().split(' ')[1]}
                    </div>)
                }},
                { title: 'Tx', field: 'tx', render:(data, type) => {
                    return (<div>
                        <Tooltip title={data.tx}>
                           <span className='cmdText' onClick={()=>window.open(Protocol.Instance().explorerUrl(data.tx, 'txblock'), '_blank')?.focus()}>
                            {data.tx.slice(0, 6)} 
                            </span>
                        </Tooltip>
                    </div>)
                }},
                ]}
                data={data.sort((a,b)=>{return parseInt(b.time)-parseInt(a.time)}).map((v, i) => {
                    return {index:i+1, objects:v.objects, signer:v.sender, gas:v.gas, time:v.time, tx:v.tx}
            })}   
                options={{
                    padding: "dense", rowStyle:{border:'1px solid', borderColor:grey[300]}, 
                    pageSize: len, 
                    pageSizeOptions: [5, 10, 20],
                }}
                components={{
                    Header: (props) => {
                    return (<TableHead {...props} style={{border:'1px solid', borderColor:grey[300]}}>
                        <TableRow>
                        <TableCell variant='head' align='left' style={{paddingLeft:'2em'}} width='56%'>
                            <div style={{display:'flex', marginRight:'1em', }}>
                            <div>{props.columns[0].title}</div>
                            <div><Tooltip title='Active objects' arrow placement='right'>
                            <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                            </Tooltip></div>                      
                            </div>
                        </TableCell>
                        <TableCell variant='head' align='left' width='6em'>
                            <div style={{display:'flex', justifyContent:'start', marginRight:'1em', }}>
                            <div>{props.columns[1].title}</div>                  
                            </div>
                        </TableCell>
                        <TableCell variant='head' align='center' width='10em'>
                            <div style={{display:'flex', justifyContent:'start', marginRight:'1em', }}>
                            <div>{props.columns[2].title}</div>                   
                            </div>
                        </TableCell>
                        <TableCell variant='head' align='center' width='12em'>
                            <div style={{display:'flex', justifyContent:'start', marginRight:'1em', }}>
                            <div>{props.columns[3].title}</div>                    
                            </div>
                        </TableCell>
                        <TableCell variant='head' align='center'>{props.columns[4].title}</TableCell>
                        </TableRow>
                    </TableHead>)
                    },
                }}/>
            </Paper>
        );
    }
}

function Objects(props:any) {
    const theme = useTheme()
    const [hidechip, setHidchip] = useState(true);

    return ( <ClickAwayListener onClickAway={(e)=> {
        setHidchip(true)
    }}>     
     <Autocomplete disableClearable size='small'  sx={{'& fieldset':{border:'none!important'}}}
        options={[]} multiple disableListWrap forcePopupIcon={false} noOptionsText
        value={props.objects}
        onChange={(event, newValue, reason) => {
        }}
        renderInput={(params) => (
          <TextField /*onClick={()=> props.handleAdd()}*/ 
            {...params}
            sx={{ "& fieldset": { padding:'8px',  borderColor:'rgba(232,167,213,0.36)!important'},
            "&:hover fieldset": { borderColor:theme.palette.primary.main + '!important'},
            input: {cursor:'pointer', display:'none'}}}
          />
        )}
        renderTags={(tagValue, getTagProps) => {
            const count = 6;
            const show = hidechip ? tagValue.slice(0, count): tagValue;

            return (
                <div>
                {show.map((option, index) => {
                    return (
                        <Chip label={<Address address={option} showType={true}/>} sx={{mr:'1em', mb:'.2em', mt:'.2em'}} />)
                    })}
                    {
                        hidechip && tagValue.length > show.length && 
                            <Tooltip title='Click to show more permissions' arrow>
                                <div style={{display:'inline-block', marginLeft:'.4em', fontSize:'1.1em', cursor:'pointer'}} onClick={() => {
                                    setHidchip(false);
                                }}>{'+' + (tagValue.length - show.length)}</div>                                  
                           </Tooltip>
                    }
                </div>);
        }}
        PaperComponent={(paperProps:any) => null}
      /></ClickAwayListener>)
}
